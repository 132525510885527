import { MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllSalaryReportActiveQuery, useGetCreateSalaryReportRowsMutation } from "api/Api";
import colors from "styles/colors-theme";
import { enqueueSnackbar } from "notistack";
import StatusChip from "components/common/chip/StatusChip";

import { Box, Button, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as AgreementIcon } from "assets/icons/agreement.svg";

import LoadingButton from "components/common/loading/LoadingButton";
import ModalDialog from "components/common/modal/ModalDialog";
import AgreementModalContent from "components/representative/salaryProtocol/components/AgreementModalContent";

export const salaryProtocolOverviewGridColDef = (isActiveSalaryProtocol: boolean) => {
	const [isLoading, setIsLoading] = useState(false);
	const [salaryReportId, setSalaryReportId] = useState("");
	const [year, setYear] = useState("");

	const navigate = useNavigate();

	const [getCreateSalaryReportRowsMutation] = useGetCreateSalaryReportRowsMutation();
	const { refetch } = useGetAllSalaryReportActiveQuery(null);

	const createSalaryProtocol = (
		e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
		salaryReportId: string,
		year: string,
	) => {
		e.stopPropagation();
		setIsLoading(true);
		setYear(year);
		setSalaryReportId(salaryReportId);
	};

	useEffect(() => {
		if (salaryReportId && salaryReportId.length > 0) {
			const form = {
				salaryReportId: salaryReportId,
			};
			try {
				getCreateSalaryReportRowsMutation(form)
					.unwrap()
					.then(() => {
						refetch();
						navigate(`/aktuella/${year}/${salaryReportId}`);
					})
					.catch((error) => {
						setIsLoading(false);
						enqueueSnackbar(error.data.detail, { variant: "error", persist: true });
					});
			} catch (error) {
				setIsLoading(false);
				enqueueSnackbar("Löneprotokoll kan inte laddas", {
					variant: "error",
					persist: true,
				});
			}
		}
	}, [salaryReportId]);

	const columns: GridColDef[] = [
		{
			field: "workplaceName",
			headerName: "Arbetställe",
			sortable: true,
			align: "left",
			headerAlign: "left",
			flex: 3,
			minWidth: 100,
		},
		{
			field: "agreementTypeName",
			headerName: "Avtal",
			sortable: true,
			align: "left",
			headerAlign: "left",
			flex: 2,
			minWidth: 200,
			renderCell: (params: GridRenderCellParams) => {
				const [modalAgreement, setModalAgreement] = useState<boolean>(false);
				return (
					<>
						{modalAgreement && (
							<ModalDialog
								isVisible={modalAgreement}
								showModal={setModalAgreement}
								label={params?.value ?? ""}
								icon={<AgreementIcon />}
								content={
									<AgreementModalContent
										salaryReportId={params.row.id}
										showModal={setModalAgreement}
									/>
								}
							/>
						)}
						<Typography
							variant='body1'
							noWrap
							onClick={() => setModalAgreement(true)}
							sx={{
								color: colors.primary,
								fontSize: 14,
								":hover": { cursor: "pointer" },
							}}
						>
							{params.value ?? ""}
						</Typography>
					</>
				);
			},
		},
		{
			field: "departmentNumber",
			headerName: "A.nummer",
			type: "number",
			sortable: true,
			align: "right",
			headerAlign: "right",
			flex: 1,
			minWidth: 120,
			renderCell: (params: GridRenderCellParams) => {
				return <Box>{params.value ?? ""} </Box>;
			},
		},
		{
			field: "workplaceMunicipality",
			headerName: "Ort",
			sortable: true,
			align: "left",
			headerAlign: "left",
			flex: 2,
			minWidth: 100,
			renderCell: (params: GridRenderCellParams) => {
				return <Box>{params.value ?? ""} </Box>;
			},
		},
		{
			field: "statusMessage",
			headerName: "Status",
			sortable: false,
			align: "right",
			headerAlign: "right",
			flex: 2,
			minWidth: 120,
			renderCell: (params: GridRenderCellParams) => {
				const statusMessage = params.value;
				const status = params.row.status;
				return (
					<Box>
						<StatusChip
							statusMessage={statusMessage && statusMessage.length > 0 ? statusMessage : ""}
							status={status ? status : 0}
						/>
					</Box>
				);
			},
		},
		{
			field: "status",
			headerName: "",
			sortable: false,
			align: "right",
			flex: 2,
			minWidth: 220,
			renderCell: (params) => {
				const id = params.row.id ?? -1;
				const year = `${params.row.salaryRevisionDate.substring(0, 4)}` ?? "1900";

				if (!isActiveSalaryProtocol) {
					if (params.value !== 100) {
						return (
							<Box pr={3}>
								<Button
									aria-label='öppna'
									variant='containedSecondary'
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										navigate(`/${isActiveSalaryProtocol ? "aktuella" : "historik"}/${year}/${id}`);
									}}
								>
									Öppna
								</Button>
							</Box>
						);
					}
					return <Box pr={3} />;
				}

				return (
					<Box pr={3}>
						{params.value !== 100 ? (
							<Button
								aria-label='öppna'
								variant='containedSecondary'
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									navigate(`/${isActiveSalaryProtocol ? "aktuella" : "historik"}/${year}/${id}`);
								}}
							>
								Öppna
							</Button>
						) : salaryReportId == id && isLoading ? (
							<LoadingButton label={"Skapar protokoll"} />
						) : (
							<Button
								value={id}
								aria-label='Ny Spara protokoll'
								variant='containedPrimary'
								startIcon={<AddIcon />}
								onClick={(e) => createSalaryProtocol(e, id, year)}
								fullWidth
							>
								Skapa protokoll
							</Button>
						)}
					</Box>
				);
			},
		},
	];

	return columns;
};
