import React, { useEffect, useState } from "react";
import { useLazyGetAgreementTypeTemplateQuery, useLazyGetSalaryReportQuery } from "api/Api";

import {
	Box,
	Typography,
	CircularProgress,
	Stack,
	DialogActions,
	Button,
	DialogContent,
	useMediaQuery,
} from "@mui/material";

import InfoBox from "components/common/infoBox/InfoBox";
import { IAgreementInfoBox } from "types/templates/CommonTemplateTypes";
import theme from "styles/theme";
import { sanitizedData } from "lib/helpers/Sanitize";
import { formatSalary } from "lib/helpers/NumberInputHelper";
import { ISalaryRevisionLimits } from "components/templates/components/EditAgreement";

interface AgreementModalContentProps {
	showModal: React.Dispatch<React.SetStateAction<boolean>>;
	editable?: boolean;
	salaryReportId?: string;
}

const AgreementModalContent: React.FC<AgreementModalContentProps> = ({
	showModal,
	salaryReportId,
}) => {
	// 2 = ombud/FFV
	const recipientType = 2;
	const isLoadingAgreement = false;
	const [agreement, setAgreement] = useState("");
	const [agreementTypeId, setAgreementTypeId] = useState<any>();
	const [salaryRevision, setSalaryRevision] = useState<any>();

	const [lazyGetAgreementTypeTemplateQuery, resultsAgreement] =
		useLazyGetAgreementTypeTemplateQuery();

	const [lazyGetSalaryReportQuery, resultsSalaryReport] = useLazyGetSalaryReportQuery();

	// hämta salaryreport info för att få ut salaryrevision och agreementTypeId
	useEffect(() => {
		if (salaryReportId && salaryReportId.length > 0) {
			lazyGetSalaryReportQuery(salaryReportId);
		}
	}, []);
	useEffect(() => {
		if (agreementTypeId && agreementTypeId.length > 0) {
			lazyGetAgreementTypeTemplateQuery({
				form: {
					agreementTypeId: agreementTypeId,
					recipientType: recipientType,
				},
			});
		}
	}, [agreementTypeId]);

	useEffect(() => {
		if (resultsSalaryReport && resultsSalaryReport.data) {
			setAgreementTypeId(resultsSalaryReport.data.agreementTypeId);
			setSalaryRevision(resultsSalaryReport.data.salaryRevision);
		}
		if (resultsAgreement && resultsAgreement) {
			if (resultsAgreement.data) {
				setAgreement(resultsAgreement.data.body);
			}
		}
	}, [resultsAgreement, resultsSalaryReport]);

	const agreementInfoBoxContent: IAgreementInfoBox[] = [];

	if (salaryRevision && salaryRevision.date) {
		const salaryRevisionLimits: ISalaryRevisionLimits[] = [];

		if (salaryRevision.salaryRevisionLimits && salaryRevision.salaryRevisionLimits.length > 0) {
			salaryRevision.salaryRevisionLimits.map(
				(s: { salaryCode: { code: string }; minimalSalary: number }) => {
					salaryRevisionLimits.push({
						code: `${" "}${s.salaryCode.code}`,
						minimalSalary: `:\u00A0${formatSalary(s.minimalSalary)}\u00A0kr`,
					});
				},
			);
		} else {
			salaryRevisionLimits.push({ code: "", minimalSalary: "-" });
		}

		salaryRevisionLimits.sort(function (a, b) {
			const textA = a.code.toUpperCase();
			const textB = b.code.toUpperCase();
			return textA < textB ? -1 : textA > textB ? 1 : 0;
		});

		agreementInfoBoxContent.push({
			"Period Lönerevision": salaryRevision.date.slice(0, 10),
			"Garanterat utfall":
				salaryRevision.guaranteedRaiseType !== 0
					? `${salaryRevision.guaranteedRaise.toString().replace(".", ",")} ${
							salaryRevision.guaranteedRaiseTypeUnit
					  }`
					: "-",
			"Avtalad höjning":
				salaryRevision.sharedRaiseType !== 0
					? `${salaryRevision.sharedRaise.toString().replace(".", ",")} ${
							salaryRevision.sharedRaiseTypeUnit
					  }`
					: "-",
			"Minimilön per lönekod": salaryRevisionLimits.map((item, i) => (
				<span style={{ paddingRight: "6px", display: "inline-block" }} key={i}>
					<span style={{ fontWeight: "500" }}>{item.code}</span>
					{item.minimalSalary}
					{i != salaryRevisionLimits.length - 1 ? "," : ""}
				</span>
			)),
		});
	}
	if (salaryRevision && !salaryRevision.date) {
		agreementInfoBoxContent.push({
			"Period Lönerevision": "Uppkommande lönerevision saknas",
			"Garanterat utfall": "-",
			"Avtalad höjning": "-",
			"Minimilön per lönekod": "-",
		});
	}

	const fullScreen = useMediaQuery(theme.breakpoints.up("md"));
	return (
		<>
			<DialogContent
				dividers
				sx={{
					display: "flex",
					alignContent: "center",
					flexDirection: "column",
					minWidth: { xxs: "100%", sm: 600 },
					maxWidth: { xxs: "100%", lg: 950 },
				}}
			>
				<Stack
					display={"flex"}
					mb={2}
					direction={{ xs: "column", sm: "row" }}
					sx={{
						width: fullScreen ? "fit-content" : "100%",
					}}
				>
					{agreementInfoBoxContent ? (
						<InfoBox infoBoxProps={agreementInfoBoxContent[0]} maxWidthCustom={300} />
					) : (
						<Typography sx={{ fontStyle: "italic", paddingLeft: { xxs: 1, lg: 2 } }}>
							Kan ej hitta avtalsinfo
						</Typography>
					)}
				</Stack>
				<Box pl={{ xxs: 1, lg: 2 }}>
					{isLoadingAgreement ? (
						<CircularProgress sx={{ margin: "auto", marginTop: 15 }} />
					) : agreement && agreement.length > 0 ? (
						<Box
							sx={{
								backgroundColor: "white",
								height: "100%",
								fontFamily: "Arial",
							}}
							dangerouslySetInnerHTML={sanitizedData(agreement)}
						></Box>
					) : agreement && agreement.length == 0 ? (
						<Typography sx={{ fontStyle: "italic" }}>Kan ej hitta avtalet</Typography>
					) : (
						<Typography sx={{ fontStyle: "italic" }}>Avtalet saknar innehåll</Typography>
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button aria-label='Avbryt' variant='grey' onClick={() => showModal(false)}>
					Avbryt
				</Button>
			</DialogActions>
		</>
	);
};
export default AgreementModalContent;
