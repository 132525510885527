import React, { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Divider, Box, Stack } from "@mui/material";
import { IAgent } from "types/dispatch/dispatchListTable/SelectAgentModal";

interface IAgentSelectProps {
	rows: IAgent[];
	placeholder: string;
	selectedAgents: IAgent[];
	setSelectedAgents: React.Dispatch<React.SetStateAction<IAgent[]>>;
}

export default function AgentSelect({
	rows,
	placeholder,
	selectedAgents,
	setSelectedAgents,
}: IAgentSelectProps) {
	const [filteredRows, setFilteredRows] = useState<IAgent[]>([]);
	const [filterValue, setFilterValue] = useState<string>("");

	useEffect(() => {
		let tempList: IAgent[] = [];
		if (filterValue == "") tempList = rows;
		else {
			rows.forEach((row: IAgent) => {
				if (
					row.name.toLowerCase().includes(filterValue.toLowerCase()) ||
					(row.sector && row.sector.toLowerCase().includes(filterValue.toLowerCase()))
				) {
					tempList.push(row);
				}
			});
		}

		setFilteredRows(tempList);
	}, [filterValue, rows]);

	const handleCheckboxChange = async (
		e: React.MouseEvent<HTMLLIElement, MouseEvent>,
		row: IAgent,
	) => {
		const tempList = [...selectedAgents];
		if (tempList.length > 0) {
			const idx = tempList.findIndex((r) => r.userId == row.userId);
			if (idx > -1) tempList.splice(idx, 1);
			else tempList.push(row);
		} else {
			tempList.push(row);
		}
		setSelectedAgents(tempList);
	};

	return (
		<Box>
			<FormControl fullWidth sx={{ border: "1px solid #EBEDF3", borderRadius: 2 }}>
				<OutlinedInput
					sx={{
						borderBottom: rows.length > 0 ? "1px solid #EBEDF3" : "",
						borderRadius: "0",
						"& fieldset": { border: "none !important" },
					}}
					placeholder={placeholder}
					onChange={(event) => setFilterValue(event.target.value)}
				/>
				{rows ? (
					<Box
						sx={{
							maxHeight: "300px",
							height: "auto",
							overflowY: "scroll",
						}}
					>
						{filteredRows.map((row: any, index: number) => {
							if (selectedAgents.filter((r) => r.userId == row.userId).length == 0) {
								return (
									<MenuItem
										key={row.userId}
										value={row.userId}
										sx={{
											flexDirection: "column",
											alignItems: "flex-start",
											borderBottom: index != filteredRows.length - 1 ? "1px solid #EBEDF3" : "",
										}}
										onClick={(e) => handleCheckboxChange(e, row)}
									>
										<Stack
											direction={"row"}
											sx={{ width: "100%", alignItems: "center", padding: "5px" }}
										>
											<Checkbox sx={{ marginRight: 2, height: "10px" }} />
											<ListItemText
												primary={row.name}
												primaryTypographyProps={{ fontSize: 13 }}
												sx={{ fontWeight: "500", flex: 2 }}
											/>
											<Stack
												direction={"row"}
												sx={{ float: "right", marginLeft: "auto", flex: 0.6 }}
											>
												<Divider orientation='vertical' flexItem sx={{ marginRight: 3 }} />
												<ListItemText
													secondary={
														row.sector ||
														(row.nationalIdentityNumber &&
															row.nationalIdentityNumber.slice(0, -4) + "xxxx") ||
														""
													}
													secondaryTypographyProps={{ fontSize: 13 }}
												/>
											</Stack>
										</Stack>
									</MenuItem>
								);
							}
						})}
					</Box>
				) : null}
			</FormControl>
		</Box>
	);
}
