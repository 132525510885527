import * as React from "react";
import { useNavigate } from "react-router-dom";

import { styled, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { GridColDef } from "@mui/x-data-grid";

import { useGetAgreementTypeTemplatesQuery } from "api/Api";
import { IContractAreaListTable } from "types/templates/CommonTemplateTypes";
import colors from "styles/colors-theme";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";
import { DataGridPro } from "@mui/x-data-grid-pro";

const columns: GridColDef[] = [
	{
		field: "agreementTypeName",
		headerName: "Namn",
		sortable: true,
		align: "left",
		headerAlign: "left",
		flex: 5,
	},
	{
		field: "updatedDate",
		headerName: "Senast ändrad",
		sortable: true,
		align: "right",
		headerAlign: "right",
		flex: 2,
		renderCell: (date) => {
			return (
				<span style={{ color: date.row.isValid ? colors.primary : colors.error2 }}>
					{date.value ? date.value.slice(0, 10) : ""}
				</span>
			);
		},
	},
];

const DataGridStyled = styled(DataGridPro)(({ theme }) => ({
	marginRight: 5,
	border: "none",
	marginTop: 16,

	"&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
		{
			outline: "none",
		},

	"& .MuiDataGrid-columnHeaders": {
		border: 0,

		"& .MuiDataGrid-columnHeader": {
			width: "100%",
			minWidth: "auto !important",
			padding: "0 24px",
		},

		"& .MuiDataGrid-columnSeparator": {
			display: "none",
		},

		"& .MuiDataGrid-iconButtonContainer .MuiIconButton-root": {
			position: "relative",
			background: "transparent",
			bottom: -10,
			visibility: "visible",
		},
	},

	"& .MuiDataGrid-row": {
		border: "2px solid #DFEFFF",
		margin: "0 0 8px",
		borderRadius: "8px",
		padding: "0 24px",
		cursor: "pointer",
		width: "auto",
		backgroundColor: "white !important",

		"& .MuiDataGrid-cell": {
			border: 0,
			fontSize: 14,
			width: "100%",
			minWidth: "auto !important",
			padding: 0,

			"&:first-of-type": {
				fontWeight: 900,
			},
		},
		"& .MuiDataGrid-cellContent": {
			fontSize: 14,

			"&:first-of-type": {
				fontWeight: 500,
			},
		},
	},
	"& .MuiDataGrid-virtualScrollerRenderZone": {
		position: "relative",
	},
})) as typeof DataGridPro;

export default function AgreementListTable({ role }: IContractAreaListTable) {
	const navigate = useNavigate();

	const recipient = LastSegmentOfUrl(location.href);
	const recipientType = recipient == "ombud-ffv" ? 2 : 1;

	const { data: rows, isLoading } = useGetAgreementTypeTemplatesQuery(recipientType);

	return (
		<Box sx={{ minWidth: "100%", border: "0" }} minWidth={"100%"}>
			{rows && rows.length > 0 ? (
				<DataGridStyled
					autoHeight
					rows={rows}
					hideFooter
					columns={columns}
					disableColumnMenu
					getRowId={(row) => row.agreementTypeId}
					onRowClick={(row) =>
						navigate(`/mallar/${role}/${row.id.toString()}`, {
							state: { agreementIdFromState: row.id },
						})
					}
					rowHeight={79}
					columnHeaderHeight={40}
				/>
			) : isLoading ? (
				<CircularProgress sx={{ marginTop: 7 }} />
			) : (
				<p>Inga listor</p>
			)}
		</Box>
	);
}
