import { styled } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ChangeEvent } from "react";

interface CheckboxLabelProps {
	label: string;
	value: string;
	handleCheckboxChange: (e: ChangeEvent<EventTarget & HTMLInputElement>) => void;
	checked: boolean;
	disabled?: boolean;
}

const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
	backgroundColor: theme.palette.background.label,
	padding: "0 10px",
	borderRadius: 6,
	height: 38,
	margin: 0,
	"& .MuiFormControlLabel-label": {
		marginTop: "2px",
		fontSize: 13,
		paddingLeft: 10,
		fontWeight: 400,
	},
})) as typeof FormControlLabel;

const CheckboxLabel = ({
	checked,
	value,
	disabled,
	label,
	handleCheckboxChange,
}: CheckboxLabelProps) => {
	return (
		<>
			<FormControlLabelStyled
				disabled={disabled || false}
				control={
					<Checkbox onChange={(e) => handleCheckboxChange(e)} checked={checked} value={value} />
				}
				label={label || ""}
			/>
		</>
	);
};

export default CheckboxLabel;
