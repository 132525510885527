import { Typography, Stack, DialogActions, Button, DialogContent } from "@mui/material";

interface UserTersModalContenttProps {
	showModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserTersModalContent = ({ showModal }: UserTersModalContenttProps) => {
	return (
		<>
			<DialogContent dividers sx={{ width: { md: 900 } }}>
				<Stack spacing={2} py={2}>
					<Typography variant='body1'>
						Svenska Elektrikerförbundet kommer i samband med att du registrerar dig som användare
						behandla dina personuppgifter.
					</Typography>
					<Typography variant='body1'>
						Svenska Elektrikerförbundet tillhandahåller ett system för att tillvarata rättigheter
						för medlemmar i lönerevisionen, det handlar om månadslön, fördelning av lönepotten, rätt
						karriärsteg och därtill nödvändiga personuppgifter.
					</Typography>
					<Typography variant='body1'>
						Du som användare får tillgång till de personuppgifter som hanteras i
						månadslönerevisionen. Uppgifterna i systemet ska endast användas för administration av
						månadslönerevision och statistik. I och med att du godkänner dessa användarvillkor
						förbinder du dig att inte avslöja någon information om de registrerade, vilket även
						inkluderar informationen om en person är medlem i Svenska Elektrikerförbundet eller
						inte. Fackligt medlemskap är en känslig personuppgift enligt GDPR och ska behandlas med
						en högre nivå av skyddsåtgärder. Du får därmed inte sprida eller använda dig av de
						personuppgifter som behandlas i andra syften än ovanstående.
					</Typography>
					<Typography variant='body1'>
						De personuppgifter som behandlas i systemet är namn, personnummer, arbetsgivare,
						arbetsplats, lön samt medlemsnummer i Svenska Elektrikerförbundet.
					</Typography>
					<Typography variant='body1'>
						De personer vars personuppgifter finns registrerade i systemet är personer som omfattas
						av månadslönerevisionen, arbetsgivare, administratörer/annan personal från Svenska
						Elektrikerförbundet.
					</Typography>
					<Typography variant='body1'>
						Behandlingen av personuppgifter sker med stöd av kollektivavtalet och
						Elektrikerförbundets stadgar.
					</Typography>
					<Typography variant='body1'>
						De personer som är registrerade i Lönewebben har enligt GDPR en rad rättigheter. De har
						rätt att:
					</Typography>
					<ul>
						<li>
							<Typography variant='body1'>
								få information om vilka personuppgifter vi behandlar och hur vi behandlar dem
							</Typography>
						</li>
						<li>
							<Typography variant='body1'>begära ut registerutdrag</Typography>
						</li>
						<li>
							<Typography variant='body1'>få sina uppgifter rättade och gallrade</Typography>
						</li>
						<li>
							<Typography variant='body1'>
								ta med sig sina uppgifter, så kallad dataportabilitet.
							</Typography>
						</li>
					</ul>
					<Typography variant='body1'>
						Har de registrerade frågor om sina rättigheter eller hur Elektrikerförbundet hanterar
						deras personuppgifter ska de kontakta Elektrikerförbundet på{" "}
						<a href='mailto:kontakten@sef.se'>kontakten@sef.se</a>.
					</Typography>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button aria-label='Avbryt' variant='grey' onClick={() => showModal(false)}>
					Avbryt
				</Button>
			</DialogActions>
		</>
	);
};
export default UserTersModalContent;
