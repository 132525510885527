import { CircularProgress, Box, Typography } from "@mui/material";
import {
	useGetAttachmentsQuery,
	useGetAttachmentDownloadMutation,
	useGetRemoveAttachmentMutation,
} from "api/Api";
import { openConfirmation } from "components/common/confirmDialog/ConfirmationSlice";
import { enqueueSnackbar } from "notistack";
import { Link } from "react-router-dom";
import theme from "styles/theme";
import colors from "styles/colors-theme";
import { useDispatch } from "react-redux";
import { ReactComponent as AttachmentIcon } from "assets/icons/paperclip-regular.svg";
import CloseIcon from "@mui/icons-material/Close";

interface IProtocolAttachmentList {
	salaryReportId: string;
	disable: boolean;
}

interface IFile {
	name: string;
	id: string;
}

const ProtocolAttachmentList = ({ salaryReportId, disable }: IProtocolAttachmentList) => {
	const {
		data: fileData,
		isFetching: isLoadingFile,
		isError: isErrorLoadingFile,
		refetch: refetchFile,
	} = useGetAttachmentsQuery(salaryReportId);
	const [getAttachmentDownload] = useGetAttachmentDownloadMutation();
	const [getRemoveAttachment] = useGetRemoveAttachmentMutation();

	const dispatch = useDispatch();

	function handleDeleteFile(e: React.MouseEvent<HTMLDivElement, MouseEvent>, fileId: string) {
		e.preventDefault();
		if (!disable) {
			dispatch(
				openConfirmation({
					message: ["Är du säker på att du vill radera bilagan?", "Obs! Detta går inte att ångra."],
					callback: (confirmed: boolean) => {
						if (confirmed) {
							const form = { salaryReportId: salaryReportId, attachmentId: fileId };

							getRemoveAttachment(form)
								.unwrap()
								.then(() => {
									enqueueSnackbar("Bilaga borttagen", {
										variant: "success",
									});
									refetchFile();
								})
								.catch(() => {
									enqueueSnackbar("Kan ej radera bilaga", {
										variant: "error",
										persist: true,
									});
								});
						}
					},
				}),
			);
		} else {
			return;
		}
	}

	function handleDownloadFile(file: IFile) {
		if (file) {
			const form = {
				salaryReportId: salaryReportId,
				attachmentId: file.id,
				name: file.name,
			};
			getAttachmentDownload(form);
		}
	}

	if (isLoadingFile) {
		return (
			<Box ml={{ xxs: 0, sm: 2 }} my={{ xxs: 2, sm: 0 }} sx={{ width: { xxs: "100%", sm: 210 } }}>
				<CircularProgress size={20} sx={{ marginLeft: 2, marginTop: 1 }} />
			</Box>
		);
	} else if (isErrorLoadingFile) {
		return (
			<Typography variant='body0' sx={{ fontWeight: 600, fontStyle: "italic" }}>
				Bifogad bilaga kunde inte hämtas
			</Typography>
		);
	}
	return (
		<Box sx={{ display: "flex", gap: 2, marginBottom: 2, flexWrap: "wrap" }}>
			{fileData.map((file: IFile) => {
				return (
					<Box
						key={file.id}
						sx={{
							minHeight: 40,
							borderRadius: 2,
							border: `1px solid ${colors.border.main}`,
							display: "inline-flex",
							alignItems: "center",
							maxWidth: "100%",
						}}
					>
						<Box
							onClick={() => handleDownloadFile(file)}
							sx={{
								fontSize: 13,
								color: colors.primary,
								fontWeight: 400,
								height: 40,
								padding: "0 16px",
								"&:hover": {
									backgroundColor: colors.text.lightestGrey,
									cursor: "pointer",
								},

								overflow: "hidden",
								whiteSpace: "nowrap",
								display: "flex",
								alignItems: "center",
								width: { xxs: "100%", sm: "auto" },
							}}
						>
							<Box
								sx={{
									width: "100%",
									display: "flex",
									alignItems: "center",
									"&:hover": {
										backgroundColor: colors.text.lightestGrey,
									},
								}}
							>
								<AttachmentIcon
									color={theme.palette.primary.main}
									style={{
										minWidth: "15px",
										minHeight: "15px",
										width: "15px",
										height: "15px",
										marginRight: "8px",
									}}
								/>

								<Typography
									color={theme.palette.primary.main}
									sx={{
										fontSize: 13,
										fontWeight: 400,
									}}
									noWrap
								>
									{file.name}
								</Typography>
							</Box>
						</Box>
						<Box
							onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
								handleDeleteFile(e, file.id)
							}
							sx={{
								height: 40,
								display: "flex",
								alignItems: "center",
								borderLeft: `1px solid ${colors.border.main}`,
								backgroundColor: disable ? theme.palette.secondary.main : "",

								"&:hover": {
									backgroundColor: disable ? "" : colors.text.lightestGrey,
									cursor: disable ? "" : "pointer",
								},
							}}
						>
							<CloseIcon
								style={{
									fill: theme.palette.info.main,
									width: "15px",
									height: "15px",
									margin: "0 8px",
									position: "static",
								}}
								aria-label='Delete file'
							/>
						</Box>
					</Box>
				);
			})}
		</Box>
	);
};

export default ProtocolAttachmentList;
