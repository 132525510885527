import React, { useEffect, useState } from "react";

import {
	useGetRemoveSalaryProtocolRowMutation,
	useGetSalaryReportQuery,
	useGetSalaryReportRowsQuery,
	useGetUserFromDirectoryQuery,
} from "api/Api";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";
import { roles } from "api/user/Roles";

import { IconButton, Menu, MenuItem, styled, ListItemIcon } from "@mui/material";

import ModalDialog from "components/common/modal/ModalDialog";
import { ISalaryProtocolTableRow } from "types/representative/commonSalaryReportTypes";
import AddWorkerModalContent from "../addButton/AddWorkerModalContent";
import IgnoreWorkerModalContent from "./IgnoreWorkerModalContent";

import { openConfirmation } from "components/common/confirmDialog/ConfirmationSlice";

import { ReactComponent as SettingsIcon } from "assets/icons/settings-grey.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as UserColorIcon } from "assets/icons/user-group-21.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as IgnoreIcon } from "assets/icons/square-minus-regular.svg";
import { ReactComponent as IncludeIcon } from "assets/icons/square-plus-regular.svg";
import { IResponsible } from "types/dispatch/dispatchListTable/SelectAgentModal";

interface ButtonProps {
	open?: boolean;
}

const IconButtonStyled = styled(IconButton, {
	shouldForwardProp: (prop) => prop !== "open",
})<ButtonProps>(({ theme, open }) => ({
	backgroundColor: open === true ? theme.palette.background.label : "transparent",
	"&:hover #Group_5 .settings": {
		fill: theme.palette.primary.main,
	},
	"& #Group_5 .settings": {
		fill: open && theme.palette.primary.main,
	},
}));

interface RowMenuProps {
	row: ISalaryProtocolTableRow;
}

export default function RowMenuSalaryProtocol({ row }: RowMenuProps) {
	const salaryReportId = LastSegmentOfUrl(location.href);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [showIgnoreModal, setShowIgnoreModal] = useState<boolean>(false);
	const [ignored, setIgnored] = useState<boolean>(false);
	const [worker, setWorker] = useState<string | null>("");

	const { data: userData } = useGetUserFromDirectoryQuery(null);
	const activeRole = roles.find((r) => r.roleName == userData?.activeRole)?.uiRoleName;

	const [getRemoveSalaryProtocolRow] = useGetRemoveSalaryProtocolRowMutation();
	const { refetch: refetchSalaryReportRows } = useGetSalaryReportRowsQuery(salaryReportId);
	const { data: salaryProtocolData } = useGetSalaryReportQuery(salaryReportId);

	const isOmbud =
		salaryProtocolData.responsibles.findIndex((r: IResponsible) => r.userId == userData.id) >= 0;
	const ombudCanEdit = isOmbud && salaryProtocolData.status == 250;
	const disableNonAdmin = userData.activeRole == "non-admin" && salaryProtocolData.status >= 250;
	const isArchived = salaryProtocolData?.dispatchListArchivedDate != null ?? false;

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleEditList = () => {
		setShowEditModal(true);
		setAnchorEl(null);
	};

	useEffect(() => {
		const name = row?.name || "";
		const birthDate = row?.birthDate?.slice(0, 8) || "";
		const sefMemberNumber = row?.sefMemberNumber || "";

		if (name.length > 0 && sefMemberNumber.length > 0) {
			setWorker(name + " " + sefMemberNumber);
		} else if (name.length > 0 && birthDate.length > 0) {
			setWorker(name + " " + birthDate);
		} else if (name.length > 0) {
			setWorker(name);
		} else {
			setWorker(null);
		}
		setIgnored(row?.ignored);
	}, [row]);

	const dispatch = useDispatch();
	function handleDelete() {
		dispatch(
			openConfirmation({
				message: [
					`Är du säker på att du vill radera ${worker != null ? worker : "arbetstagare"}?`,
					"Obs! Detta går inte att ångra.",
				],
				callback: (confirmed: boolean) => {
					if (confirmed) {
						const form = {
							salaryRowId: row.salaryRowId,
							salaryReportId: salaryReportId,
						};
						getRemoveSalaryProtocolRow(form)
							.unwrap()
							.then((response) => {
								enqueueSnackbar(`${worker != null ? worker : "arbetstagare "} raderad.`, {
									variant: "success",
								});
								refetchSalaryReportRows();
							})
							.catch((error) => {
								enqueueSnackbar(error.data.detail, {
									variant: "error",
									persist: true,
								});
							});
					}
				},
			}),
		);
	}

	return (
		<>
			{showIgnoreModal && (
				<ModalDialog
					isVisible={showIgnoreModal}
					showModal={setShowIgnoreModal}
					label={`${!ignored ? "Ignorera" : "Inkludera"} ${
						worker != null ? worker : "arbetstagare"
					}`}
					icon={<UserColorIcon />}
					content={
						<IgnoreWorkerModalContent
							showModal={setShowIgnoreModal}
							workerId={row.salaryRowId || ""}
						/>
					}
				/>
			)}
			{showEditModal && (
				<ModalDialog
					isVisible={showEditModal}
					showModal={setShowEditModal}
					label={"Redigera arbetstagare"}
					icon={<UserColorIcon />}
					content={
						<AddWorkerModalContent
							showModal={setShowEditModal}
							inEditMode={true}
							salaryRowId={row.salaryRowId}
						/>
					}
				/>
			)}
			<IconButtonStyled
				onClick={handleClick}
				aria-label='redigera'
				size='small'
				aria-haspopup='true'
				aria-controls={open ? "redigera" : undefined}
				aria-expanded={open ? "true" : undefined}
				open={open}
				disabled={(disableNonAdmin || isArchived) && !ombudCanEdit}
			>
				<SettingsIcon />
			</IconButtonStyled>
			<Menu
				anchorEl={anchorEl}
				id='account-menu'
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
				}}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<MenuItem onClick={handleEditList}>
					<ListItemIcon>
						<EditIcon />
					</ListItemIcon>
					Redigera
				</MenuItem>
				<MenuItem onClick={() => setShowIgnoreModal(true)}>
					<ListItemIcon>{ignored ? <IncludeIcon /> : <IgnoreIcon />}</ListItemIcon>
					{ignored ? "Inkludera" : "Ignorera"}
				</MenuItem>
				{activeRole === "System Admin" ||
				activeRole === "Administratör" ||
				(row.addedMethod > 0 && activeRole === "Förtroendevald") ? (
					<MenuItem onClick={() => handleDelete()}>
						<ListItemIcon>
							<DeleteIcon />
						</ListItemIcon>
						Radera
					</MenuItem>
				) : null}
			</Menu>
		</>
	);
}
