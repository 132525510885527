import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "components/layout/Layout";
import DispatchOverviewList from "components/dispatchOverview/DispatchOverviewList";
import DispatchList from "components/dispatch/DispatchList";
import TemplatesOverview from "components/templates/TemplatesOverview";
import EditAgreement from "components/templates/components/EditAgreement";
import SalaryProtocolList from "components/representative/salaryProtocol/SalaryProtocolList";
import DuplicatesList from "components/duplicates/DuplicatesList";

const AdminRoute = () => {
	return (
		<Routes>
			<Route
				path='/utskickslistor/aktuella/'
				element={
					<Layout>
						<DispatchOverviewList />
					</Layout>
				}
			/>

			<Route
				path='/utskickslistor/arkiverade/'
				element={
					<Layout>
						<DispatchOverviewList />
					</Layout>
				}
			/>

			<Route
				path='/utskickslistor/aktuella/:id'
				element={
					<Layout>
						<DispatchList />
					</Layout>
				}
			/>

			<Route
				path='/utskickslistor/aktuella/:id/:id'
				element={
					<Layout>
						<SalaryProtocolList />
					</Layout>
				}
			/>

			<Route
				path='/utskickslistor/arkiverade/:id'
				element={
					<Layout>
						<DispatchList />
					</Layout>
				}
			/>

			<Route
				path='/utskickslistor/arkiverade/:id/:id'
				element={
					<Layout>
						<SalaryProtocolList />
					</Layout>
				}
			/>

			<Route
				path='/mallar/arbetsgivare/'
				element={
					<Layout>
						<TemplatesOverview />
					</Layout>
				}
			/>

			<Route
				path='/mallar/ombud-ffv/'
				element={
					<Layout>
						<TemplatesOverview />
					</Layout>
				}
			/>

			<Route
				path='/mallar/arbetsgivare/:id'
				element={
					<Layout>
						<EditAgreement />
					</Layout>
				}
			/>

			<Route
				path='/mallar/ombud-ffv/:id'
				element={
					<Layout>
						<EditAgreement />
					</Layout>
				}
			/>

			<Route
				path='/utskickslistor/dubbletter'
				element={
					<Layout>
						<DuplicatesList />
					</Layout>
				}
			/>

			<Route path='/mallar/*' element={<Navigate to='/mallar/ombud-ffv/' />} />
			<Route path='/utskickslistor/*' element={<Navigate to='/utskickslistor/aktuella/' />} />
			<Route path='*' element={<Navigate to='/utskickslistor/aktuella/' />} />
		</Routes>
	);
};

export default AdminRoute;
