import { Box, Card, CardHeader, Collapse, OutlinedInput, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { useNavigate } from "react-router-dom";
import { LayoutBoxStyled, LayoutCardStyled } from "components/common/styledComponents/Layout";

import SearchIcon from "@mui/icons-material/Search";

import { ListItemCard } from "components/common/list/ListItemCard";

import { ReactComponent as DuplicateIcon } from "assets/icons/Group 9.svg";
import { ReactComponent as ShowMoreIcon } from "assets/icons/show-more.svg";

import { useEffect, useState } from "react";
import theme from "styles/theme";
import ExpandButton from "components/common/buttons/ExpandButton";
import { useGetAllActiveQuery, useLazyGetDuplicateSalaryRowEmployeesQuery } from "api/Api";
import { IActiveList } from "types/dispatch/DispatchList";
import { DuplicatesListGridColDef, filterRows, IDuplicate } from "./IDuplicatesList";

export default function DuplicatesList() {
	const { data } = useGetAllActiveQuery(null);
	const [getDuplicateSalaryRowEmployees] = useLazyGetDuplicateSalaryRowEmployeesQuery();

	const [filteredRows, setFilteredRows] = useState<IDuplicate[]>([]);
	const [filterText, setFilterText] = useState("");
	const [expandedRows, setExpandedRows] = useState<number[]>([]);
	const [rows, setRows] = useState<IDuplicate[]>([]);

	const navigate = useNavigate();

	useEffect(() => {
		let ids = "";
		if (data) {
			data.map((d: IActiveList) => {
				if (ids != "") {
					ids += "&DispatchListIds=" + d.id;
				} else {
					ids += "DispatchListIds=" + d.id;
				}
			});
		}
		getDuplicateSalaryRowEmployees(ids)
			.unwrap()
			.then((response: IDuplicate[]) => {
				setRows(response);
			});
	}, [data]);

	useEffect(() => {
		const filteredList = filterRows(rows, filterText);
		setFilteredRows(filteredList);
	}, [filterText]);

	const handleExpandAll = (expand: boolean) => {
		if (expand) {
			const ids = rows.map((r) => r.nationalIdenityNumber);
			setExpandedRows(ids);
		} else {
			setExpandedRows([]);
		}
	};

	const handleExpandRow = (userNumber: number) => {
		if (expandedRows.includes(userNumber)) {
			setExpandedRows(expandedRows.filter((c) => c !== userNumber));
		} else {
			setExpandedRows((oldValue) => [...oldValue, userNumber]);
		}
	};

	const displayRows =
		filterText != "" || filteredRows.length > 0 ? filteredRows : rows.length > 0 ? rows : [];

	return (
		<LayoutBoxStyled>
			<LayoutCardStyled>
				<ListItemCard
					header='Dubbletter'
					icon={<DuplicateIcon />}
					headerAction={
						<>
							<ExpandButton
								expand={expandedRows.length == rows.length}
								onClick={() => handleExpandAll(expandedRows.length != rows.length)}
								sx={{
									// background: theme.palette.background.lightgrey,
									marginRight: "6px",
									border: `1px solid ${theme.palette.border.light}`,
									background:
										expandedRows.length == rows.length ? theme.palette.background.grey : "inherit",
								}}
							>
								<ShowMoreIcon />
							</ExpandButton>
							<OutlinedInput
								fullWidth
								placeholder='Filtrera'
								onChange={(event) => {
									{
										setFilterText(event.target.value);
									}
								}}
								startAdornment={<SearchIcon sx={{ marginLeft: "8px", color: "#B5B5C3" }} />}
							/>
						</>
					}
					content={
						<>
							{displayRows.length > 0 ? (
								displayRows.map((user) => {
									return (
										<Card
											key={user.name + user.nationalIdenityNumber}
											sx={{
												borderRadius: 5,
												border: `2px solid ${theme.palette.secondary.main}`,
												margin: "10px 0",
												boxShadow: "none",
											}}
										>
											<CardHeader
												title={
													<Box sx={{ display: "flex", alignItems: "center" }}>
														<Box width={250}>
															<Typography sx={{ fontSize: 14, fontWeight: 400 }}>
																{user.name}
															</Typography>
															<Typography sx={{ fontSize: 14, fontWeight: 400 }}>
																{user.nationalIdenityNumber}
															</Typography>
														</Box>
														<Box
															sx={{
																display: "flex",
																alignItems: "center",
															}}
														>
															<p>
																Finns på{" "}
																<span style={{ color: theme.palette.primary.main }}>
																	{user.duplicateCount}
																</span>{" "}
																arbetsställen
															</p>
														</Box>
													</Box>
												}
												titleTypographyProps={{
													fontSize: 14,
													fontWeight: 500,
												}}
												subheaderTypographyProps={{
													fontSize: 14,
													fontWeight: 500,
												}}
												sx={{
													cursor: "pointer",
													"& .MuiCardHeader-content": {
														paddingTop: "0",
													},
													[theme.breakpoints.down("md")]: {
														padding: "24px",
														"& .MuiCardHeader-action": {
															display: "none",
														},
													},
												}}
												action={
													<ExpandButton expand={expandedRows.includes(user.nationalIdenityNumber)}>
														<ShowMoreIcon />
													</ExpandButton>
												}
												onClick={() => {
													handleExpandRow(user.nationalIdenityNumber);
												}}
											></CardHeader>
											<Collapse
												unmountOnExit
												timeout='auto'
												in={expandedRows.includes(user.nationalIdenityNumber)}
											>
												<DataGridPro
													onRowClick={(row) => {
														navigate(
															`/utskickslistor/aktuella/${row.row.dispatchListId}/${row.row.salaryReportId}?filterValue=${row.row.name}`,
															{
																state: {
																	fromDuplicates: true,
																},
															},
														);
													}}
													sortingOrder={["desc", "asc"]}
													rowHeight={60}
													rows={user.salaryRowEmployeesDto}
													columns={DuplicatesListGridColDef}
													getRowId={(row) => row.salaryRowId}
													disableRowSelectionOnClick
													disableColumnSelector
													disableColumnMenu
													autosizeOnMount
													hideFooter
													getRowClassName={(params) =>
														params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
													}
													pagination
													initialState={{
														filter: {
															filterModel: {
																items: [],
																quickFilterExcludeHiddenColumns: true,
															},
														},

														sorting: {
															sortModel: [{ field: "namn", sort: "asc" }],
														},
													}}
													sx={{
														padding: "0 24px",
														"& .MuiDataGrid-footerContainer": {
															display: "none",
														},
														"& .MuiDataGrid-row:hover": {
															cursor: "pointer",
														},
													}}
												/>
											</Collapse>
										</Card>
									);
								})
							) : (
								<Typography>Inga dubbletter</Typography>
							)}
						</>
					}
				/>
			</LayoutCardStyled>
		</LayoutBoxStyled>
	);
}
