import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import { closeConfirmation } from "./ConfirmationSlice";
import LoadingButton from "../loading/LoadingButton";

function GlobalConfirmDialog() {
	const { isOpen, message, callback } = useSelector((state: any) => state.confirmation);
	const dispatch = useDispatch();

	const [loading, setLoading] = React.useState(false);

	function handleClose() {
		dispatch(closeConfirmation());
	}

	function handleConfirm() {
		setLoading(true);
		if (callback) {
			callback(true);
		}
		handleClose();
		setLoading(false);
	}

	function handleCancel() {
		if (callback) {
			callback(false);
		}
		handleClose();
	}

	return (
		<Dialog open={isOpen} onClose={handleClose}>
			<DialogContent sx={{ p: "30px 24px 0" }}>
				{message.map((m: string, index: number) => {
					return (
						<Typography key={index} sx={{ fontWeight: 400, fontSize: "14px" }}>
							{m}
						</Typography>
					);
				})}
			</DialogContent>
			<DialogActions sx={{ p: "30px 24px" }}>
				<Button variant='secondaryTable' onClick={handleCancel} disabled={loading}>
					Avbryt
				</Button>
				{loading ? (
					<LoadingButton label={"Sparar"} size='small' />
				) : (
					<Button variant='primaryTable' onClick={handleConfirm}>
						Bekräfta
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}

export default GlobalConfirmDialog;
