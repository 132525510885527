import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
	useGetByIdMMutation,
	useGetByIdQuery,
	useGetSendDispatchesMutation,
	useGetSendSalaryReportDispatchesMutation,
	useLazyGetAgreementTypeTemplateQuery,
	useLazyGetDispatchTemplateQQuery,
} from "api/Api";

import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";
import {
	ISalaryReport,
	ISalaryReportRecipient,
	ISalaryReportWorkplace,
} from "types/dispatch/dispatchListTable/DispatchListTableTypes";

import DispatchListsContent, { IContent } from "./DispatchListsContent";
import LoadingButton from "components/common/loading/LoadingButton";

import { theme } from "styles/theme";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
	Box,
	Stack,
	Tabs,
	Button,
	styled,
	Tab,
	DialogActions,
	Paper,
	Typography,
	Checkbox,
	useMediaQuery,
	DialogContent,
	FormControlLabel,
	CircularProgress,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import colors from "styles/colors-theme";

export interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const PaperStyled = styled(Paper)(() => ({
	border: "2px solid #DFEFFF",
	margin: "0 0 10px",
	maxHeight: "44px",
	borderRadius: "11px",
	padding: "13px 16px 12px 16px",
	boxShadow: "none",
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	width: "100%",
})) as typeof Paper;

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

interface CreateDispatchModalProps {
	showModal: React.Dispatch<React.SetStateAction<boolean>>;
	salaryReportId?: string;
}

const SendDispatchListsModalContent: React.FC<CreateDispatchModalProps> = ({
	showModal,
	salaryReportId,
}) => {
	const [tabValue, setTabValue] = useState<0 | 1 | 2>(0);
	const [isLoadingMessage, setIsLoadingMessage] = useState<boolean>(false);
	const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

	const { state } = useLocation();
	const { dispatchListIdFromState } = state || "";
	const dispatchListIdFromUrl = LastSegmentOfUrl(location.href);
	const dispatchListId = dispatchListIdFromState ? dispatchListIdFromState : dispatchListIdFromUrl;

	const [dispatchTypeName, setDispatchTypeName] = useState("Första utskick");
	const [dispatchType, setDispatchType] = useState(10);

	const [recipients, setRecipients] = useState<ISalaryReportRecipient[]>([]);
	const [recipientsAll, setRecipientsAll] = useState<ISalaryReportRecipient[]>([]);
	const [recipientsNoAddress, setRecipientsNoAddress] = useState<ISalaryReportRecipient[]>([]);
	const [selectedRecipients, setSelectedRecipients] = useState<ISalaryReportRecipient[]>([]);

	const [workplace, setWorkplace] = useState<ISalaryReportWorkplace[]>([]);
	const [selectedWorkplace, setSelectedWorkplace] = useState<ISalaryReportWorkplace[]>([]);

	const [agreementTypeId, setAgreementTypeId] = useState<string>("");
	const [messageTemplate, setMessageTemplate] = useState<string[]>([]);
	const [messageAgreement, setMessageAgreement] = useState<string[]>([]);
	const [salaryProtocol, setSalaryProtocol] = useState<string[]>([]);
	const [content, setContent] = useState<IContent>({
		agreementTypeName: "",
		name: "",
		departmentNumbers: 0,
		dispatchTypeText: "",
		workplaceSum: 0,
		recipientSum: 0,
	});

	const handleChangeTab = (event: React.SyntheticEvent, newValue: 0 | 1 | 2) => {
		setTabValue(newValue);
	};

	const { refetch: refreshDataById } = useGetByIdQuery(dispatchListId);
	const [getByIdMMutation] = useGetByIdMMutation();

	const [lazyGetAgreementTypeTemplateWorker, resultsAgreementEmployer] =
		useLazyGetAgreementTypeTemplateQuery();
	const [lazyGetAgreementTypeTemplateRecipient, resultsAgreementRecipient] =
		useLazyGetAgreementTypeTemplateQuery();

	const [lazyGetTemplateEmployer, resultsTemplateEmployer] = useLazyGetDispatchTemplateQQuery();
	const [lazyGetTemplateRecipient, resultsTemplateRecipient] = useLazyGetDispatchTemplateQQuery();

	const [getSendSalaryReportDispatches, { isLoading: loadingSendSalaryReportDispatches }] =
		useGetSendSalaryReportDispatchesMutation();

	const [getSendDispatches, { isLoading: loadingSendDispatches }] = useGetSendDispatchesMutation();

	useEffect(() => {
		if (dispatchListId) getData();
	}, [dispatchListId, tabValue]);

	useEffect(() => {
		if (agreementTypeId && agreementTypeId.length > 0) {
			lazyGetAgreementTypeTemplateWorker({
				form: {
					agreementTypeId: agreementTypeId,
					recipientType: 1,
				},
			});
			lazyGetAgreementTypeTemplateRecipient({
				form: {
					agreementTypeId: agreementTypeId,
					recipientType: 2,
				},
			});
		}
	}, [agreementTypeId]);

	const fetchMessage = async (dispatchTypeValue: number) => {
		setIsLoadingMessage(true);
		await lazyGetTemplateEmployer({
			form: {
				recipientType: 1,
				dispatchType: dispatchTypeValue,
			},
		});
		await lazyGetTemplateRecipient({
			form: {
				recipientType: 2,
				dispatchType: dispatchTypeValue,
			},
		});
		setIsLoadingMessage(false);
	};

	useEffect(() => {
		let dispatchTypeValue = 10;
		let dispatchTypeNameValue = "Första utskick";

		if (tabValue == 0) {
			fetchMessage(dispatchTypeValue);
		}

		if (tabValue == 1) {
			dispatchTypeNameValue = "Påminnelse 1";
			dispatchTypeValue = 20;
			fetchMessage(dispatchTypeValue);
		}
		if (tabValue == 2) {
			dispatchTypeNameValue = "Påminnelse 2";
			dispatchTypeValue = 30;
			fetchMessage(dispatchTypeValue);
		}
		setDispatchType(dispatchTypeValue);
		setDispatchTypeName(dispatchTypeNameValue);
	}, [tabValue]);

	const getData = async () => {
		setIsLoadingData(true);

		await getByIdMMutation(dispatchListId)
			.unwrap()
			.then((data) => {
				if (data) {
					const dispatchTypeFromTabValue = tabValue === 0 ? 10 : tabValue === 1 ? 20 : 30;
					// avdelningar
					const departmentNumbers = data.departmentNumbers
						.reduce(
							(accumulator: string | string[], value: string) => accumulator.concat(value),
							[],
						)
						.sort((a: number, b: number) => a - b)
						.join(", ");
					// antal förtroendevalda
					const allRecipients: ISalaryReportRecipient[] = [];

					data.salaryReports.map((report: ISalaryReport) => {
						report.representativeRecipients.map((r: ISalaryReportRecipient) =>
							allRecipients.push(r),
						);
					});

					// förtroendevalda
					const recipientsWithSomeAddressActivated: string[] = [];
					data.salaryReports.map((r: ISalaryReport) =>
					{
						r.representativeRecipients.map((rr: ISalaryReportRecipient) =>
						{
							if (
								// has not received selected dispatch on email
								((rr.latestEmailDispatch &&
									rr.latestEmailDispatch.type < dispatchTypeFromTabValue) ||
									!rr.latestEmailDispatch) &&
								// and has not received selected dispatch on letter
								((rr.latestLetterDispatch &&
									rr.latestLetterDispatch.type < dispatchTypeFromTabValue) ||
									!rr.latestLetterDispatch) &&
								// has appropriate salaryreport status for dispatchType
								((dispatchTypeFromTabValue === 10 && !(r.status >= 250)) // First dispatchType and status is not sent
									|| (dispatchTypeFromTabValue > 10 && !(r.status >= 200))) && // Reminder dispatchType and status is not started
								// and has any address or email activated
								(rr.addressActivated || rr.emailActivated)
							) {
								recipientsWithSomeAddressActivated.push(rr.recipientId!);
							}
						});
					});

					const recipientsNoAddress = allRecipients.filter((r: ISalaryReportRecipient) => {
						if (!r.hasAddress && !r.hasEmail) return r;
					});

					// arbetsställe
					const workplaceWithSomeAddressActivated: string[] = [];
					data.salaryReports.map((r: ISalaryReport) => {
						if (
							// has not received selected dispatch on email
							((r.workplace.latestEmailDispatch &&
								r.workplace.latestEmailDispatch.type < dispatchTypeFromTabValue) ||
								!r.workplace.latestEmailDispatch) &&
							// and has not received selected dispatch on letter
							((r.workplace.latestLetterDispatch &&
								r.workplace.latestLetterDispatch.type < dispatchTypeFromTabValue) ||
								!r.workplace.latestLetterDispatch) &&
							// has appropriate salaryreport status for dispatchType
							((dispatchTypeFromTabValue === 10 && !(r.status >= 250)) // First dispatchType and status is not sent
								|| (dispatchTypeFromTabValue > 10 && !(r.status >= 200))) && // Reminder dispatchType and status is not started
							// and has any address or email activated
							(r.workplace.addressActivated || r.workplace.emailActivated)
						) {
							workplaceWithSomeAddressActivated.push(r.workplace.recipientId);
						}
					});
					// set content
					const tempList: any[] = [];

					if (!salaryReportId) {
						tempList.push({
							agreementTypeName: data.agreementTypeName,
							name: data.name,
							departmentNumbers: departmentNumbers,
							workplaceSum: workplaceWithSomeAddressActivated.length,
							recipientSum: recipientsWithSomeAddressActivated.length,
						});
						setContent(tempList[0]);
					}

					setAgreementTypeId(data.agreementTypeId);
					setRecipientsNoAddress(recipientsNoAddress);
					setRecipientsAll(allRecipients);

					// show checkbox list when coming from settings button
					if (salaryReportId) {
						const salaryReport = data.salaryReports.filter(
							(r: ISalaryReport) => r.id === salaryReportId,
						);

						const allRecipients: ISalaryReportRecipient[] = [];

						salaryReport.map((report: ISalaryReport) => {
							report.representativeRecipients.map((r: ISalaryReportRecipient) =>
								allRecipients.push(r),
							);
						});

						const recipientsWithSomeAddressActivated = allRecipients.filter(
							(r: ISalaryReportRecipient) => {
								if (r.addressActivated || r.emailActivated) return r;
							},
						);

						const noAddressActivated = allRecipients.filter((r: ISalaryReportRecipient) => {
							if (!r.addressActivated && !r.emailActivated) return r;
						});

						!salaryReport[0].workplace.addressActivated &&
							!salaryReport[0].workplace.emailActivated &&
							noAddressActivated.push(salaryReport[0].workplace);

						const workPlace: ISalaryReportWorkplace[] = [];
						workPlace.push(salaryReport[0].workplace);

						const workplaceWithSomeAddress: ISalaryReportWorkplace[] = [];
						if (
							salaryReport[0].workplace.addressActivated ||
							salaryReport[0].workplace.emailActivated
						) {
							workplaceWithSomeAddress.push(salaryReport[0].workplace);
						}

						setWorkplace(workPlace);
						setSelectedWorkplace(workplaceWithSomeAddress);

						setRecipients(recipientsWithSomeAddressActivated);
						setSelectedRecipients(recipientsWithSomeAddressActivated);

						setRecipientsNoAddress(noAddressActivated);
						setRecipientsAll(salaryReport[0].representativeRecipients);

						tempList.push({
							agreementTypeName: data.agreementTypeName,
							name: data.name,
							departmentNumbers: departmentNumbers,
							workplaceSum: workplaceWithSomeAddress.length,
							recipientSum: recipientsWithSomeAddressActivated.length,
						});
						setContent(tempList[0]);
					}
				}
			});
		setIsLoadingData(false);
	};

	useEffect(() => {
		const tempArr: string[] = [];
		if (resultsAgreementEmployer && resultsAgreementEmployer.data) {
			const data = resultsAgreementEmployer.data;
			if (!data.isValid) {
				const m = data.updatedDate
					? `Mall gällande avtalsområdet för <span style="font-weight:600;">Arbetsgivare</span> är senast uppdaterat <span style="color:#F64E60">${data.updatedDate.slice(
							0,
							10,
					  )}</span>.`
					: 'Mall gällande avtalsområdet för <span style="font-weight:600;">Arbetsgivare</span> är inte uppdaterat.';

				tempArr.push(m);
			}
		}
		if (resultsAgreementRecipient && resultsAgreementRecipient.data) {
			const data = resultsAgreementRecipient.data;
			if (!data.isValid) {
				const m = data.updatedDate
					? `Mall gällande avtalsområdet för <span style="font-weight:600;">Förtroendevald</span> är senast uppdaterat <span style="color:#F64E60">${data.updatedDate.slice(
							0,
							10,
					  )}</span>.`
					: 'Mall gällande avtalsområdet för <span style="font-weight:600;">Förtroendevald</span> är inte uppdaterat.';

				tempArr.push(m);
			}
		}

		setMessageAgreement(tempArr);
	}, [resultsAgreementEmployer, resultsAgreementRecipient]);

	useEffect(() => {
		const tempArr: string[] = [];
		const tempArrSalaryProtocol: string[] = [];

		if (resultsTemplateEmployer && resultsTemplateEmployer.data) {
			const data = resultsTemplateEmployer.data;
			if (data) {
				if (!data.isValid) {
					const m = data.updatedDate
						? `<span style="font-weight:600;">Mallen</span> för <span style="font-weight:600;">Arbetsgivare</span> är senast uppdaterad <span style="color:#F64E60">${data.updatedDate.slice(
								0,
								10,
						  )}</span>.`
						: '<span style="font-weight:600;">Mallen</span> för <span style="font-weight:600;">Arbetsgivare</span> är inte uppdaterad.';
					tempArr.push(m);
				}
				if (data.includeGeneratedProtocol) {
					tempArrSalaryProtocol.push("arbetsställen");
				}
			}
		}
		if (resultsTemplateRecipient && resultsTemplateRecipient.data) {
			const data = resultsTemplateRecipient.data;
			if (data) {
				if (!data.isValid) {
					const m = data.updatedDate
						? `<span style="font-weight:600;">Mallen</span> för <span style="font-weight:600;">Förtroendevald</span> är senast uppdaterad <span style="color:#F64E60">${data.updatedDate.slice(
								0,
								10,
						  )}</span>.`
						: '<span style="font-weight:600;">Mallen</span> för <span style="font-weight:600;">Förtroendevald</span> är inte uppdaterad.';
					tempArr.push(m);
				}
				if (data.includeGeneratedProtocol) {
					tempArrSalaryProtocol.push("ombud/FFV");
				}
			}
		}

		setMessageTemplate(tempArr);
		setSalaryProtocol(tempArrSalaryProtocol);
	}, [resultsTemplateRecipient, resultsTemplateEmployer]);

	// Skicka enskild salary report
	const showCheckBoxes = () => {
		const handleCheckboxChange = async (
			e: React.ChangeEvent<HTMLInputElement>,
			row: ISalaryReportRecipient,
		) => {
			const isChecked = (e.target as HTMLInputElement).checked;
			const tempList = [...selectedRecipients];

			if (isChecked) {
				tempList.push(row);
				setSelectedRecipients(tempList);
			} else {
				const idx = tempList.findIndex((r) => r.userId == row.userId);
				tempList.splice(idx, 1);
				setSelectedRecipients(tempList);
			}
			const recipientsSum = tempList.length;
			const copy = content;
			setContent({
				...copy,
				recipientSum: recipientsSum,
			});
		};

		const handleCheckboxChangeWorkplace = (e: React.ChangeEvent<HTMLInputElement>) => {
			const isChecked = (e.target as HTMLInputElement).checked;
			let tempList = [...selectedWorkplace];

			if (isChecked) {
				tempList.push(workplace[0]);
				setSelectedWorkplace(tempList);
			} else {
				tempList = [];
				setSelectedWorkplace(tempList);
			}

			const workplaceSum = tempList.length;
			const copy = content;
			setContent({
				...copy,
				workplaceSum: workplaceSum,
			});
		};
		return (
			<>
				{isLoadingData ? (
					<CircularProgress sx={{ mt: 10, ml: 5 }} />
				) : workplace && recipientsAll ? (
					<Stack flexDirection='column' p={{ xxs: "80px 20px 8px", sm: "80px 40px 10px" }}>
						<>
							<Typography variant='subtitle1' sx={{ fontWeight: "600", mb: 1 }}>
								ARBETSSTÄLLE
							</Typography>
							{workplace.length > 0 ? (
								<PaperStyled>
									<FormControlLabel
										disabled={!workplace[0].addressActivated && !workplace[0].emailActivated}
										control={
											<Checkbox
												name={workplace[0].name}
												sx={{ marginRight: 2, marginLeft: 1 }}
												onChange={(e) => handleCheckboxChangeWorkplace(e)}
												defaultChecked={
													workplace[0].addressActivated || workplace[0].emailActivated
												}
											/>
										}
										label={
											<Typography
												variant='body'
												sx={{
													fontWeight: "600",
													mx: 2,
													color:
														!workplace[0].addressActivated && !workplace[0].emailActivated
															? colors.text.disabled
															: null,
												}}
											>
												{workplace[0].name}
											</Typography>
										}
									/>
								</PaperStyled>
							) : (
								<Typography variant='body' sx={{ fontStyle: "italic", mb: 2 }}>
									Inget arbetsställe valt
								</Typography>
							)}
						</>

						<Typography variant='subtitle1' sx={{ fontWeight: "600", my: 1 }}>
							FÖRTROENDEVALD
						</Typography>
						{recipientsAll.length > 0 ? (
							recipientsAll.map((row: ISalaryReportRecipient, index) => {
								return (
									<PaperStyled key={index}>
										<FormControlLabel
											disabled={!row.addressActivated && !row.emailActivated}
											control={
												<Checkbox
													sx={{ marginRight: 2, marginLeft: 1 }}
													onChange={(e) => handleCheckboxChange(e, row)}
													defaultChecked={row.addressActivated || row.emailActivated}
												/>
											}
											label={
												<Typography
													variant='body'
													sx={{
														fontWeight: "600",
														mx: 2,
														color:
															!row.addressActivated && !row.emailActivated
																? colors.text.disabled
																: null,
													}}
												>
													{row.name}
												</Typography>
											}
										/>
									</PaperStyled>
								);
							})
						) : (
							<Typography variant='body' sx={{ fontStyle: "italic" }}>
								Ingen förtroendevald vald
							</Typography>
						)}
					</Stack>
				) : (
					<Stack flexDirection='column' p={{ xxs: "80px 20px 8px", sm: "80px 40px 10px" }}>
						<Typography variant='body' sx={{ fontStyle: "italic" }}>
							Kunde inte hämta arbetsställe eller förtroendevalda
						</Typography>
					</Stack>
				)}
			</>
		);
	};

	const sendDispatch = async () => {
		const form: any = [];
		if (salaryReportId) {
			const recipientIds: string[] = [];
			selectedWorkplace.length > 0 && recipientIds.push(selectedWorkplace[0].recipientId);
			selectedRecipients.map((r) => r.recipientId && recipientIds.push(r.recipientId));
			form.push({
				dispatchListId: dispatchListId,
				salaryReportId: salaryReportId,
				recipientIds: recipientIds,
				dispatchType: dispatchType,
			});
		} else {
			form.push({
				dispatchListId: dispatchListId,
				dispatchType: dispatchType,
			});
		}
		salaryReportId
			? await getSendSalaryReportDispatches(form[0])
					.unwrap()
					.then((response) => {
						enqueueSnackbar(dispatchTypeName + " för " + workplace[0].name + " är skickad", {
							variant: "success",
						});
						refreshDataById();
					})
					.catch((error) => {
						enqueueSnackbar(error.data.detail, { variant: "error", persist: true });
					})
			: await getSendDispatches(form[0])
					.unwrap()
					.then((response) => {
						enqueueSnackbar(dispatchTypeName + " för " + content.name + " är skickad", {
							variant: "success",
						});
						refreshDataById();
						showModal(false);
					})
					.catch((error) => {
						enqueueSnackbar(error.data.detail, {
							variant: "error",
							persist: true,
						});
					});
	};

	const fullScreen = useMediaQuery(theme.breakpoints.up("md"));

	return (
		<>
			<DialogContent dividers sx={{ padding: "0 !important" }}>
				<Stack
					flexDirection='column'
					justifyContent='space-between'
					display='flex'
					height={"100%"}
					width={fullScreen ? "697px" : "100%"}
					mb={2}
				>
					<Box
						sx={{
							typography: "body1",
							position: "fixed",
							backgroundColor: "white !important",
							zIndex: 10,
							padding: { xxs: "10px 8px", sm: "10px 40px" },
						}}
						width={fullScreen ? "697px" : "100%"}
					>
						<Tabs
							value={tabValue}
							onChange={handleChangeTab}
							TabIndicatorProps={{
								style: {
									backgroundColor: "#C30E15",
									bottom: 0,
								},
							}}
							aria-label='meny'
						>
							<Tab
								disabled={isLoadingMessage}
								label='FÖRSTA UTSKICK'
								{...a11yProps(1)}
								icon={<CheckCircleIcon color='primary' fontSize='small' sx={{ ml: 0.75 }} />}
							/>
							<Tab
								disabled={isLoadingMessage}
								label='PÅMINNELSE 1'
								{...a11yProps(2)}
								icon={<CheckCircleIcon color='warning' fontSize='small' sx={{ ml: 0.75 }} />}
							/>
							<Tab
								disabled={isLoadingMessage}
								label='PÅMINNELSE 2'
								{...a11yProps(3)}
								icon={
									<CheckCircleIcon sx={{ ml: 0.75, color: colors.purple.main }} fontSize='small' />
								}
							/>
						</Tabs>
					</Box>
					{salaryReportId && showCheckBoxes()}

					<Box sx={{ padding: { xxs: "10px 20px", sm: "10px 40px" } }} mt={salaryReportId ? 1 : 10}>
						<TabPanel value={tabValue} index={0}>
							<DispatchListsContent
								dispatchTypeText={"Första utskick"}
								content={content}
								salaryProtocol={salaryProtocol}
								messageTemplate={messageTemplate}
								messageAgreement={messageAgreement}
								isLoadingData={isLoadingData}
								isLoadingMessage={isLoadingMessage}
								recipients={recipients}
								recipientsNoAddress={recipientsNoAddress}
							/>
						</TabPanel>
						<TabPanel value={tabValue} index={1}>
							<DispatchListsContent
								dispatchTypeText={"Påminnelse 1"}
								content={content}
								salaryProtocol={salaryProtocol}
								messageTemplate={messageTemplate}
								messageAgreement={messageAgreement}
								isLoadingData={isLoadingData}
								isLoadingMessage={isLoadingMessage}
								recipients={recipients}
								recipientsNoAddress={recipientsNoAddress}
							/>
						</TabPanel>
						<TabPanel value={tabValue} index={2}>
							<DispatchListsContent
								dispatchTypeText={"Påminnelse 2"}
								content={content}
								salaryProtocol={salaryProtocol}
								messageTemplate={messageTemplate}
								messageAgreement={messageAgreement}
								isLoadingData={isLoadingData}
								isLoadingMessage={isLoadingMessage}
								recipients={recipients}
								recipientsNoAddress={recipientsNoAddress}
							/>
						</TabPanel>
					</Box>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button aria-label='Avbryt' variant='grey' onClick={() => showModal(false)}>
					Avbryt
				</Button>
				{loadingSendDispatches || loadingSendSalaryReportDispatches ? (
					<LoadingButton label={"Skickar"} />
				) : (
					<Button
						aria-label='Skapa'
						variant='containedPrimary'
						onClick={sendDispatch}
						startIcon={<MailOutlineIcon />}
						disabled={
							salaryReportId
								? isLoadingData ||
								  isLoadingMessage ||
								  messageAgreement.length > 0 ||
								  messageTemplate.length > 0 ||
								  (selectedRecipients.length == 0 && selectedWorkplace.length == 0)
								: isLoadingData ||
								  isLoadingMessage ||
								  messageTemplate.length > 0 ||
								  messageAgreement.length > 0 ||
								  (content.recipientSum == 0 && content.workplaceSum == 0)
						}
					>
						Skicka
					</Button>
				)}
			</DialogActions>
		</>
	);
};

export default SendDispatchListsModalContent;
