import { Chip } from "@mui/material";

interface IStatusChip {
	statusMessage: string;
	status: number;
	margin?: string;
}

const StatusChip = ({ statusMessage, status, margin }: IStatusChip) => {
	function renderSwitch() {
		switch (status) {
			case 100:
				return "infoGrey";
			case 200:
				return "purple";
			case 250:
				return "yellow";
			case 300:
				return "green";
			default:
				return "infoGrey";
		}
	}
	return (
		<Chip
			label={statusMessage || ""}
			color={renderSwitch()}
			size='small'
			sx={{ margin: margin ? margin : "0" }}
		/>
	);
};

export default StatusChip;
