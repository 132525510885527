import { configureStore, getDefaultMiddleware, combineReducers } from "@reduxjs/toolkit";
import confirmationReducer from "components/common/confirmDialog/ConfirmationSlice";
import { api } from "api/Api";

export default configureStore({
	reducer: {
		api: api.reducer,
		confirmation: confirmationReducer,
	},

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
});
