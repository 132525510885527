import theme from "styles/theme";
import { IProtocolStatus } from "../tableCard/ProtocolModalContent";
import CloseIcon from "@mui/icons-material/Close";
import { Box, List, ListItem, Tooltip, Typography, styled } from "@mui/material";

const BoxStyled = styled(Box)(() => ({
	display: "flex",
	maxWidth: 373,
	fontSize: 13,
	background: theme.palette.background.default,
	borderColor: theme.palette.border.main,
	borderStyle: "solid",
	borderWidth: 1,
	borderRadius: 11,
	wordBreak: "keep-all",
	marginBottom: 20,
	flexDirection: "column",

	[theme.breakpoints.down("md")]: {
		minWidth: "100%",
		justifyContent: "space-between",
	},
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column",
	},
}));
const CloseIconBoxStyled = styled(Box)(() => ({
	display: "flex",
	maxWidth: 373,
	justifyContent: "flex-end",
	width: "15px",
	height: "15px",
	marginLeft: "auto",
	padding: "4px 4px 0px",
	color: theme.palette.grey[500],
	cursor: "pointer",
}));
const ListStyled = styled(List)(() => ({
	listStyleType: "none",
	paddingTop: 0,
}));

interface IProtocolInfoprops {
	salaryProtocolResponse: IProtocolStatus;
	setShowProtocolInfo: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ImportProtocolInfo = ({
	salaryProtocolResponse,
	setShowProtocolInfo,
}: IProtocolInfoprops) => {
	return (
		<BoxStyled>
			<CloseIconBoxStyled onClick={() => setShowProtocolInfo(false)}>
				<CloseIcon
					style={{
						width: "15px",
						height: "15px",
						color: theme.palette.grey[500],
					}}
				/>
			</CloseIconBoxStyled>

			<ListStyled dense={true}>
				{salaryProtocolResponse.newRows > 0 && (
					<ListItem>
						<Typography variant='body'>
							{salaryProtocolResponse.newRows} rader lades till
						</Typography>
					</ListItem>
				)}

				{salaryProtocolResponse.updatedRows > 0 && (
					<ListItem>
						<Typography variant='body'>
							{salaryProtocolResponse.updatedRows} rader uppdaterades
						</Typography>
					</ListItem>
				)}

				{salaryProtocolResponse.errorRows > 0 && (
					<ListItem>
						<Typography variant='body'>{salaryProtocolResponse.errorRows} rader med fel</Typography>
						<Tooltip
							title={salaryProtocolResponse.errorRowsReason.map((reason: string, index: number) => {
								return (
									<p style={{ margin: 0 }} key={index}>
										{reason}
									</p>
								);
							})}
							placement='bottom-start'
							arrow
						>
							<Typography
								variant='body'
								color={theme.palette.primary.main}
								pl={1}
								sx={{ cursor: "pointer" }}
							>
								(mer info)
							</Typography>
						</Tooltip>
					</ListItem>
				)}
				{salaryProtocolResponse.failedRows > 0 && (
					<ListItem>
						<Typography variant='body'>
							{salaryProtocolResponse.failedRows} rader kunde inte importeras
						</Typography>
						<Tooltip
							title={salaryProtocolResponse.failedRowsReason.map(
								(reason: string, index: number) => {
									return (
										<p style={{ margin: 0 }} key={index}>
											{reason}
										</p>
									);
								},
							)}
							placement='bottom-start'
							arrow
						>
							<Typography
								variant='body'
								color={theme.palette.primary.main}
								pl={1}
								sx={{ cursor: "pointer" }}
							>
								(mer info)
							</Typography>
						</Tooltip>
					</ListItem>
				)}
			</ListStyled>
		</BoxStyled>
	);
};
