import React, { useEffect, useState } from "react";
import { useGetSalaryReportCommentsQuery } from "api/Api";
import theme from "styles/theme";

import {
	Typography,
	Stack,
	DialogActions,
	Button,
	DialogContent,
	Box,
	Divider,
	CircularProgress,
	styled,
} from "@mui/material";

import { CardInfoBoxStyled, TypographyInfoBoxStyled } from "components/common/infoBox/InfoBox";
import { ISalaryReportRecipient } from "types/dispatch/dispatchListTable/DispatchListTableTypes";

interface SalaryReportCommentsModalContentProps {
	showModal: React.Dispatch<React.SetStateAction<boolean>>;
	salaryReport: any;
}

const BoxStyled = styled(Box)(({ theme }) => ({
	borderRadius: 6,
	marginTop: 8,
	border: `1px solid ${theme.palette.border.light}`,
	padding: "2px 6px 4px",
	minHeight: 100,
}));

const SalaryReportCommentsModalContent: React.FC<SalaryReportCommentsModalContentProps> = ({
	showModal,
	salaryReport,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [workplace, setWorkplace] = useState<string>("");
	const [recipients, setRecipients] = useState<string[]>([""]);

	const { data: salaryReportCommentsData, isLoading: isLoadingComments } =
		useGetSalaryReportCommentsQuery(salaryReport.id ?? -1);

	useEffect(() => {
		if (salaryReport) {
			const allRecipients: string[] = [];
			salaryReport.representativeRecipients.map((r: ISalaryReportRecipient) =>
				allRecipients.push(r.name),
			);

			const capitalizeWorkplace = salaryReport.workplace.name.toLowerCase().split(" ");
			for (let i = 0; i < capitalizeWorkplace.length; i++) {
				capitalizeWorkplace[i] =
					capitalizeWorkplace[i].charAt(0).toUpperCase() + capitalizeWorkplace[i].slice(1);
			}
			const workplaceCapitalized = capitalizeWorkplace.join(" ");

			setRecipients(allRecipients);
			setWorkplace(workplaceCapitalized);
			setIsLoading(false);
		}
	}, [salaryReport]);

	return (
		<>
			<DialogContent dividers sx={{ width: { md: 700 } }}>
				{isLoading || isLoadingComments ? (
					<Box display={"flex"} justifyContent={"center"}>
						<CircularProgress />
					</Box>
				) : (
					<>
						{salaryReport ? (
							<Box display={"flex"} flexDirection={{ xxs: "column", sm: "row" }}>
								<CardInfoBoxStyled variant='outlined'>
									<TypographyInfoBoxStyled sx={{ fontWeight: "500" }}>
										Arbetsställe
									</TypographyInfoBoxStyled>
									{workplace || ""}
								</CardInfoBoxStyled>
								<CardInfoBoxStyled variant='outlined'>
									<TypographyInfoBoxStyled sx={{ fontWeight: "500" }}>
										Förtroendevald
									</TypographyInfoBoxStyled>
									<>
										{recipients?.length > 0 ? (
											recipients.map((name: string, index: number) => {
												return (
													<TypographyInfoBoxStyled key={index}>
														{name || ""}
													</TypographyInfoBoxStyled>
												);
											})
										) : (
											<TypographyInfoBoxStyled>-</TypographyInfoBoxStyled>
										)}
									</>
								</CardInfoBoxStyled>
							</Box>
						) : (
							<Typography fontStyle={"italic"}>
								Kunde inte hämta arbetställe och förtroendevalda
							</Typography>
						)}

						{salaryReportCommentsData ? (
							<Stack spacing={2} divider={<Divider flexItem />} m={2}>
								{salaryReportCommentsData.generalComment &&
									salaryReportCommentsData.generalComment.length > 0 && (
										<Stack>
											<Typography variant='bodyMedium'>Generell kommentar:</Typography>
											<BoxStyled mt={1}>
												<Typography variant='body'>
													{salaryReportCommentsData.generalComment || ""}
												</Typography>
											</BoxStyled>
										</Stack>
									)}

								{salaryReportCommentsData.salaryRowComments &&
									salaryReportCommentsData.salaryRowComments.length > 0 &&
									salaryReportCommentsData.salaryRowComments.map((comment: any, index: any) => {
										if (comment.comment.length > 0)
											return (
												<Stack key={index}>
													<Box display={"flex"} mt={1}>
														<Typography variant='bodyMedium'>{comment.employeeName} </Typography>
														<Typography
															variant='bodyMedium'
															sx={{
																fontSize: "12px",
																lineHeight: "20px",
																fontWeight: 500,
																color: theme.palette.text.grey,
																ml: 1,
															}}
														>
															{comment.employeeIdNumber}
														</Typography>
													</Box>
													<BoxStyled>
														<Typography variant='body'>{comment.comment}</Typography>
													</BoxStyled>
												</Stack>
											);
									})}
							</Stack>
						) : (
							<Typography sx={{ mt: 16 }} fontStyle={"italic"}>
								Kunde inte hämta kommentarer
							</Typography>
						)}
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button aria-label='Avbryt' variant='grey' onClick={() => showModal(false)}>
					Avbryt
				</Button>
			</DialogActions>
		</>
	);
};
export default SalaryReportCommentsModalContent;
