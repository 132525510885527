import React, { useState, useEffect } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";

import { ReactComponent as UsersIcon } from "assets/icons/user-group-regular.svg";
import { ReactComponent as EmployerIcon } from "assets/icons/employer.svg";

import AgreementListTable from "components/templates/components/AgreementListTable";
import GeneralTemplates from "./components/GeneralTemplates";
import {
	LayoutBoxStyled,
	LayoutTabStyled,
	LayoutTabsStyled,
	LayoutCardStyled,
} from "components/common/styledComponents/Layout";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 2 }}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export default function TemplatesOverview() {
	const [value, setValue] = useState(0);

	// same as in url
	const pageTabNames = ["ombud-ffv", "arbetsgivare"];

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
		window.history.pushState({}, "", "/mallar/" + pageTabNames[newValue]);
	};

	useEffect(() => {
		const path =
			location.pathname
				.split("/")
				.filter((x: string) => x)
				.pop() ?? "ombud/FFV";
		path == "ombud-ffv" ? "ombud/FFV" : path;
		setValue(pageTabNames.indexOf(path));
	}, []);

	return (
		<LayoutBoxStyled>
			<LayoutCardStyled>
				<Box
					sx={{
						display: "inline-flex",
						background: "#F3F6F9",
						width: "100%",
						alignItems: "center",
					}}
				>
					<LayoutTabsStyled value={value} onChange={handleChange} aria-label='basic tabs example'>
						<LayoutTabStyled icon={<UsersIcon />} label='Förtroendevald' {...a11yProps(0)} />
						<LayoutTabStyled icon={<EmployerIcon />} label='Arbetsgivare' {...a11yProps(1)} />
					</LayoutTabsStyled>
				</Box>

				<TabPanel value={value} index={0}>
					<Grid container padding={{ md: "12px" }} spacing={4} justifyContent={"center"}>
						<Grid item xxs={12} lg={6}>
							<Typography variant='h1'>Generella mallar</Typography>
							<Stack mt={7}>
								<GeneralTemplates role={"ombud/FFV"} />
							</Stack>
						</Grid>
						<Grid item xxs={12} lg={6}>
							<Typography variant='h1'>Avtalsområde</Typography>
							<AgreementListTable role={"ombud-ffv"} contractArea={""} />
						</Grid>
					</Grid>
				</TabPanel>

				<TabPanel value={value} index={1}>
					<Grid container padding={"12px"} spacing={4} justifyContent={"center"}>
						<Grid item xxs={12} lg={6}>
							<Typography variant='h1'>Generella mallar</Typography>
							<Stack mt={7}>
								<GeneralTemplates role={"arbetsgivare"} />
							</Stack>
						</Grid>
						<Grid item xxs={12} lg={6}>
							<Typography variant='h1'>Avtalsområde</Typography>
							<AgreementListTable role={"arbetsgivare"} contractArea={""} />
						</Grid>
					</Grid>
				</TabPanel>
			</LayoutCardStyled>
		</LayoutBoxStyled>
	);
}
