import styled from "@emotion/styled";
import { IconButtonProps, IconButton } from "@mui/material";
import theme from "styles/theme";

interface ExpandButtonProps extends IconButtonProps {
	expand: boolean;
}

const ExpandButton = styled((props: ExpandButtonProps) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ expand }) => ({
	marginLeft: "auto",

	"& svg": {
		transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
		transition: theme.transitions.create("transform", {
			duration: 350,
		}),
	},
}));
export default ExpandButton;
