import React from "react";
import { styled } from "@mui/system";

import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent, { CardContentProps as MuiCardContentProps } from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material";

interface CardContentProps extends MuiCardContentProps {
	padding?: number;
}

interface ListItemCardProps {
	icon?: React.ReactNode;
	header: React.ReactNode;
	headerAction?: React.ReactNode;
	content: React.ReactNode;
	footer?: React.ReactNode;
	padding?: number;
	hoverHeader?: boolean;
	handleClick?: (params: any) => void;
	sx?: SxProps;
}

const CardStyled = styled(Card)(({ theme }) => ({
	borderRadius: 7,
	boxShadow: `0px 0px 16px ${theme.palette.boxShadow.main}`,
	textDecoration: "none",
})) as typeof Card;

const IconStyled = styled(Avatar)(({ theme }) => ({
	backgroundColor: "white",
	height: 24,
	width: 24,
})) as typeof Avatar;

const CardContentStyled = styled(CardContent, {
	shouldForwardProp: (prop) => prop !== "padding",
})<CardContentProps>(({ theme, padding }) => ({
	color: theme.palette.ackordswebben,
	padding: padding || padding === 0 ? `${padding} !important` : 28,
	[theme.breakpoints.down("sm")]: {
		padding: 16,
		paddingBottom: 10,
	},
	[theme.breakpoints.down("md")]: {
		padding: 16,
		// no extra padding on footer
		":last-child": {
			paddingBottom: padding || padding === 0 ? padding : 16,
		},
	},
}));

export const ListItemCard: React.FC<ListItemCardProps> = ({
	icon,
	header,
	headerAction,
	content,
	footer,
	padding,
	hoverHeader,
	handleClick,
	sx,
}) => {
	return (
		<>
			<CardStyled elevation={2}>
				<CardHeader
					onClick={handleClick ? handleClick : null}
					sx={{
						minHeight: "80px",
						alignContent: "center",
						display: "flex",
						"& .MuiCardHeader-action": { margin: 0, width: "fit-content" },
						"& :hover": { cursor: `${hoverHeader && "pointer"}` },
						...sx,
					}}
					title={<Typography variant='h1'>{header}</Typography>}
					// avatar is a part of the mui cardheader, here used as a icon
					avatar={icon && <IconStyled>{icon}</IconStyled>}
					action={headerAction}
				/>
				<Divider />
				<CardContentStyled padding={padding}>{content || ""}</CardContentStyled>
				{footer && (
					<>
						<Divider />
						<CardActions>{footer}</CardActions>
					</>
				)}
			</CardStyled>
		</>
	);
};
