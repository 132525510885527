import React, { useState } from "react";
import {
	useGetAllSalaryReportActiveQuery,
	useGetPatchSalaryReportCompletedMutation,
	useGetSalaryReportQuery,
	useGetUserFromDirectoryQuery,
} from "api/Api";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";

import { Box, Button } from "@mui/material";
import ModalDialog from "components/common/modal/ModalDialog";
import SendSalaryProtocolModalContent from "./SendSalaryProtocolModalContent";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { ReactComponent as SendListIcon } from "assets/icons/send-group-35.svg";
import { IResponsible } from "types/dispatch/dispatchListTable/SelectAgentModal";
import { Check } from "@mui/icons-material";
import { openConfirmation } from "components/common/confirmDialog/ConfirmationSlice";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import LoadingButton from "components/common/loading/LoadingButton";

interface ISalaryProtocolListTableHeaderProps {
	setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SalaryProtocolListTableHeader({
	setShowErrors,
}: ISalaryProtocolListTableHeaderProps) {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const dispatch = useDispatch();

	const salaryReportId = LastSegmentOfUrl(location.href);
	const { data, refetch: refetchData } = useGetSalaryReportQuery(salaryReportId);
	const { refetch: refetchOverviewList } = useGetAllSalaryReportActiveQuery(null);
	const { data: userData } = useGetUserFromDirectoryQuery(null);
	const [setCompleted] = useGetPatchSalaryReportCompletedMutation();
	const isOmbud = data.responsibles.findIndex((r: IResponsible) => r.userId == userData.id) >= 0;
	const ombudCanEdit = isOmbud && data.status == 250;
	const disableNonAdmin = userData.activeRole == "non-admin" && data.status >= 250;
	const isArchived = data.dispatchListArchivedDate != null ?? false;

	return (
		<Box sx={{ minWidth: "100%", display: "flex" }}>
			{showModal && (
				<ModalDialog
					isVisible={showModal}
					showModal={setShowModal}
					label={"Skicka löneprotokoll"}
					icon={<SendListIcon />}
					content={
						<SendSalaryProtocolModalContent
							showModal={setShowModal}
							setShowErrors={setShowErrors}
						/>
					}
					minHeight='auto'
				/>
			)}
			{data.status == 250 && (!disableNonAdmin || ombudCanEdit) && loading && (
				<LoadingButton label='Klarmarkerar löneprotokoll...' />
			)}
			{data.status == 250 && (!disableNonAdmin || ombudCanEdit) && !loading && (
				<Button
					sx={{ width: "auto" }}
					aria-label='Utredning klar'
					variant='containedPrimary'
					startIcon={<Check />}
					onClick={() => {
						dispatch(
							openConfirmation({
								message: [
									"Är du säker på att du vill klarmarkera löneprotokollet?",
									"Obs! Detta går inte att ångra.",
								],
								callback: (confirmed: boolean) => {
									if (confirmed) {
										if (setLoading) setLoading(true);
										setCompleted(salaryReportId)
											.unwrap()
											.then(() => {
												enqueueSnackbar("Utredning klar", {
													variant: "success",
												});
												refetchData();
												refetchOverviewList();
											})
											.catch((error) => {
												enqueueSnackbar(error.data.detail || "Utredningstatus kan inte ändras", {
													variant: "error",
													persist: true,
												});
											})
											.finally(() => {
												if (setLoading) setLoading(false);
											});
									}
								},
							}),
						);
					}}
				>
					Utredning klar
				</Button>
			)}

			<Button
				sx={{ width: "auto", marginLeft: "8px" }}
				aria-label='Skicka protokoll'
				variant='containedPrimary'
				startIcon={<MailOutlineIcon />}
				onClick={() => setShowModal(true)}
				disabled={(data?.status && data.status !== 200) || disableNonAdmin || isArchived}
			>
				Skicka protokoll
			</Button>
		</Box>
	);
}
