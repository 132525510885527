import { Dialog, styled } from "@mui/material";

export const DialogStyled = styled(Dialog, {
	shouldForwardProp: (prop) => prop !== "height",
})<{ height?: string }>(({ theme, height }) => ({
	minHeight: height,

	"& .MuiPaper-root": {
		borderRadius: "16px",
		maxWidth: "none",
	},
	"& .MuiDialogTitle-root": {
		padding: "20px 20px 10px",
		[theme.breakpoints.down("xs")]: {
			padding: 10,
		},

		"& p > svg": {
			marginRight: "8px",
		},
	},

	"& .MuiDialogContent-root": {
		minHeight: "100%",
		padding: "20px 40px",

		[theme.breakpoints.down("xs")]: {
			padding: 10,
		},
	},
	"& .MuiDialogActions-root": {
		padding: "20px",
	},
})) as typeof Dialog;
