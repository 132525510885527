import React from "react";
import { getUrl } from "lib/helpers/ApiHelper";
import Config from "Config";
import { Box } from "@mui/material";
import theme from "styles/theme";

const VersionBanner = () => {
	const hostName = getUrl();

	const showBanner: boolean =
		hostName === "https://sef-salaryweb-dev.azurewebsites.net" ||
		hostName === "https://sef-salaryweb-systest.azurewebsites.net" ||
		hostName === "https://acctest.lonewebben.se/api";

	function switchColor() {
		switch (hostName) {
			case "https://sef-salaryweb-systest.azurewebsites.net":
				return theme.palette.yellow.contrastText;

			case "https://acctest.lonewebben.se/api":
				return theme.palette.purple.contrastText;

			case "https://dev.lonewebben.se/api":
				return theme.palette.green.contrastText;
			default:
				break;
		}
	}

	return (
		<>
			{showBanner && (
				<Box
					sx={{
						background: switchColor(),
						fontSize: 12,
						color: "#fff",
						fontWeight: 600,
						textAlign: "center",
					}}
				>
					OBSERVERA! TESTMILJÖ LÖNEWEBBEN [{Config.version}]
				</Box>
			)}
		</>
	);
};

export default VersionBanner;
