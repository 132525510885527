import React, { useEffect, useState } from "react";
import theme from "styles/theme";
import { useNavigate } from "react-router-dom";
import { useGetAllSalaryReportActiveQuery, useGetCreateSalaryReportRowsMutation } from "api/Api";
import { enqueueSnackbar } from "notistack";
import colors from "styles/colors-theme";
import StatusChip from "components/common/chip/StatusChip";

import { ISalaryProtocolOverViewListContent } from "types/representative/commonSalaryReportTypes";

import {
	Box,
	Button,
	Card,
	CircularProgress,
	Link,
	Stack,
	styled,
	Typography,
	useMediaQuery,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as AgreementIcon } from "assets/icons/agreement.svg";

import ModalDialog from "components/common/modal/ModalDialog";
import LoadingButton from "components/common/loading/LoadingButton";
import AgreementModalContent from "components/representative/salaryProtocol/components/AgreementModalContent";

export const CardMobileViewStyled = styled(Card)(() => ({
	display: "flex",
	flexDirection: "column",
	padding: "20px 21px 27px",
	border: `2px solid ${colors.border.blue}`,
	borderRadius: "11px !important",
	width: "100%",
	maxWidth: 300,
	margin: "0 10px 10px 10px",
	justifyContent: "space-between",
	minHeight: 250,
	[theme.breakpoints.down("xs")]: {
		maxWidth: "100%",
		marginBottom: 16,
	},
}));

export const TypographyInfoBoxStyled = styled(Typography)(() => ({
	fontSize: "14px",
}));

export interface ISalaryProtocolContentProps {
	salaryProtocolContent: ISalaryProtocolOverViewListContent;
	isActiveSalaryProtocol: boolean;
}

export default function CardMobileView({
	salaryProtocolContent,
	isActiveSalaryProtocol,
}: ISalaryProtocolContentProps) {
	const {
		id,
		departmentNumber,
		workplaceMunicipality,
		statusMessage,
		status,
		agreementTypeName,
		workplaceName,
		year,
	} = salaryProtocolContent;

	const [isLoading, setIsLoading] = useState(false);
	const [salaryReportId, setSalaryReportId] = useState("");
	const [modalAgreement, setModalAgreement] = useState<boolean>(false);

	const navigate = useNavigate();

	const [getCreateSalaryReportRowsMutation] = useGetCreateSalaryReportRowsMutation();
	const { refetch } = useGetAllSalaryReportActiveQuery(null);

	const createSalaryProtocol = () => {
		if (id) {
			setIsLoading(true);
			setSalaryReportId(id);
		}
	};

	useEffect(() => {
		if (salaryReportId && salaryReportId.length > 0) {
			const form = {
				salaryReportId: salaryReportId,
			};
			try {
				getCreateSalaryReportRowsMutation(form)
					.unwrap()
					.then(() => {
						refetch();
						navigate(`/aktuella/${year}/${salaryReportId}`);
					})
					.catch((error) => {
						setIsLoading(false);
						enqueueSnackbar(error.data.detail, { variant: "error", persist: true });
					});
			} catch (error) {
				setIsLoading(false);
				enqueueSnackbar("Löneprotokoll kan inte laddas", {
					variant: "error",
					persist: true,
				});
			}
		}
	}, [salaryReportId]);

	const fullScreen = useMediaQuery(theme.breakpoints.up("lg"));

	return salaryProtocolContent ? (
		<>
			{modalAgreement && (
				<ModalDialog
					isVisible={modalAgreement}
					showModal={setModalAgreement}
					label={agreementTypeName || ""}
					icon={<AgreementIcon />}
					content={<AgreementModalContent salaryReportId={id} showModal={setModalAgreement} />}
				/>
			)}
			<CardMobileViewStyled
				variant='outlined'
				onClick={() => {
					if (!isActiveSalaryProtocol) navigate(`/historik/${year}/${id}`);
				}}
				sx={{
					"&:hover": {
						backgroundColor: !isActiveSalaryProtocol ? theme.palette.action.hover : null,
						cursor: isActiveSalaryProtocol ? "default" : "pointer",
					},
				}}
			>
				<Stack spacing={2}>
					<Typography variant='h1'>{workplaceName || ""}</Typography>
					<Typography variant='body'>
						<Link
							href='#'
							underline='none'
							onClick={() => setModalAgreement(true)}
							color={"primary"}
						>
							{agreementTypeName || ""}
						</Link>
					</Typography>
				</Stack>

				<Stack spacing={2} mt={1}>
					<Box>
						<Typography variant='body'>{departmentNumber || ""}</Typography>
						{"  "}
						<Typography variant='body'>{workplaceMunicipality || ""}</Typography>
					</Box>
					<Box>
						<StatusChip
							statusMessage={statusMessage && statusMessage.length > 0 ? statusMessage : ""}
							status={status ? status : 0}
						/>
					</Box>
					<Box mr={3}>
						{status !== 100 ? (
							<Button
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									navigate(`/${isActiveSalaryProtocol ? "aktuella" : "historik"}/${year}/${id}`);
								}}
								aria-label='öppna'
								variant='containedSecondary'
								fullWidth
							>
								Öppna
							</Button>
						) : isLoading ? (
							<LoadingButton label={"Skapar protokoll"} fullWidth={fullScreen ? false : true} />
						) : // Button should only be shown in "Aktuella"
						isActiveSalaryProtocol ? (
							<Button
								aria-label='Ny Spara protokoll'
								variant='containedPrimary'
								startIcon={<AddIcon />}
								onClick={() => createSalaryProtocol()}
								fullWidth
							>
								Skapa protokoll
							</Button>
						) : null}
					</Box>
				</Stack>
			</CardMobileViewStyled>
		</>
	) : (
		<CircularProgress />
	);
}
