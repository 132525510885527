import { useEffect, useState } from "react";
import { theme } from "styles/theme";
import { useGetAllSalaryReportActiveQuery } from "api/Api";
import { useNavigate } from "react-router-dom";

import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";
import {
	ISalaryReportOverviewList,
	ISalaryProtocolOverViewRow,
} from "types/representative/commonSalaryReportTypes";

import {
	IconButton,
	useMediaQuery,
	Collapse,
	IconButtonProps,
	styled,
	Stack,
	CircularProgress,
	Typography,
} from "@mui/material";

import { LayoutBoxStyled, LayoutCardStyled } from "components/common/styledComponents/Layout";
import { ListItemCard } from "components/common/list/ListItemCard";
import SalaryProtocolOverviewListCard from "./components/SalaryProtocolOverviewListCard";
import SalaryProtocolOverviewListTable from "./components/SalaryProtocolOverviewListTable";

import { ReactComponent as FormatListBulletedIcon } from "assets/icons/format-list-bulleted.svg";
import { ReactComponent as ShowMoreIcon } from "assets/icons/show-more.svg";

interface ExpandMoreProps extends IconButtonProps {
	expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	marginLeft: "auto",

	transition: theme.transitions.create("transform", {
		duration: 350,
		// not working with table
	}),
}));

const CollapsetStyled = styled(Collapse)(({ theme }) => ({
	padding: 28,
	[theme.breakpoints.down("lg")]: {
		padding: 16,
		paddingBottom: 10,
	},
}));

export default function SalaryProtocolOverview() {
	const yearFromUrl = LastSegmentOfUrl(location.href);
	const [selectedProtocol, setSelectedProtocol] = useState<string[]>([""]);
	const [rows, setRows] = useState<ISalaryProtocolOverViewRow[]>([]);

	const { data: rowsData, isLoading } = useGetAllSalaryReportActiveQuery(null);

	const fullScreen = useMediaQuery(theme.breakpoints.up("lg"));

	const handleExpandClick = (date: string) => {
		if (selectedProtocol.includes(date)) {
			if (selectedProtocol) {
				const newArr = selectedProtocol.filter((i: string) => i != date);
				setSelectedProtocol(newArr);
			}
		} else {
			setSelectedProtocol([...selectedProtocol, date]);
		}
	};

	useEffect(() => {
		if (rowsData && rowsData.length > 0) {
			const rowsDividedOnDate: ISalaryProtocolOverViewRow[] = [];

			const allDates = rowsData.map(function (row: ISalaryReportOverviewList) {
				// sorting date on year
				return row.salaryRevisionDate.substring(0, 4);
			});

			const individualDates = allDates.sort().filter((d: string, index: number) => {
				return allDates.indexOf(d) == index;
			});

			individualDates.forEach((date: string) => {
				const rowArray = rowsData.filter(
					(row: ISalaryReportOverviewList) => row.salaryRevisionDate.substring(0, 4) == date,
				);

				const newRowObject = {
					date: date,
					rows: rowArray,
				};

				rowsDividedOnDate.push(newRowObject);

				date == yearFromUrl && setSelectedProtocol([newRowObject.date]);
			});
			setRows(rowsDividedOnDate);

			yearFromUrl === "aktuella" && setSelectedProtocol([rowsDividedOnDate[0].date]);
		}
	}, [rowsData]);

	const navigate = useNavigate();
	useEffect(() => {
		const redirectUri = localStorage.getItem("redirectUri");
		// going to url from new tab
		if (redirectUri) {
			localStorage.removeItem("redirectUri");
			navigate(redirectUri);
		}
	}, [location]);

	return (
		<LayoutBoxStyled>
			{isLoading ? (
				<CircularProgress />
			) : rows && rows.length > 0 ? (
				<Stack spacing={2}>
					{rows.map((row: ISalaryProtocolOverViewRow) => (
						<LayoutCardStyled key={row.date}>
							<ListItemCard
								handleClick={() => handleExpandClick(row.date)}
								icon={<FormatListBulletedIcon color='primary' />}
								header={`Löneprotokoll ${row.date}`}
								headerAction={
									<ExpandMore
										expand={selectedProtocol.includes(row.date)}
										onClick={() => handleExpandClick(row.date)}
										aria-label='show more'
										sx={{ margin: 0, width: "fit-content" }}
									>
										<ShowMoreIcon />
									</ExpandMore>
								}
								content={
									<CollapsetStyled
										in={selectedProtocol.includes(row.date)}
										timeout='auto'
										unmountOnExit
									>
										{fullScreen ? (
											<SalaryProtocolOverviewListTable
												rowProps={row.rows}
												isActiveSalaryProtocol={true}
											/>
										) : (
											<SalaryProtocolOverviewListCard
												rowProps={row.rows}
												isActiveSalaryProtocol={true}
											/>
										)}
									</CollapsetStyled>
								}
								padding={0}
								hoverHeader
							/>
						</LayoutCardStyled>
					))}
				</Stack>
			) : rows && rows.length == 0 ? (
				<Typography variant='body1'>Du har inga tilldelade löneprotokoll</Typography>
			) : (
				<Typography variant='body1' sx={{ fontStyle: "italic" }}>
					Kunde inte hämta löneprotokoll
				</Typography>
			)}
		</LayoutBoxStyled>
	);
}
