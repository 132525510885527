import React, { useContext, useCallback, useState, MouseEvent, useEffect } from "react";
import { AuthContext } from "components/idConnect/AuthContext";
import { enqueueSnackbar } from "notistack";

import {
	useGetChangeRoleFromDirectoryMutation,
	useGetRolesFromDirectoryQuery,
	useGetUserFromDirectoryQuery,
} from "api/Api";

import theme from "styles/theme";
import { styled } from "@mui/material/styles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import { ReactComponent as UserIcon } from "assets/icons/Group 45.svg";
import sefLogo from "assets/images/sef_logo.png";
import lonewebbenLogo from "assets/images/lonewebben_logo.png";
import UserTersModalContent from "./components/UserTersModalContent";
import { ReactComponent as UsersIcon } from "assets/icons/user-group-regular.svg";
import BreadcrumbHandler from "components/common/breadcrumbs/BreadcrumbHandler";

import {
	Menu,
	Typography,
	Avatar,
	useMediaQuery,
	AppBar,
	Toolbar,
	Box,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	ListItemIcon,
	CircularProgress,
} from "@mui/material";
import { matchPath } from "react-router-dom";
import { IGetRolesData, roles } from "api/user/Roles";
import ModalDialog from "components/common/modal/ModalDialog";
import VersionBanner from "components/common/VersionBanner";

const StyledTypography = styled(Typography)({
	fontSize: "14px",
	fontWeight: "500",
});

const StyledAvatar = styled(Avatar)({
	bgcolor: "#E1F0FF",
	color: "#3699FF",
	fontWeight: "500",
	margin: "0px 8px 0px 16px",
});

const Appbar = () => {
	const userManager = useContext(AuthContext);

	const [role, setRole] = useState("");
	const [openUserTermsModal, setOpenUserTermsModal] = useState(false);

	const { data, isSuccess, refetch: refreshUserData } = useGetUserFromDirectoryQuery(null);
	const { data: rolesData, isSuccess: isGetRolesSuccess } = useGetRolesFromDirectoryQuery(null);
	const [getChangeRoleFromDirectoryMutation] = useGetChangeRoleFromDirectoryMutation();

	useEffect(() => {
		const activeRole = roles.find((r) => r.roleName == data.activeRole)?.uiRoleName;
		activeRole && setRole(activeRole);
	}, []);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const logOutUser = useCallback(() => {
		handleClose();
		sessionStorage.clear();
		userManager.logout();
		localStorage.removeItem("userAuth");
	}, [userManager]);

	const changeRole = async (roleName: string) => {
		await getChangeRoleFromDirectoryMutation({ role: JSON.stringify(roleName) })
			.unwrap()
			.then((response: any) => {
				const newRole = roles.find((r) => r.roleName == roleName)?.uiRoleName;
				newRole && setRole(newRole);
				enqueueSnackbar(`Du har bytt till roll ${newRole}`, {
					variant: "success",
				});
				refreshUserData();
				handleClose();

				// för att uppdatera nya tilldelade listor
				setTimeout(() => {
					if (newRole == "Förtroendevald") {
						window.location.reload();
					}
				}, 100);
			})
			.catch((error) => {
				enqueueSnackbar(error.data.detail, {
					variant: "error",
					persist: true,
				});
			});
	};

	// 768
	const fullScreen = useMediaQuery(theme.breakpoints.up("md"));

	// 520px
	const mobileScreen = useMediaQuery(theme.breakpoints.up("xs"));

	return (
		<AppBar
			position='sticky'
			style={{
				padding: "2px 0 0 0",
				height: "auto",
				boxShadow: "0px 0px 10px #523F6914",
				background: "white",
			}}
		>
			<ModalDialog
				isVisible={openUserTermsModal}
				label={"Användarvillkor"}
				icon={<UsersIcon />}
				content={<UserTersModalContent showModal={() => setOpenUserTermsModal(false)} />}
				showModal={setOpenUserTermsModal}
			/>
			<Toolbar
				sx={{
					bgcolor: "#fff",
					height: "auto",
					padding: fullScreen ? "0 10px 0 60px !important" : "0 5px 0 20px !important",
					justifyContent: "space-between",
				}}
			>
				<Box
					marginRight={"5px"}
					maxWidth={fullScreen ? "fit-content" : mobileScreen ? "40%" : "fit-content"}
				>
					{location.pathname == "/" ||
					location.pathname == "/aktuella/" ||
					location.pathname == "/aktuella" ||
					location.pathname == "/historik/" ||
					location.pathname == "/historik" ||
					location.pathname == "/utskickslistor/aktuella/" ||
					location.pathname == "/utskickslistor/arkiverade/" ||
					location.pathname == "/utskickslistor/aktuella" ||
					location.pathname == "/utskickslistor/arkiverade" ||
					location.pathname == "/mallar/ombud-ffv/" ||
					location.pathname == "/mallar/ombud-ffv" ||
					location.pathname == "/mallar/arbetsgivare/" ||
					location.pathname == "/mallar/arbetsgivare" ||
					matchPath({ path: "/aktuella/:year" }, location.pathname) ? (
						<img
							src={sefLogo}
							width={fullScreen ? "200px" : "150px"}
							style={{ alignSelf: "center", display: "flex" }}
						/>
					) : (
						<BreadcrumbHandler />
					)}
				</Box>
				<Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
					{fullScreen && (
						<img
							src={lonewebbenLogo}
							width={"150px"}
							style={{ alignSelf: "center", display: "flex", marginRight: 24 }}
						/>
					)}
					<Divider orientation='vertical' flexItem />
					{isSuccess && data ? (
						<>
							{mobileScreen && (
								<Box textAlign={"right"} minWidth={"fit-content"} ml={3}>
									<StyledTypography fontWeight='500'>
										{data.firstName} {data.lastName}
									</StyledTypography>
									<StyledTypography sx={{ color: "#B5B5C3" }}>
										{role ? "Roll: " + role : ""}
									</StyledTypography>
								</Box>
							)}
							<StyledAvatar variant='square'>
								{data.firstName[0]}
								{data.lastName[0]}
							</StyledAvatar>
						</>
					) : null}
					<IconButton onClick={handleClick}>
						<ExpandMoreIcon fontSize='medium' />
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						id='account-menu'
						open={open}
						onClose={handleClose}
						onClick={handleClose}
						PaperProps={{
							elevation: 0,
						}}
						transformOrigin={{ horizontal: "right", vertical: "top" }}
						anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
					>
						{!mobileScreen && (
							<MenuItem>
								<Stack>
									<StyledTypography fontWeight='500'>
										{data?.firstName} {data?.lastName}
									</StyledTypography>
									<StyledTypography sx={{ color: "#B5B5C3", mt: "1px" }}>
										{role ? "Roll: " + role : ""}
									</StyledTypography>
									<Divider sx={{ pt: 2 }} />
								</Stack>
							</MenuItem>
						)}
						<MenuItem onClick={logOutUser}>
							<ListItemIcon>
								<LogoutIcon />
							</ListItemIcon>
							Logga ut
						</MenuItem>
						{isGetRolesSuccess ? (
							rolesData && rolesData.length > 0 ? (
								rolesData.map((data: IGetRolesData) => {
									return (
										<MenuItem key={data.roleName} onClick={() => changeRole(data.roleName)}>
											<ListItemIcon>
												<UserIcon />
											</ListItemIcon>
											{roles.find((r) => r.roleName == data.roleName)?.uiRoleName}
										</MenuItem>
									);
								})
							) : null
						) : (
							<CircularProgress />
						)}
						<Divider sx={{ margin: "0px !important" }} />
						<MenuItem
							onClick={() => {
								setOpenUserTermsModal(true);
								handleClose();
							}}
						>
							<ListItemIcon>
								<UsersIcon />
							</ListItemIcon>
							Användarvillkor
						</MenuItem>
					</Menu>
				</Box>
			</Toolbar>
			{VersionBanner()}
		</AppBar>
	);
};

export default Appbar;
