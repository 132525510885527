import { Tooltip, Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import StatusChip from "components/common/chip/StatusChip";
import { IAddress } from "types/dispatch/dispatchListTable/DispatchListTableTypes";

export interface IEmployeeDto {
	address: IAddress;
	agreementTypeName: string;
	comment: string;
	dispatchListId: number;
	dispatchListName: string;
	name: string;
	organizationNumber: string;
	salaryReportId: string;
	salaryRowEmployeeId: string;
	salaryRowId: string;
	status: number;
	statusMessage: string;
	workplaceName: string;
	workplaceNumber: string;
}
export interface IDuplicate {
	duplicateCount: number;
	name: string;
	nationalIdenityNumber: number;
	salaryRowEmployeesDto: IEmployeeDto[];
}

export const DuplicatesListGridColDef: GridColDef[] = [
	{
		field: "workplaceName",
		headerName: "Arbetsställe",
		minWidth: 250,
		flex: 2.5,
		renderCell: (row) => {
			return (
				<Tooltip
					title={
						<>
							<p style={{ padding: "0", margin: "4px 0", fontSize: "13px" }}>
								<span style={{ fontWeight: "500" }}>Organisationsnummer: </span>
								{row.row.organizationNumber ?? ""}
							</p>
						</>
					}
					placement='bottom-start'
					arrow
				>
					<Box>{row.row.workplaceName ?? ""}</Box>
				</Tooltip>
			);
		},
	},
	{
		field: "address",
		headerName: "Adress",
		minWidth: 200,
		flex: 2,
		valueGetter: (row) => {
			return row.row.address.street ?? "";
		},
		renderCell: (row) => {
			const address = row.row.address;
			return (
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<span>{address?.street ?? ""}</span>
					<span>{(address?.postalCode ?? "") + " " + (address?.city ?? "")}</span>
				</Box>
			);
		},
	},
	{
		field: "dispatchListName",
		headerName: "Utskickslista",
		minWidth: 200,
		flex: 2,
	},
	{
		field: "agreementTypeName",
		headerName: "Avtalsområde",
		minWidth: 200,
		flex: 2,
	},
	{
		field: "status",
		headerName: "",
		sortable: false,
		minWidth: 100,
		flex: 1,
		renderCell: (row) => {
			const status = row.row.status;
			const statusMessage = row.row.statusMessage;
			return <StatusChip statusMessage={statusMessage ?? ""} status={status ?? 0} />;
		},
	},
];

export const filterRows = (rows: IDuplicate[], filterText: string) => {
	const tempList: IDuplicate[] = [];
	if (filterText != "") {
		rows.forEach((row: IDuplicate) => {
			if (
				row.name &&
				row.name.toLowerCase().includes(filterText.toLowerCase()) &&
				!tempList.includes(row)
			) {
				tempList.push(row);
			} else if (
				row.nationalIdenityNumber &&
				row.nationalIdenityNumber.toString().toLowerCase().includes(filterText.toLowerCase()) &&
				!tempList.includes(row)
			) {
				tempList.push(row);
			} else {
				row.salaryRowEmployeesDto.forEach((dto) => {
					if (
						dto.workplaceName &&
						dto.workplaceName.toLowerCase().includes(filterText.toLowerCase()) &&
						!tempList.includes(row)
					) {
						tempList.push(row);
					} else if (
						dto.address &&
						dto.address.fullAddress &&
						dto.address.fullAddress.toLowerCase().includes(filterText.toLowerCase()) &&
						!tempList.includes(row)
					) {
						tempList.push(row);
					} else if (
						dto.comment &&
						dto.comment.toLowerCase().includes(filterText.toLowerCase()) &&
						!tempList.includes(row)
					) {
						tempList.push(row);
					} else if (
						dto.dispatchListName &&
						dto.dispatchListName.toLowerCase().includes(filterText.toLowerCase()) &&
						!tempList.includes(row)
					) {
						tempList.push(row);
					} else if (
						dto.organizationNumber &&
						dto.organizationNumber.toLowerCase().includes(filterText.toLowerCase()) &&
						!tempList.includes(row)
					) {
						tempList.push(row);
					} else if (
						dto.agreementTypeName &&
						dto.agreementTypeName.toLowerCase().includes(filterText.toLowerCase()) &&
						!tempList.includes(row)
					) {
						tempList.push(row);
					} else if (
						dto.statusMessage &&
						dto.statusMessage.toLowerCase().includes(filterText.toLowerCase()) &&
						!tempList.includes(row)
					) {
						tempList.push(row);
					}
				});
			}
		});
	}
	return tempList;
};
