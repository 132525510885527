import React, { useState } from "react";
import { useGetUploadProtocolMutation, useGetSalaryReportRowsQuery } from "api/Api";
import { enqueueSnackbar } from "notistack";
import theme from "styles/theme";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";

import { Box, Typography, Stack, DialogActions, Button, DialogContent } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

import LoadingButton from "components/common/loading/LoadingButton";

interface AttachmentModalContentProps {
	showModal: React.Dispatch<React.SetStateAction<boolean>>;
	setSalaryProtocolResponse: React.Dispatch<React.SetStateAction<IProtocolStatus>>;
}

export interface IProtocolStatus {
	newRows: number; // importstatus 0
	updatedRows: number; // importstatus 1
	errorRows: number; // importstatus 2
	errorRowsReason: string[]; // importstatus 2 error
	failedRows: number; // importstatus 3
	failedRowsReason: string[]; // importstatus 3 error
}

const ProtocolModalContent: React.FC<AttachmentModalContentProps> = ({
	showModal,
	setSalaryProtocolResponse,
}) => {
	const salaryReportId = LastSegmentOfUrl(location.href);
	const [file, setFile] = useState<File | null>(null);
	const [errorMsg, setErrorMsg] = useState<string>();

	const [getUploadProtocol, { isLoading: isLoadingFile }] = useGetUploadProtocolMutation();

	const { refetch: refetchSalaryReportRowsData } = useGetSalaryReportRowsQuery(salaryReportId);

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			setFile(e.target.files[0]);
			setErrorMsg("");
		}
	};

	const calculateRows = async (
		rows: { rowNumber: number; importStatus: number; importError: string }[],
	) => {
		const tempObj: IProtocolStatus = {
			newRows: 0,
			updatedRows: 0,
			errorRows: 0,
			errorRowsReason: [],
			failedRows: 0,
			failedRowsReason: [],
		};
		rows.map((row: { rowNumber: number; importStatus: number; importError: string }) => {
			const type = row.importStatus;
			switch (type) {
				case 0:
					tempObj.newRows++;
					break;
				case 1:
					tempObj.updatedRows++;
					break;
				case 2:
					tempObj.errorRows++;
					tempObj.errorRowsReason.push(`Rad ${row.rowNumber} - ${row.importError}`);
					break;
				case 3:
					tempObj.failedRows++;
					tempObj.failedRowsReason.push(`Rad ${row.rowNumber} - ${row.importError}`);
					break;
				default:
					return;
			}
		});
		setSalaryProtocolResponse(tempObj);
	};

	const saveFile = async () => {
		if (file) {
			const formData = new FormData();
			formData.append("type", file.type);
			formData.append("formFile", file);
			formData.append("fileName", file.name);

			try {
				const form = {
					salaryReportId: salaryReportId,
					file: formData,
				};

				await getUploadProtocol(form)
					.unwrap()
					.then(async (res) => {
						await calculateRows(res.salaryReportProtocolRows);
						enqueueSnackbar('Protokoll "' + file.name + '" uppladdad', { variant: "success" });
						refetchSalaryReportRowsData();
						showModal(false);
					})
					.catch((error) => {
						setErrorMsg(error.data.detail);
						setFile(null);
						// enqueueSnackbar(error.data.detail, { variant: "error", persist: true });
					});
			} catch (error) {
				enqueueSnackbar("Protokoll kan ej laddas upp", { variant: "error", persist: true });
			}
		}
	};

	return (
		<>
			<DialogContent dividers sx={{ width: { md: 500 } }}>
				<Box border={`2px solid ${theme.palette.warning.light}`} borderRadius={2} p={2}>
					<Typography variant='bodyMedium' lineHeight={1.5}>
						VIKTIGT! Observera att data som läses in från fil ersätter all eventuell tidigare
						inskriven data på rader där personnummer matchar.
					</Typography>
				</Box>
				<Box
					display={"flex"}
					alignItems={{ xxs: "start", md: "center" }}
					mt={5}
					mb={4}
					flexDirection={{ xxs: "column", md: "row" }}
				>
					<Button
						variant='secondaryTable'
						component='label'
						sx={{ width: { xxs: "100%", sm: 110 }, marginRight: 2 }}
					>
						Välj fil
						<input type='file' hidden onChange={handleFileChange} />
					</Button>
					<Stack mt={{ xxs: 4, md: 0 }}>
						{file && !errorMsg ? (
							<Typography variant='bodyMedium'>{file.name}</Typography>
						) : errorMsg ? (
							<Typography variant='body' lineHeight={1.5} color={theme.palette.error.contrastText}>
								{errorMsg}
							</Typography>
						) : (
							<Typography variant='body0'>Inget fil vald</Typography>
						)}
					</Stack>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button aria-label='Avbryt' variant='grey' onClick={() => showModal(false)}>
					Avbryt
				</Button>
				{isLoadingFile ? (
					<LoadingButton label={"Laddar upp protokoll"} />
				) : (
					<Button
						aria-label='Spara'
						variant='containedPrimary'
						component='label'
						style={{ marginLeft: 8 }}
						onClick={saveFile}
					>
						<SaveIcon style={{ marginRight: "8px" }} />
						Importera löneprotokoll
					</Button>
				)}
			</DialogActions>
		</>
	);
};
export default ProtocolModalContent;
