import React, { useEffect, useState } from "react";
import {
	useGetPatchSalaryRowCommentMutation,
	useGetSalaryReportQuery,
	useGetSalaryReportRowsQuery,
	useGetUserFromDirectoryQuery,
} from "api/Api";
import { enqueueSnackbar } from "notistack";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";

import { Stack, DialogActions, Button, DialogContent, Typography } from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";

import LoadingButton from "components/common/loading/LoadingButton";
import Comment from "components/common/comment/Comment";
import { IResponsible } from "types/dispatch/dispatchListTable/SelectAgentModal";

interface SalaryRowCommentModalContentProps {
	showModal: React.Dispatch<React.SetStateAction<boolean>>;
	salaryRowId: string;
}

const SalaryRowCommentModalContent: React.FC<SalaryRowCommentModalContentProps> = ({
	showModal,
	salaryRowId,
}) => {
	const salaryReportId = LastSegmentOfUrl(location.href);
	const [comment, setComment] = useState<string>("");

	const { data: userData } = useGetUserFromDirectoryQuery(null);

	const {
		data: salaryReportRowsData,
		refetch: refetchSalaryReportRows,
		isLoading: isLoadingsalaryReportRowsData,
		isError: isErrorsalaryReportRowsData,
	} = useGetSalaryReportRowsQuery(salaryReportId);

	const [getPatchSalaryRowComment, { isLoading: createLoading }] =
		useGetPatchSalaryRowCommentMutation();

	const { data: salaryProtocolData } = useGetSalaryReportQuery(salaryReportId);
	const { data: salaryReportData } = useGetSalaryReportQuery(salaryReportId);
	const isOmbud =
		salaryProtocolData.responsibles.findIndex((r: IResponsible) => r.userId == userData.id) >= 0;
	const ombudCanEdit = isOmbud && salaryProtocolData.status == 250;
	const disableNonAdmin = userData.activeRole == "non-admin" && salaryProtocolData.status >= 250;
	const isArchived = salaryReportData.dispatchListArchivedDate != null ?? false;

	useEffect(() => {
		if (salaryRowId && salaryReportRowsData) {
			const worker = salaryReportRowsData?.salaryRows?.filter((row: { salaryRowId: string }) => {
				return row.salaryRowId == salaryRowId;
			});

			setComment(worker[0].comment);
		}
	}, []);

	const saveComment = async () => {
		const form = {
			salaryReportId: salaryReportId,
			salaryRowId: salaryRowId,
			comment: JSON.stringify(comment),
		};

		await getPatchSalaryRowComment(form)
			.unwrap()
			.then((response) => {
				enqueueSnackbar("Kommentar sparad", { variant: "success" });
				showModal(false);
				refetchSalaryReportRows();
			})
			.catch((error) => {
				enqueueSnackbar(error.data.detail || "Kan ej spara kommentar", {
					variant: "error",
					persist: true,
				});
			});
	};

	return (
		<>
			<DialogContent dividers sx={{ minWidth: { md: 500 } }}>
				<Stack spacing={2} mb={2}>
					{(!disableNonAdmin && !isArchived) || ombudCanEdit ? (
						<Comment
							commentLabel={"Kommentar:"}
							isLoading={isLoadingsalaryReportRowsData}
							isError={isErrorsalaryReportRowsData}
							setComment={setComment}
							comment={comment}
						/>
					) : (
						<Typography variant='body'>{comment}</Typography>
					)}
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button aria-label='Avbryt' variant='grey' onClick={() => showModal(false)}>
					Avbryt
				</Button>

				{(!disableNonAdmin && !isArchived) || ombudCanEdit ? (
					createLoading ? (
						<LoadingButton label={"Sparar kommentar"} />
					) : (
						<Button
							aria-label='Spara'
							variant='containedPrimary'
							onClick={saveComment}
							startIcon={<SaveIcon />}
							disabled={comment?.length >= 1000}
						>
							Spara
						</Button>
					)
				) : null}
			</DialogActions>
		</>
	);
};
export default SalaryRowCommentModalContent;
