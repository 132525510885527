import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import theme from "styles/theme";
import { ISalaryReportOverviewList } from "types/representative/commonSalaryReportTypes";
import { Box } from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid";
import { StyledOverviewDataGrid } from "components/common/styledComponents/StyledOverviewDataGrid";
import { salaryProtocolOverviewGridColDef } from "./SalaryProtocolOverviewGridColDef";

interface ISalaryReportProps {
	rowProps: ISalaryReportOverviewList[];
	isActiveSalaryProtocol: boolean;
}

export default function SalaryProtocolOverviewListTable({
	rowProps,
	isActiveSalaryProtocol,
}: ISalaryReportProps) {
	const navigate = useNavigate();

	const [sortModel, setSortModel] = useState<GridSortModel>([
		{
			field: "workplaceName",
			sort: "asc",
		},
	]);

	const rows =
		rowProps &&
		rowProps.map((s: ISalaryReportOverviewList) => ({
			id: s.salaryReportId ?? "",
			workplaceName: s.workplaceName ?? "",
			agreementTypeName: s.agreementTypeName ?? "",
			departmentNumber: s.departmentNumber ?? "",
			workplaceMunicipality: s.workplaceMunicipality ?? "",
			statusMessage: s.statusMessage ?? "",
			status: s.status ?? "",
			salaryRevisionDate: s.salaryRevisionDate ?? "",
		}));

	const columns = salaryProtocolOverviewGridColDef(isActiveSalaryProtocol);

	useEffect(() => {
		if (rows && rows.length > 0) {
			const sortModelStorage = sessionStorage.getItem("sortModelSalaryProtocolOverviewActive");
			sortModelStorage && setSortModel(JSON.parse(sortModelStorage));
		}
	}, []);

	useEffect(() => {
		if (rows && rows.length > 0) {
			sessionStorage.setItem("sortModelSalaryProtocolOverviewActive", JSON.stringify(sortModel));
		}
	}, [sortModel]);

	return (
		<Box>
			{rows && rows.length > 0 ? (
				<Box>
					<StyledOverviewDataGrid
						autoHeight
						rows={rows}
						columns={columns}
						sortModel={sortModel}
						onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
						disableColumnMenu
						hideFooterPagination
						hideFooter
						rowHeight={67}
						columnHeaderHeight={40}
						// settings depending on isActiveSalaryProtocol (active/history)
						initialState={{
							columns: {
								columnVisibilityModel: {
									agreementTypeName: isActiveSalaryProtocol,
								},
							},
						}}
						disableRowSelectionOnClick={isActiveSalaryProtocol}
						onRowClick={(row) => {
							const year = `${row.row.salaryRevisionDate.substring(0, 4)}`;
							isActiveSalaryProtocol === false && navigate(`/historik/${year}/${row.id}`);
						}}
						sx={{
							"& .MuiDataGrid-row": {
								"&:hover": {
									backgroundColor: isActiveSalaryProtocol
										? "inherit !important"
										: theme.palette.action.hover,
									cursor: isActiveSalaryProtocol ? "default" : "pointer",
								},
							},
						}}
					/>
				</Box>
			) : (
				<p style={{ marginTop: 5 }}>Inga listor</p>
			)}
		</Box>
	);
}

// save Filter for now - wait until feedback from sef, if they don't ask for it, delete it
// const [filteredRows, setFilteredRows] = useState<any[]>([]);
// const [filterValue, setFilterValue] = useState<string>("");
// const [rowsCount, setRowsCount] = useState<number>(5);
// const [limit, setLimit] = useState(5);
// useEffect(() => {
// 	if (filterValue != "") {
// 		const tempList: any[] = [];
// 		rows.forEach((row: any) => {
// 			if (
// 				row.workplaceName.toLowerCase().includes(filterValue.toLowerCase()) ||
// 				row.agreement.toLowerCase().includes(filterValue.toLowerCase()) ||
// 				row.agreementNr.toLowerCase().includes(filterValue.toLowerCase()) ||
// 				row.address.toLowerCase().includes(filterValue.toLowerCase()) ||
// 				row.status.toLowerCase().includes(filterValue.toLowerCase())
// 			) {
// 				tempList.push(row);
// 			}
// 		});
// 		setFilteredRows(tempList);
// 	} else {
// 		setFilteredRows([]);
// 	}
// }, [filterValue]);
{
	/* <Box sx={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
				<Box
					sx={{
						mb: 2,
						width: { xxs: "100%", md: "300px" },
					}}
				>
					<OutlinedInput
						fullWidth
						placeholder='Filtrera'
						onChange={(event) => setFilterValue(event.target.value)}
						startAdornment={<SearchIcon sx={{ marginLeft: "8px", color: "#B5B5C3" }} />}
					/>
				</Box>
			</Box> */
}
// ) : filterValue != "" && filteredRows.length == 0 ? (
// <p style={{ marginTop: 5 }}>Inga listor</p>
// rows={filteredRows.length > 0 ? filteredRows : rows.slice(0, limit)}
