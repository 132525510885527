import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ConfirmationState {
	isOpen: boolean;
	message: string[];
	callback?: (confirmed: boolean) => void;
}

const initialState: ConfirmationState = {
	isOpen: false,
	message: [""],
};

const confirmationSlice = createSlice({
	name: "confirmation",
	initialState,
	reducers: {
		openConfirmation(
			state,
			action: PayloadAction<{ message: string[]; callback?: (confirmed: boolean) => void }>,
		) {
			const { message, callback } = action.payload;
			state.isOpen = true;
			state.message = message;
			state.callback = callback;
		},
		closeConfirmation(state) {
			state.isOpen = false;
			state.callback = undefined;
		},
	},
});

export const { openConfirmation, closeConfirmation } = confirmationSlice.actions;

export default confirmationSlice.reducer;
