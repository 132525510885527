import { Box, Card, Tab, Tabs, styled } from "@mui/material";
import colors from "styles/colors-theme";

export const LayoutBoxHideInMobile = styled(Box)(({ theme }) => ({
	[theme.breakpoints.down("sm")]: {
		display: "none",
	},
	display: "block",
	marginLeft: "auto",
})) as typeof Box;

export const LayoutBoxShowInMobile = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up("sm")]: {
		display: "none",
	},
	display: "block",
	marginLeft: "auto",
})) as typeof Box;

export const LayoutBoxStyled = styled(Box)(({ theme }) => ({
	padding: "45px 55px",

	[theme.breakpoints.down("md")]: {
		padding: "20px 21px",
	},

	[theme.breakpoints.down("sm")]: {
		padding: "10px",
	},
})) as typeof Box;

//  ******* FLIKAR ******

export const LayoutCardStyled = styled(Card)(({ theme }) => ({
	borderRadius: 4,
	boxShadow: `0px 0px 16px ${theme.palette.boxShadow.main}`,
	textDecoration: "none",
	borderWidth: "0.1px",
	borderColor: theme.palette.border.main,
	borderStyle: "solid",
	minHeight: "auto",
})) as typeof Card;

interface StyledTabsProps {
	children?: React.ReactNode;
	value: number;
	onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const LayoutTabsStyled = styled((props: StyledTabsProps) => (
	<Tabs {...props} TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }} />
))(({ theme }) => ({
	"& .MuiTabs-indicator": {
		display: "none",
	},
}));

export const LayoutTabStyled = styled(Tab)(({ theme }) => ({
	borderRight: `0.1px solid ${theme.palette.border.main}`,
	minHeight: 70,
	padding: 28,
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "center",
	fontSize: 0,
	textTransform: "capitalize",

	"&:hover": {
		color: colors.text.darkestGrey,
	},

	"& svg": {
		marginRight: 8,

		marginBottom: "2px !important",
		fill: colors.text.grey,
		"& #Path_85 ": {
			fill: colors.text.grey,
		},
		"& #Path_86": {
			fill: colors.text.grey,
		},
		"& #Rectangle_95 ": {
			fill: colors.text.grey,
		},
		"& #Path_21": {
			fill: colors.text.grey,
		},
		"& #Path_22": {
			fill: colors.text.grey,
		},
		":hover": {
			fill: colors.text.darkestGrey,
			color: colors.text.darkestGrey,
		},
	},

	"&.Mui-selected": {
		backgroundColor: "white",
		boxShadow: `0px 0px 10px ${theme.palette.boxShadow.main}`,
		fontSize: 16,

		color: colors.text.darkestGrey,
		"& svg": {
			fill: colors.primary,

			"& #Path_21": {
				fill: colors.primary,
			},
			"& #Path_22": {
				fill: colors.primary,
			},
			"& #Path_85": {
				fill: colors.primary,
			},
			"& #Path_86": {
				fill: colors.primary,
			},
			"& #Rectangle_95": {
				fill: colors.primary,
			},
		},
	},
})) as typeof Tab;
