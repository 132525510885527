import React, { useEffect, useState } from "react";
import { useGetDispatchTemplateMutation, useGetUpdateDispatchTemplateMutation } from "api/Api";
import { enqueueSnackbar } from "notistack";

import colors from "styles/colors-theme";
import { Box, Button, OutlinedInput, Typography, Stack, CircularProgress } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
	IGetDispatchTemplateForm,
	IGetDispatchTemplateData,
} from "types/templates/CommonTemplateTypes";
import {
	AccordionDetailsStyled,
	AccordionStyled,
	AccordionSummaryStyled,
} from "./styles/GeneralTemplatesStyle";
import CheckboxLabel from "components/common/checkbox/CheckboxLabel";
import TextEditor from "components/common/texteditor/TextEditor";
import LoadingButton from "components/common/loading/LoadingButton";

export default function GeneralTemplates({ role }: { role: string }) {
	const [isLoadingData, setIsLoadingData] = useState(true);
	const [getUpdateDispatchTemplate, { isLoading: isSaving }] =
		useGetUpdateDispatchTemplateMutation();
	const [getDispatchTemplateMutation] = useGetDispatchTemplateMutation();

	const recipientType = role == "ombud/FFV" ? 2 : 1;

	const firstDispatchForm: IGetDispatchTemplateForm = {
		form: {
			recipientType: recipientType,
			dispatchType: 10,
		},
	};
	const reminderOneForm: IGetDispatchTemplateForm = {
		form: {
			recipientType: recipientType,
			dispatchType: 20,
		},
	};
	const reminderTwoForm: IGetDispatchTemplateForm = {
		form: {
			recipientType: recipientType,
			dispatchType: 30,
		},
	};

	const [firstDispatchText, setFirstDispatchText] = useState("");
	const [reminderOneText, setReminderOneText] = useState("");
	const [reminderTwoText, setReminderTwoText] = useState("");

	const [firstDispatchChanged, setFirstDispatchChanged] = useState<boolean>(false);
	const [reminderOneChanged, setReminderOneChanged] = useState<boolean>(false);
	const [reminderTwoChanged, setReminderTwoChanged] = useState<boolean>(false);

	const [firstDispatchData, setFirstDispatchData] = useState<IGetDispatchTemplateData>({
		recipientType: 0,
		dispatchType: 10,
		title: "",
		body: "",
		updatedDate: "",
		includeGeneratedProtocol: false,
		isValid: false,
		hasTemplate: false,
	});
	const [reminderOneData, setReminderOneData] = useState<IGetDispatchTemplateData>({
		recipientType: 0,
		dispatchType: 20,
		title: "",
		body: "",
		updatedDate: "",
		includeGeneratedProtocol: false,
		isValid: false,
		hasTemplate: false,
	});
	const [reminderTwoData, setReminderTwoData] = useState<IGetDispatchTemplateData>({
		recipientType: 0,
		dispatchType: 30,
		title: "",
		body: "",
		updatedDate: "",
		includeGeneratedProtocol: false,
		isValid: false,
		hasTemplate: false,
	});

	useEffect(() => {
		setFirstDispatch({ ...firstDispatch, body: firstDispatchText });
	}, [firstDispatchText]);

	useEffect(() => {
		setReminderOne({ ...reminderOne, body: reminderOneText });
	}, [reminderOneText]);

	useEffect(() => {
		setReminderTwo({ ...reminderTwo, body: reminderTwoText });
	}, [reminderTwoText]);

	const [firstDispatch, setFirstDispatch] = useState<IGetDispatchTemplateData>({
		recipientType: 0,
		dispatchType: 10,
		title: "",
		body: "",
		includeGeneratedProtocol: false,
		updatedDate: "",
		isValid: false,
		hasTemplate: false,
	});
	const [reminderOne, setReminderOne] = useState<IGetDispatchTemplateData>({
		recipientType: 0,
		dispatchType: 20,
		title: "",
		body: "",
		includeGeneratedProtocol: false,
		updatedDate: "",
		isValid: false,
		hasTemplate: false,
	});
	const [reminderTwo, setReminderTwo] = useState<IGetDispatchTemplateData>({
		recipientType: 0,
		dispatchType: 30,
		title: "",
		body: "",
		includeGeneratedProtocol: false,
		updatedDate: "",
		isValid: false,
		hasTemplate: false,
	});

	const fetchData = async (dispatchType?: number) => {
		try {
			if (dispatchType === 10 || !dispatchType) {
				await getDispatchTemplateMutation(firstDispatchForm)
					.unwrap()
					.then((res) => {
						if (res) {
							const firstDispatchData = res;
							const tempList: IGetDispatchTemplateData[] = [];
							tempList.push({
								title: firstDispatchData.title,
								body: firstDispatchData.body,
								recipientType: firstDispatchData.recipientType,
								dispatchType: firstDispatchData.dispatchType,
								hasTemplate: firstDispatchData.hasTemplate,
								includeGeneratedProtocol: firstDispatchData.includeGeneratedProtocol,
								updatedDate: firstDispatchData.updatedDate,
								isValid: firstDispatchData.isValid,
							});
							setFirstDispatch(tempList[0]);
							setFirstDispatchData(firstDispatchData);
							setFirstDispatchChanged(false);
						}
					});
			}
			if (dispatchType === 20 || !dispatchType) {
				await getDispatchTemplateMutation(reminderOneForm)
					.unwrap()
					.then((res) => {
						if (res) {
							const reminderOneData = res;
							const tempList: IGetDispatchTemplateData[] = [];

							tempList.push({
								title: reminderOneData.title,
								body: reminderOneData.body,
								recipientType: reminderOneData.recipientType,
								dispatchType: reminderOneData.dispatchType,
								includeGeneratedProtocol: reminderOneData.includeGeneratedProtocol,
								updatedDate: reminderOneData.updatedDate,
								isValid: reminderOneData.isValid,
								hasTemplate: reminderOneData.hasTemplate,
							});
							setReminderOne(tempList[0]);
							setReminderOneData(reminderOneData);
							setReminderOneChanged(false);
						}
					});
			}
			if (dispatchType === 30 || !dispatchType) {
				await getDispatchTemplateMutation(reminderTwoForm)
					.unwrap()
					.then((res) => {
						if (res) {
							const reminderTwoData = res;
							const tempList: IGetDispatchTemplateData[] = [];
							tempList.push({
								title: reminderTwoData.title,
								body: reminderTwoData.body,
								recipientType: reminderTwoData.recipientType,
								dispatchType: reminderTwoData.dispatchType,
								includeGeneratedProtocol: reminderTwoData.includeGeneratedProtocol,
								updatedDate: reminderTwoData.updatedDate,
								isValid: reminderTwoData.isValid,
								hasTemplate: reminderTwoData.hasTemplate,
							});
							setReminderTwo(tempList[0]);
							setReminderTwoData(reminderTwoData);
							setReminderTwoChanged(false);
						}
					});
			}
			setIsLoadingData(false);
		} catch (error) {
			setIsLoadingData(false);
			console.error(error);
		}
	};

	useEffect(() => {
		fetchData();
	}, [getDispatchTemplateMutation]);

	const saveTemplates = async (form: IGetDispatchTemplateData) => {
		const formUpdate = {
			recipientType: recipientType,
			dispatchType: form.dispatchType,
			title: form.title,
			body: form.body,
			includeGeneratedProtocol: form.includeGeneratedProtocol,
		};

		await getUpdateDispatchTemplate(formUpdate)
			.unwrap()
			.then((response) => {
				fetchData(form.dispatchType);
				enqueueSnackbar("Utskicksmallen sparades", { variant: "success" });
			})
			.catch((error) => {
				enqueueSnackbar(error.data.detail, {
					variant: "error",
					persist: true,
				});
			});
	};

	// show prompt if unsaved changes in any templates
	window.onbeforeunload = () => {
		if (firstDispatchChanged || reminderOneChanged || reminderTwoChanged) {
			return true;
		} else {
			return null;
		}
	};

	if (!isLoadingData) {
		return (
			<Box sx={{ width: "100%", border: "0" }}>
				<AccordionStyled>
					<AccordionSummaryStyled aria-controls='panel1a-content' id='panel1a-header'>
						<Stack flexDirection={"row"} alignItems={"center"}>
							<Typography mr={1}>Första utskick</Typography>
							<CheckCircleIcon color='primary' fontSize='small' />
						</Stack>
						{firstDispatch.updatedDate && (
							<Typography variant='body0'>
								Senast ändrad:{" "}
								<Typography
									variant='body0'
									color={firstDispatch.isValid ? colors.primary : colors.error2}
								>
									{firstDispatch.updatedDate.slice(0, 10) || ""}
								</Typography>
							</Typography>
						)}
					</AccordionSummaryStyled>
					<AccordionDetailsStyled>
						<OutlinedInput
							placeholder='Rubrik'
							type='text'
							onChange={(e) => {
								setFirstDispatch({ ...firstDispatch, ["title"]: e.target.value });
								setFirstDispatchChanged(true);
							}}
							value={firstDispatch.title}
						/>
						<Stack
							mt={2}
							sx={{
								borderColor: `${colors.border.light} !important`,
								border: "1px solid",
								borderRadius: 1.5,
								padding: 1,
							}}
						>
							<TextEditor
								initialText={firstDispatchData.body}
								smallEditor
								onChange={(text) => {
									setFirstDispatchText(text);
									setFirstDispatchChanged(true);
									setFirstDispatch({ ...firstDispatch, ["body"]: text as string });
								}}
							/>
						</Stack>
						<Stack flexDirection={"row"} mt={4} justifyContent={"space-between"}>
							<CheckboxLabel
								label='Bifoga tomt löneprotokoll'
								checked={firstDispatch.includeGeneratedProtocol || false}
								value={"firstDispatch"}
								handleCheckboxChange={(e) => {
									setFirstDispatch({
										...firstDispatch,
										["includeGeneratedProtocol"]: e.target.checked,
									});
									setFirstDispatchChanged(true);
								}}
							/>

							{isSaving ? (
								<LoadingButton label={"Sparar"} />
							) : (
								<Button
									variant='containedPrimary'
									startIcon={<SaveIcon />}
									onClick={() => saveTemplates(firstDispatch)}
									disabled={!firstDispatchChanged}
									sx={{ ml: 1 }}
								>
									Spara
								</Button>
							)}
						</Stack>
					</AccordionDetailsStyled>
				</AccordionStyled>

				<AccordionStyled>
					<AccordionSummaryStyled aria-controls='panel1a-content' id='panel1a-header'>
						<Stack flexDirection={"row"} alignItems={"center"}>
							<Typography mr={1}>Påminnelse 1</Typography>
							<CheckCircleIcon color='warning' fontSize='small' />
						</Stack>
						{reminderOne.updatedDate && (
							<Typography variant='body0'>
								Senast ändrad:{" "}
								<Typography
									variant='body0'
									color={reminderOne.isValid ? colors.primary : colors.error2}
								>
									{reminderOne.updatedDate.slice(0, 10) || ""}
								</Typography>
							</Typography>
						)}
					</AccordionSummaryStyled>
					<AccordionDetailsStyled>
						<OutlinedInput
							placeholder='Rubrik'
							type='text'
							value={reminderOne.title}
							onChange={(e) => {
								setReminderOne({ ...reminderOne, ["title"]: e.target.value });
								setReminderOneChanged(true);
							}}
						/>
						<Stack
							mt={2}
							sx={{
								borderColor: `${colors.border.light} !important`,
								border: "1px solid",
								borderRadius: 1.5,
								padding: 1,
							}}
						>
							<TextEditor
								initialText={reminderOneData.body}
								smallEditor
								onChange={(text) => {
									setReminderOneText(text);
									setReminderOneChanged(true);
									setReminderOne({ ...reminderOne, ["body"]: text as string });
								}}
							/>
						</Stack>
						<Stack flexDirection={"row"} mt={4} justifyContent={"space-between"}>
							<CheckboxLabel
								label='Bifoga tomt löneprotokoll'
								checked={reminderOne.includeGeneratedProtocol || false}
								value={"reminderOne"}
								handleCheckboxChange={(e) => {
									setReminderOne({
										...reminderOne,
										["includeGeneratedProtocol"]: e.target.checked,
									});
									setReminderOneChanged(true);
								}}
							/>
							{isSaving ? (
								<LoadingButton label={"Sparar"} />
							) : (
								<Button
									variant='containedPrimary'
									startIcon={<SaveIcon />}
									onClick={() => saveTemplates(reminderOne)}
									disabled={!reminderOneChanged}
									sx={{ ml: 1 }}
								>
									Spara
								</Button>
							)}
						</Stack>
					</AccordionDetailsStyled>
				</AccordionStyled>

				<AccordionStyled>
					<AccordionSummaryStyled aria-controls='panel1a-content' id='panel1a-header'>
						<Stack flexDirection={"row"} alignItems={"center"}>
							<Typography mr={1}>Påminnelse 2</Typography>
							<CheckCircleIcon sx={{ color: colors.purple.main }} fontSize='small' />
						</Stack>
						{reminderTwo.updatedDate && (
							<Typography variant='body0'>
								Senast ändrad:{" "}
								<Typography
									variant='body0'
									color={reminderTwo.isValid ? colors.primary : colors.error2}
								>
									{reminderTwo.updatedDate.slice(0, 10) || ""}
								</Typography>
							</Typography>
						)}
					</AccordionSummaryStyled>
					<AccordionDetailsStyled>
						<OutlinedInput
							placeholder='Rubrik'
							type='text'
							value={reminderTwo.title}
							onChange={(e) => {
								setReminderTwo({ ...reminderTwo, ["title"]: e.target.value });
								setReminderTwoChanged(true);
							}}
						/>
						<Stack
							mt={2}
							sx={{
								borderColor: `${colors.border.light} !important`,
								border: "1px solid",
								borderRadius: 1.5,
								padding: 1,
							}}
						>
							<TextEditor
								initialText={reminderTwoData.body}
								smallEditor
								onChange={(text) => {
									setReminderTwoText(text);
									setReminderTwoChanged(true);
									setReminderTwo({ ...reminderTwo, ["body"]: text as string });
								}}
							/>
						</Stack>
						<Stack flexDirection={"row"} mt={4} justifyContent={"space-between"}>
							<CheckboxLabel
								label='Bifoga tomt löneprotokoll'
								checked={reminderTwo.includeGeneratedProtocol || false}
								value={"reminderTwo"}
								handleCheckboxChange={(e) => {
									setReminderTwo({
										...reminderTwo,
										["includeGeneratedProtocol"]: e.target.checked,
									});
									setReminderTwoChanged(true);
								}}
							/>
							{isSaving ? (
								<LoadingButton label={"Sparar"} />
							) : (
								<Button
									variant='containedPrimary'
									startIcon={<SaveIcon />}
									onClick={() => saveTemplates(reminderTwo)}
									disabled={!reminderTwoChanged}
									sx={{ ml: 1 }}
								>
									Spara
								</Button>
							)}
						</Stack>
					</AccordionDetailsStyled>
				</AccordionStyled>
			</Box>
		);
	} else {
		return <CircularProgress />;
	}
}
