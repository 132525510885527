import { Box } from "@mui/material";

import CardMobileView from "components/representative/salaryProtocolOverview/components/CardMobileView";
import {
	ISalaryProtocolOverViewListContent,
	ISalaryReportOverviewList,
} from "types/representative/commonSalaryReportTypes";

interface ISalaryReportProps {
	rowProps: ISalaryReportOverviewList[];
	isActiveSalaryProtocol: boolean;
}

export default function salaryProtocolOverviewListCard({
	rowProps,
	isActiveSalaryProtocol,
}: ISalaryReportProps) {
	const rows = isActiveSalaryProtocol
		? rowProps &&
		  rowProps.map((row: ISalaryReportOverviewList) => ({
				id: row.salaryReportId,
				workplaceName: row.workplaceName ?? "",
				agreementTypeName: row.agreementTypeName ?? "",
				departmentNumber: row.departmentNumber ?? "",
				workplaceMunicipality: row.workplaceMunicipality ?? "",
				statusMessage: row.statusMessage ?? "",
				status: row.status ?? "",
				year: row.salaryRevisionDate.substring(0, 4) ?? "",
		  }))
		: rowProps &&
		  rowProps.map((row: ISalaryReportOverviewList) => ({
				id: row.salaryReportId,
				workplaceName: row.workplaceName ?? "",
				departmentNumber: row.departmentNumber ?? "",
				workplaceMunicipality: row.workplaceMunicipality ?? "",
				statusMessage: row.statusMessage ?? "",
				status: row.status ?? "",
				year: row.salaryRevisionDate.substring(0, 4) ?? "",
		  }));

	return (
		<Box>
			{rows && rows.length > 0 ? (
				<Box display={"flex"} flexWrap={"wrap"} flexDirection={"row"}>
					{rows.map((row: ISalaryProtocolOverViewListContent) => {
						const salaryProtocolContent = {
							id: row.id,
							workplaceName: row.workplaceName,
							agreementTypeName: row.agreementTypeName,
							departmentNumber: row.departmentNumber,
							workplaceMunicipality: row.workplaceMunicipality,
							statusMessage: row.statusMessage,
							status: row.status,
							year: row.year,
						};

						return (
							<CardMobileView
								key={row.id}
								salaryProtocolContent={salaryProtocolContent}
								isActiveSalaryProtocol={isActiveSalaryProtocol}
							/>
						);
					})}
				</Box>
			) : (
				<p style={{ marginTop: 5 }}>Inga listor</p>
			)}
		</Box>
	);
}
