import React, { useState, MouseEvent, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { ReactComponent as UsersIcon } from "assets/icons/user-group-regular.svg";
import { ReactComponent as EmployerIcon } from "assets/icons/employer.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Button from "@mui/material/Button";
import ModalDialogPreview from "components/templates/components/ModalDialogPreview";

interface DispatchPreviewButtonProps {
	id: string;
	dispatchListId: string;
	disabled?: boolean;
}

export default function DispatchPreviewButton({
	id,
	dispatchListId,
	disabled,
}: DispatchPreviewButtonProps) {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [role, setRole] = useState<1 | 2>();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const showPreview = (roleParam: 1 | 2) => {
		// arbetsgivare = 1, ombud/FFV = 2
		const correctRole: 1 | 2 = roleParam == 1 ? 2 : 1;
		setRole(correctRole);
		setShowModal(true);
		setAnchorEl(null);
	};

	useEffect(() => {
		setRole(role);
	}, [showPreview]);

	return (
		<>
			{showModal && (
				<ModalDialogPreview
					isVisible={showModal}
					showModal={setShowModal}
					role={role}
					agreementTypeId={id}
					dispatchListId={dispatchListId}
				/>
			)}

			<Button
				aria-label='Förhandsgranska'
				id='demo-customized-button'
				aria-controls={open ? "demo-customized-menu" : undefined}
				aria-haspopup='true'
				aria-expanded={open ? "true" : undefined}
				variant='containedSecondary'
				disableElevation
				onClick={handleClick}
				startIcon={<SearchIcon />}
				endIcon={<KeyboardArrowDownIcon />}
				fullWidth
				sx={{ maxWidth: 200 }}
				disabled={disabled}
				// sx={{ maxWidth: { xs: "100%", sm: 210 } }}
			>
				Förhandsgranska
			</Button>
			<Menu
				MenuListProps={{
					"aria-labelledby": "redigera",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={() => setAnchorEl(null)}
			>
				<MenuItem onClick={() => showPreview(1)}>
					<UsersIcon style={{ marginRight: "12px" }} />
					Förtroendevald
				</MenuItem>
				<MenuItem onClick={() => showPreview(2)}>
					<EmployerIcon style={{ marginRight: "12px" }} />
					Arbetsgivare
				</MenuItem>
			</Menu>
		</>
	);
}
