import React, { useState, useEffect } from "react";
import { Box, Button, Link } from "@mui/material";

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import InventoryIcon from "@mui/icons-material/Inventory";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as CreateIcon } from "assets/icons/letter-send.svg";

import DispatchOverviewListTable from "./DispatchOverviewListTable";
import ModalDialog from "components/common/modal/ModalDialog";
import CreateDispatchModalContent from "./components/CreateDispatchModalContent";
import DispatchOverviewListTableArchive from "./DispatchOverviewListTableArchive";
import { useLocation, useNavigate } from "react-router-dom";
import {
	LayoutBoxStyled,
	LayoutTabStyled,
	LayoutTabsStyled,
	LayoutCardStyled,
	LayoutBoxHideInMobile,
	LayoutBoxShowInMobile,
} from "components/common/styledComponents/Layout";
import AlertMessage from "components/common/alert/AlertMessage";
import { useGetAllActiveQuery, useLazyGetDuplicateNumberOfSalaryRowEmployeesQuery } from "api/Api";
import { IActiveList } from "types/dispatch/DispatchList";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 2 }}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export default function DispatchOverviewList() {
	const [value, setValue] = useState(0);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [duplicatesSum, setDuplicatesSum] = useState<number>(0);

	const pageTabNames = ["aktuella", "arkiverade"];

	const location = useLocation();

	const { data } = useGetAllActiveQuery(null);
	const [getDuplicateNumberOfSalaryRowEmployees] =
		useLazyGetDuplicateNumberOfSalaryRowEmployeesQuery();

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
		window.history.pushState({}, "", "/utskickslistor/" + pageTabNames[newValue]);
	};

	const navigate = useNavigate();
	useEffect(() => {
		const redirectUri = localStorage.getItem("redirectUri");

		// going to url from new tab
		if (redirectUri) {
			const dispatchListId = redirectUri.split("/").pop();
			dispatchListId && localStorage.setItem("dispatchListId", dispatchListId);

			localStorage.removeItem("redirectUri");
			navigate(redirectUri);
		}
		const path =
			location.pathname
				.split("/")
				.filter((x: string) => x)
				.pop() ?? "aktuella";
		setValue(pageTabNames.indexOf(path));
	}, [location]);

	useEffect(() => {
		let ids = "";
		if (data) {
			data.map((d: IActiveList) => {
				if (ids != "") {
					ids += "&DispatchListIds=" + d.id;
				} else {
					ids += "DispatchListIds=" + d.id;
				}
			});
		}
		getDuplicateNumberOfSalaryRowEmployees(ids)
			.unwrap()
			.then((response: number) => {
				setDuplicatesSum(response);
			});
	}, [data]);

	return (
		<LayoutBoxStyled>
			<ModalDialog
				isVisible={showModal}
				label={"Ny utskickslista"}
				icon={<CreateIcon />}
				content={<CreateDispatchModalContent showModal={setShowModal} />}
				showModal={setShowModal}
			/>
			{duplicatesSum > 0 && (
				<Box mb={6}>
					<AlertMessage
						alertText='Det finns dubbletter av arbetstagare på löneprotokollen.'
						type={"info"}
						actionLink={
							<Link
								onClick={() => navigate("/utskickslistor/dubbletter")}
								color='primary'
								ml={0.75}
								sx={{
									cursor: "pointer",
								}}
							>
								Visa dubbletter
							</Link>
						}
					/>
				</Box>
			)}
			<LayoutCardStyled>
				<Box
					sx={{
						display: "inline-flex",
						background: "#F3F6F9",
						width: "100%",
						alignItems: "center",
					}}
				>
					<LayoutTabsStyled value={value} onChange={handleChange} aria-label='basic tabs example'>
						<LayoutTabStyled
							icon={<FormatListBulletedIcon />}
							label='Aktuella listor'
							{...a11yProps(0)}
						/>
						<LayoutTabStyled icon={<InventoryIcon />} label='Arkiverade listor' {...a11yProps(1)} />
					</LayoutTabsStyled>

					{value == 0 ? (
						<LayoutBoxHideInMobile>
							<Button
								aria-label='Ny utskickslista'
								variant='containedPrimary'
								startIcon={<AddIcon />}
								sx={{ marginLeft: "auto", marginRight: "28px" }}
								onClick={() => setShowModal(true)}
							>
								Ny utskickslista
							</Button>
						</LayoutBoxHideInMobile>
					) : null}
				</Box>
				{value == 0 ? (
					<LayoutBoxShowInMobile mt={4} px={3.5}>
						<Button
							aria-label='Ny utskickslista'
							variant='containedPrimary'
							startIcon={<AddIcon />}
							sx={{ marginLeft: "auto", marginRight: "45px" }}
							onClick={() => setShowModal(true)}
							fullWidth
						>
							Ny utskickslista
						</Button>
					</LayoutBoxShowInMobile>
				) : null}
				<TabPanel value={value} index={0}>
					<DispatchOverviewListTable />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<DispatchOverviewListTableArchive />
				</TabPanel>
			</LayoutCardStyled>
		</LayoutBoxStyled>
	);
}
