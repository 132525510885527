import { createTheme, Theme } from "@mui/material/styles";
import { svSE } from "@mui/x-data-grid";
import colors from "./colors-theme";
import checkIcon from "../assets/icons/check.svg";
import checkIconDisabled from "../assets/icons/check-disabled.svg";
import type {} from "@mui/x-data-grid/themeAugmentation";

declare module "@mui/material/styles/createPalette" {
	interface TypeText {
		lightestGrey: string;
		lighterGrey: string;
		grey: string;
		darkGrey: string;
		darkestGrey: string;
		// warning: string;
		primary: string;
		secondary: string;
	}
	interface TypeBackground {
		// bluegrey: string;
		default: string;
		label: string;
		menu: string;
		menuRed: string;
		lightgrey: string;
		grey: string;
		darkGrey: string;
		darkestGrey: string;
	}

	interface Palette {
		green: Palette["primary"];
		purple: Palette["primary"];
		yellow: Palette["primary"];
		infoGrey: Palette["primary"];
		border: Palette["primary"];
		boxShadow: Palette["primary"];
		text: TypeText;
		background: TypeBackground;
	}

	interface PaletteOptions {
		green: PaletteOptions["primary"];
		purple: PaletteOptions["primary"];
		yellow: PaletteOptions["primary"];
		infoGrey: Palette["primary"];
		border: PaletteOptions["primary"];
		boxShadow: PaletteOptions["primary"];
		text?: Partial<TypeText>;
		background?: Partial<TypeBackground>;
	}
}

// Extend color prop on components
declare module "@mui/material/Chip" {
	export interface ChipPropsColorOverrides {
		purple: true;
		yellow: true;
		green: true;
		infoGrey: true;
	}
}

declare module "@mui/material/Button" {
	interface ButtonPropsVariantOverrides {
		orange: true;
		grey: true;
		primaryTable: true;
		secondaryTable: true;
		containedPrimary: true;
		containedSecondary: true;
		changeView: true;
		outlinedGrey: true;
	}
}

declare module "@mui/material/styles" {
	interface TypographyVariants {
		subtitle3: React.CSSProperties;
		subtitle4: React.CSSProperties;
		body: React.CSSProperties;
		body0: React.CSSProperties;
		bodyMedium: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		subtitle3?: React.CSSProperties;
		subtitle4?: React.CSSProperties;
		body: React.CSSProperties;
		body0: React.CSSProperties;
		bodyMedium: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		subtitle3: true;
		subtitle4: true;
		body: true;
		body0: true;
		bodyMedium: true;
	}
}

declare module "@mui/material/styles" {
	interface BreakpointOverrides {
		xxs: true;
		xs: true;
		sm: true;
		md: true;
		lg: true;
		xl: true;
	
	}
}

export const theme: Theme = createTheme(

	{
		breakpoints: {
			values: {
				xxs: 280,
				xs: 520,
				sm: 600,
				md: 800,
				lg: 1200,
				xl: 1536,
			},
		},

		palette: {
			action: {
				hover: colors.background.bluegrey,
				disabled: colors.disabledText,
			},
			error: {
				contrastText: "#F64E60",
				main: "#FFE2E5",
			},
			green: {
				contrastText: "#42C7BF",
				main: "#C9F7F5",
			},
			info: {
				// main: "#E1F0FF",
				// contrastText: "#3699FF",
				light: colors.text.grey,
				contrastText: "#B5B5C3",
				main: "#B5B5C3",
			},
			infoGrey: {
				light: "#B5B5C3",
				contrastText: "#787B84",
				main: "#F3F6F9",
				dark: "#B5B5C3",
			},
			secondary: {
				main: "#E1F0FF",
				dark: "#C1DFFC",
			},
			success: {
				contrastText: "#C9F7F5",
				main: "#42C7BF",
			},
			primary: {
				main: "#3699FF",
				dark: "#187DE4",
			},
			purple: {
				main: "#EEE5FF",
				contrastText : "#8950FC",
			},
			warning: {
				contrastText: "#FFF4DE",
				main: "#FFA800",
			},
			yellow: {
				main: "#FFF4DE",
				contrastText : "#FFA800",
			},
		
			background: {
				default: colors.background.body,
				label: colors.background.label,
				menu: colors.background.menu,
				menuRed: colors.background.menuRed,
				grey: colors.background.grey,
				lightgrey: colors.background.lightgrey,
				darkestGrey: colors.background.darkestGrey,
				darkGrey: colors.background.darkGrey,
			},

			border: {
				light: colors.border.light,
				main: colors.border.main,
				dark: colors.border.dark,
			},

			boxShadow: {
				main: colors.border.boxShadow,
				dark: colors.border.boxShadowLight,
			},

			text: {
				// dublett
				darkestGrey: colors.text.darkestGrey,
				grey: colors.text.grey,
				lighterGrey: colors.text.lighterGrey,
				lightestGrey: "#f5f5f5",
				darkGrey: colors.text.darkGrey,
				primary: colors.text.darkestGrey,
				// warning: "#ffb822",
				// blue: "#3699FF",
			},
		},

		typography: {
			fontFamily: "Poppins",
			// fontFamily: ['"Poppins"', '"Merriweather"'].join(','),

			fontSize: 14,
			// fontWeightMedium: 500,
			// font medium: 500, normal/regular: 400, light: 300
			h1: {
				fontSize: 16.5,
				fontWeight: "500",
				color: colors.text.darkestGrey,
			},
			h2: {
				fontSize: 16.5,
				fontWeight: "500",
				color: colors.text.darkGrey,
			},
			h3: {
				fontSize: 14,
				fontWeight: "500",
				color: colors.text.darkestGrey,
			},
			h4: {
				fontSize: 12,
				fontWeight: "500",
				color: colors.text.darkGrey,
			},
			h5: {
				fontSize: 24,
				fontWeight: "500",
				color: colors.text.darkestGrey,
			},
			body0: {
				fontSize: 14,
				fontWeight: "400",
				color: colors.text.darkestGrey,
			},
			body: {
				fontSize: 13,
				fontWeight: "600",
				color: colors.text.darkestGrey,
			},
			bodyMedium: {
				fontSize: 13,
				fontWeight: "600",
				color: colors.text.darkestGrey,
			},
			body1: {
				fontSize: 16,
				fontWeight: "300",
				color: colors.text.darkestGrey,
			},
			body2: {
				fontSize: 15,
				fontWeight: "400",
				color: colors.text.darkestGrey,
			},
			subtitle1: {
				color: colors.text.grey,
				fontSize: 14,
				fontWeight: "500",
			},
			subtitle2: {
				color: colors.text.grey,
				fontSize: 13,
				fontWeight: "400",
			},
			subtitle3: {
				color: colors.text.darkGrey,
				fontSize: 13,
				fontWeight: "400",
			},
			subtitle4: {
				color: colors.text.darkestGrey,
				fontSize: 16,
				fontWeight: "500",
			},
		},

		components: {
			// MuiCssBaseline: {
			//   styleOverrides:

			//   fontSize: 13px,
			// },
			// },

			MuiButtonBase: {
				defaultProps: {
					disableRipple: true, // No more ripple, on the whole application
				},
			},
			MuiAlert: {
				styleOverrides: {
					root: {},
					outlinedInfo: {
						border: "1px solid",
						borderLeft: "5px solid",
						borderColor: colors.info2,
					},
					outlinedWarning: {
						border: "1px solid",
						borderLeft: "5px solid",
						borderColor: colors.warning2,
					},
					outlinedError: {
						border: "1px solid",
						borderLeft: "5px solid",
						borderColor: colors.error2,
					},
				},
			},
			MuiAutocomplete: {
				styleOverrides: {
					root: {
						"& input::placeholder": {
							fontWeight: 500,
						},
					},
					option: {
						fontSize: 13,
					},
					listbox: {},
					paper: {
						borderRadius: "6px !important",
					},
				},
			},
			MuiAvatar: {
				styleOverrides: {
					root: {},
					square: {
						borderRadius: 6,
						backgroundColor: colors.secondary,
						color: colors.primary,
					},
					circular: {},
				},
			},

			MuiButton: {
				styleOverrides: {
					root: {
						fontSize: 14,
						textTransform: "none",
						boxShadow: "none",
						overflow: "hidden",
						whiteSpace: "nowrap",
						height: 38,
						lineHeight: 0,
						display: "flex",
						fontWeight: 600,
						borderRadius: 6,
						// padding: "10 13 7 10",
						"&.MuiButton-sizeSmall": {
							height: 34,
							fontWeight: 600,
						},

						"&.Mui-disabled": {
							background: colors.disabledBackground,
							color: colors.disabledText,
						},
					},

					// iconSizeMedium: {
					// 	marginTop: -2,
					// },

					containedPrimary: {
						backgroundColor: colors.primary,
						color: "#fff",
						"&:hover": {
							backgroundColor: colors.buttons.primaryHover,
						},
					},

					containedSecondary: {
						backgroundColor: colors.secondary,
						color: colors.primary,
						"&:hover": {
							backgroundColor: colors.buttons.secondaryHover,
						},
					},
				},
				variants: [
					{
						props: { variant: "primaryTable" },
						style: {
							backgroundColor: colors.primary,
							color: "#fff",
							fontWeight: 600,
							height: 34,
							"&:hover": {
								backgroundColor: colors.buttons.primaryHover,
							},
						},
					},
					{
						props: { variant: "secondaryTable" },
						style: {
							backgroundColor: colors.secondary,
							color: colors.primary,
							fontWeight: 600,
							height: 34,
							"&:hover": {
								backgroundColor: colors.buttons.secondaryHover,
							},
						},
					},
					{
						props: { variant: "orange" },
						style: {
							fontSize: 13,
							height: 34,
							backgroundColor: colors.buttons.ackordswebben,
							color: "#fff",
							"&:hover": {
								backgroundColor: colors.buttons.ackordswebbenHover,
							},
						},
					},
					{
						props: { variant: "grey" },
						style: {
							fontSize: 13,
							background: colors.buttons.grey,
							color: colors.text.darkestGrey,
							"&:hover": {
								backgroundColor: colors.text.grey,
							},
						},
					},
					{
						props: { variant: "outlinedGrey" },
						style: {
							fontSize: 13,
							background: 'transparent',
							color: colors.primary,
							fontWeight: 400,
							border: `1px solid ${colors.border.main}`,
							"&:hover": {
								backgroundColor: colors.text.lightestGrey,
							},
						},
					},
					{
						props: { variant: "changeView" },
						style: {
							height: 50,
							margin: 0.5,
							paddingX: 2,
							background: `${colors.background.lightgrey}`,
							color: `${colors.primary}`,
							border: "1px solid transparent",
							":hover": {
								border: "1px solid",
								borderColor: `${colors.primary}`,
								background: `${colors.background.lightgrey}`,
							},
							":focus": {
								border: "1px solid",
								borderColor: `${colors.primary}`,
								background: `${colors.background.lightgrey}`,
							},
							":active": {
								border: "1px solid",
								borderColor: `${colors.primary}`,
								background: `${colors.background.lightgrey}`,
							},
							"&.Mui-focusVisible": {
								border: "1px solid",
								borderColor: `${colors.primary}`,
								background: `${colors.background.lightgrey}`,
							},
						},
					},
				],
			},

			MuiCard: {
				styleOverrides: {
					root: {
						color: "rgba(0, 0, 0, 0.87)",
					},				
				},
			},

			// footer
			MuiCardActions: {
				styleOverrides: {
					root: {
						justifyContent: "flex-end",
						padding: "20px 20px",
						alignItems: "center",
						margin: 0,
					},
				},
			},

			MuiCardHeader: {
				styleOverrides: {
					root: {
						padding: "0px 28px",
						display: "flex",
						flexWrap: "wrap",
						alignItems: "center",

						"@media (max-width: 600px)": {
							padding: 16,
						},
					},
					content: {
						paddingTop: 3,
						flex: 1,
					},

					avatar: {
						marginRight: "13px !important",
					},

					action: {
						minHeight: 22,
						display: "flex",
						margin: "21px 0 21px 0",
						"@media (max-width: 600px)": {
							width: "100%",
							margin: "21px 0 0 0",
						},
					},
				},
			},

			MuiCheckbox: {
				styleOverrides: {
					root: {
						padding: 7,
						margin: 0,
						width: 18,
						height: 18,
						borderRadius: 4,
						border: "2px solid",
						borderColor: colors.border.light,
						backgroundColor: "white",
						// "input:disabled": {
						// background: colors.background.label,
						// },

						position: "relative",
						content: "''",
						display: "inline-block",
						verticalAlign: "center",

						disableRipple: true,
						"&:hover": {
							backgroundColor: "white",
						},

						"&.Mui-checked": {
							border: "none",
							backgroundColor: colors.primary,
							display: "block",
							width: 18,
							height: 18,
							backgroundImage: `url(${checkIcon})`,
							backgroundRepeat: "no-repeat",
							backgroundPosition: "center",
							content: "''",
							borderRadius: 4,
						},
						".MuiSvgIcon-root": {
							display: "none !important",
						},
						"&.Mui-disabled": {
							background: colors.secondary,
							color: colors.disabledText,
							border: colors.disabledText,

						},
						"&.Mui-checked.Mui-disabled": {
							backgroundImage: `url(${checkIconDisabled})`,
							backgroundRepeat: "no-repeat",
							backgroundPosition: "center",
						},
						".tableRowError": {
							borderColor: colors.error2,
						},
					},
				},
			},

			MuiChip: {
				styleOverrides: {
					root: {
						fontSize: 13,
						borderRadius: 7,
						"& .MuiChip-labelSmall": {
							whiteSpace: "wrap",
						},
					},
					sizeSmall: {
						padding: 2,
						fontWeight: 400,
						minHeight: 24,
						height: "auto",
					},
					sizeMedium: {
						height: 40,
						borderRadius: 6,
						fontWeight: 300,
						backgroundColor: colors.background.bluegrey,
						color: colors.primary,
					},
				},
			},

			MuiDivider: {
				styleOverrides: {
					root: {
						borderColor: colors.border.main,
						margin: 0,
					},
				},
			},

			MuiIconButton: {
				styleOverrides: {
					root: {
						borderRadius: 6,
						"&:disabled": {
							backgroundColor: colors.disabledBackground,
							color: colors.disabledText,
						},
					},
					sizeSmall: {
						height: 34,
						width: 34,
					},
					sizeMedium: {
						height: 38,
						width: 38,
					},
					colorPrimary: {
						backgroundColor: colors.background.label,

						"&:hover": {
							backgroundColor: colors.secondary,
						},
					},
					colorSecondary: {
						color: colors.primary,
						backgroundColor: colors.secondary,
						"&:hover": {
							backgroundColor: colors.buttons.secondaryHover,
						},
					},
				},
			},

			MuiInputAdornment: {
				styleOverrides: {
					root: {},

					positionEnd: {
						paddingRight: 5,
						paddingBottom: 2,
						borderTopLeftRadius: 3,
						borderBottomLeftRadius: 3,
					},
				},
			},

			MuiLink: {
				styleOverrides: {
					root: {
						textDecoration: "none",
					},
				},
			},
			MuiListItemText: {
				styleOverrides: {
					root: {
						fontSize: 13,
					},
					primary: {
						fontWeight: 500,
						fontSize: 13,
					},
					secondary: {
						fontSize: 13,
					},
				},
			},

			MuiMenu: {
				styleOverrides: {
					root: {
						"& .MuiPaper-root": {
							borderRadius: 6,
							minWidth: 193,
							boxShadow: `0px 0px 15px ${"#523F6926"}`,
							"& .MuiMenu-list": {
								padding: "16px 0",
							},
							"& .MuiMenuItem-root": {
								fontWeight: 400,
								fontSize: 14,
								padding: "10px 20px",
							},
							"& .MuiListItemIcon-root": {
								display: "flex",
								justifyContent: "center",
								width: 13,
								marginRight: 12,
								minWidth: 20,
							},
						},
					},
				},
			},

			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						width: "100%",
						fontWeight:400,
						fontSize: 14,
						height: 38,
						borderRadius: 6,
						padding: 0,

						"&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
							borderColor: `${colors.border.light} !important`,
							border: "1px solid",
						},

						"& input::placeholder": {
							fontWeight: 500,
						},
					
						"& .MuiOutlinedInput-notchedOutline": {
							borderColor: `${colors.border.light} `,
						},

						"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
							borderColor: `${colors.border.light} !important`,
							border: "1px solid",
						},
						
						"&:hover .MuiOutlinedInput-notchedOutline": {
							borderColor: colors.border.light,
						},

						"& .MuiInputBase-input": {
							placeholder: colors.text.grey,
							padding: "10px 12px 9px 12px",

							"&.Mui-disabled": {
								background: colors.background.disable,
								borderRadius: 6,
								// borderColor:'red !important',
							},
						},

						"&.MuiInputBase-sizeSmall": {
							height: 25,
							width: 75,
							borderRadius: 3,
							fontSize: 10,

							"& .MuiInputBase-input": {
								padding: "5px 5px 4px 5px",

								"&.Mui-disabled": {
									background: colors.background.disable,
									padding: "4.3px 5px 4.3px 5px",
									borderRadius: 2,
								},
							},

							"& .MuiInputBase-inputAdornedEnd": {
								borderRight: "1px solid",
								borderColor: colors.border.light,
								marginRight: -3,

								"&.Mui-disabled": {
									borderRadius: 0,
									borderTopLeftRadius: 2,
									borderBottomLeftRadius: 2,
									borderColor: colors.border.light,
								},
							},
						},
					},
				},
			},

			MuiRadio:{	
				styleOverrides: {
					root: {
						":hover": {backgroundColor:'inherit'}, 
					},
				},
			},

			MuiSvgIcon: {
				styleOverrides: {
					root: {},
					fontSizeSmall: {
						fontSize: 16,
					},
					colorError: {
						color: colors.error2,
					},
					// colorInfo: {
					// 		// color:colors.background.grey
					// 		color:colors.error2
					// 	},
				},
			},

			MuiTab: {
				styleOverrides: {
					root: {
						"& svg": {
							marginBottom: "0 !important",
						},
						indicator: {
							top: 40,
						},
					},
				},
			},

			// MuiTabs: {
			// 	styleOverrides: {
			// 		root: {
			// 		},
			// 		indicator:{
			// 			top: 35,
			// 		}
			// 	},
			// },

			MuiTablePagination: {
				styleOverrides: {
					root: {
						// color: colors.text.primary,
						padding: "16px 0 0 0",
						borderRadius: 6,

						"& .MuiSvgIcon-root": {
							color: colors.primary,
						},
						"& .MuiInputBase-root": {
							margin: "0 16px 0 0",
						},

						"& .Mui-focused": {
							borderColor: `${colors.border.light} !important`,
							border: "1px solid red",
						},

						"& .MuiIconButton-root": {
							disabled: {
								backgroundColor: "black",
							},
						},
					},

					toolbar: {
						padding: "0 !important",
					},

					selectLabel: {
						fontSize: 14,
						marginRight: 8,
					},

					actions: {
						order: 2,
						marginLeft: 12,
						"& .Mui-disabled": {
							backgroundColor: "transparent",
							svg: {
								color: "lightgrey",
							},
						},
					},

					select: {
						color: colors.primary,
						backgroundColor: `${colors.secondary} !important`,
						fontWeight: 500,
						borderRadius: "6px !important",
						order: 2,
						alignItems: "center",
						display: "flex",
						justifyContent: "center",
						padding: "8px 20px 8px 10px",
					},

					displayedRows: {
						fontSize: 13,
						fontWeight: 300,
						order: 2,
					},
				},
			},

			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						backgroundColor: "white",
						color: colors.text.darkestGrey,
						fontWeight: 300,
						padding: "15px 22px",
						boxShadow: `0px 0px 16px ${colors.border.boxShadow}`,
						fontSize: "13px",
						maxWidth: "none",
					},
					arrow: {
						color: "white",
					},
				},
			},

			// DATA TABLES AND CELLS
			MuiTableRow: {
				styleOverrides: {
					root: {
						justifyContent: "space-between",
						display: "flex",
						borderSpacing: 10,
						border: "none",
						borderBottom: "1px solid",
						borderColor: colors.border.main,
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						border: "none",
						"&.MuiTableCell-head": {
							color: colors.text.grey,
							fontWeight: 600,
							paddingLeft: 0,
						},
						"&.MuiTableCell-body": {
							color: colors.text.darkestGrey,
							fontWeight: 600,
							alignSelf: "center",
							paddingRight: 0,
							wordBreak: "break-word",
							textAlign: "right",
						},
					},
				},
			},

			MuiDataGrid: {
				styleOverrides: {
					root: {
						fontSize: 13,
						fontWeight: 400,
						border: "none",
						".tableRowDisabled":{
							backgroundColor:  `${colors.background.grey} !important`,
						},
						".tableRowDisabled span": {
							opacity: 0.5,
						},

						"& .MuiDataGrid-columnSeparator": {
							visibility: "hidden !important",
						},

						"& .MuiDataGrid-cellCheckbox, .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader--sortable":
							{
								outline: "none !important",
							},

						// show all rows
						"& .MuiDataGrid-virtualScrollerRenderZone": {
							position: "relative",
							height: "auto !important",
						},

						"& .MuiDataGrid-virtualScrollerContent": {
							position: "relative",
							height: "auto !important",
						},

						"& .MuiTablePagination-root *": {
							marginTop:0,
							marginBottom:0,
							height:'auto',
							outline:'none !important',
						},
						"& .MuiTablePagination-root": {
							marginTop:26,
							"& .MuiTablePagination-selectLabel ":{
								fontSize: 13,
								paddingLeft: 2
							},
							"& .Mui-focused":{
								border:'none',
							}
						},
					},
					row: {
						"&:hover": {
							backgroundColor: `${colors.background.label}!important`,
						},
					},

					columnHeaders: {
						"& .MuiDataGrid-iconButtonContainer .MuiIconButton-root": {
							position: "relative",
							background: "transparent",
							visibility: "visible",
						},
					},

					columnHeaderTitle: {
						fontWeight: 600,
						color: colors.text.grey,
						fontSize: 12,
						textTransform: "uppercase",
						letterSpacing: 1.2
					},

					cell: {
						borderColor: colors.border.main,
					},

					cellContent: {
						fontWeight: 400,
						padding: 0,
					},

					footerContainer: {
						justifyContent: "start",
						minHeight:'auto',
					},

					paper: {
						fontSize: 13,
						boxShadow: `0px 0px 16px ${colors.border.boxShadow}`,
						borderRadius: 6,
					},
				},
			},
		},
	},
	svSE,
);

export default theme;
