import React, { useState, useEffect } from "react";
import theme from "styles/theme";
import colors from "styles/colors-theme";
import {
	Box,
	Button,
	DialogContent,
	DialogTitle,
	Stack,
	Typography,
	useMediaQuery,
	DialogActions,
	CircularProgress,
	Divider,
} from "@mui/material";

import {
	useGetPreviewAgreementTemplateLetterMutation,
	useLazyGetPreviewAgreementTemplateMailQuery,
	useLazyGetPreviewDispatchListMailQuery,
	useGetPreviewDispatchListLetterMutation,
	useGetAgreementTypeTemplateQuery,
} from "api/Api";

import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

import { DialogStyled } from "./styles/ModalDialogPreviewStyles";
import {
	IGetPreviewAgreementTemplateMail,
	IGetPreviewDispatchListMail,
} from "types/templates/CommonTemplateTypes";

import LoadingButton from "components/common/loading/LoadingButton";

export interface DialogProps {
	isVisible: boolean;
	minHeight?: string;
	showModal: React.Dispatch<React.SetStateAction<boolean>>;
	role?: 1 | 2;
	agreementTypeId?: string;
	bodyText?: string;
	dispatchListId?: string;
}

const ModalDialogPreview: React.FC<DialogProps> = ({
	isVisible,
	showModal,
	role,
	agreementTypeId,
	bodyText,
	dispatchListId,
}) => {
	// if preview comes from DispatchPreviewButton, role is sent through props, since role is not shown in url
	const recipientType =
		role === 1 || role === 2 ? role : location.pathname.includes("/mallar/ombud-ffv/") ? 2 : 1;

	const [isLoading, setIsloading] = useState<boolean>(false);
	const [previewEmail, setPreviewEmail] = useState<any>({
		disabled: true,
		mail: "recipientType",
	});
	const [showTemplate, setShowTemplate] = useState<number>(10);

	const [lazygetPreviewAgreementTemplateMailQuery, resultsPreviewMail] =
		useLazyGetPreviewAgreementTemplateMailQuery();

	const [lazyGetPreviewDispatchListMailQuery, resultsPreviewMailDispatchList] =
		useLazyGetPreviewDispatchListMailQuery();

	const [
		getPreviewAgreementTemplateLetter,
		{ isLoading: isLoadingPreviewLetterAgreementTemplate },
	] = useGetPreviewAgreementTemplateLetterMutation();

	const [getPreviewDispatchListLetter, { isLoading: isLoadingPreviewLetterDispatchList }] =
		useGetPreviewDispatchListLetterMutation();

	const { data: agreementData } = useGetAgreementTypeTemplateQuery({
		form: {
			agreementTypeId: agreementTypeId || "",
			recipientType: recipientType,
		},
	});

	useEffect(() => {
		if (resultsPreviewMail && resultsPreviewMail.status == "fulfilled") {
			setPreviewEmail({
				disabled: false,
				mail: resultsPreviewMail.data,
			});
			setIsloading(false);
		}
		if (resultsPreviewMail && resultsPreviewMail.status == "rejected") {
			setPreviewEmail({
				disabled: true,
				mail: "",
			});
			setIsloading(false);
		}
	}, [resultsPreviewMail]);

	useEffect(() => {
		if (resultsPreviewMailDispatchList && resultsPreviewMailDispatchList.status == "fulfilled") {
			setPreviewEmail({
				disabled: false,
				mail: resultsPreviewMailDispatchList.data,
			});
			setIsloading(false);
		}
		if (resultsPreviewMailDispatchList && resultsPreviewMailDispatchList.status == "rejected") {
			setPreviewEmail({
				disabled: true,
				mail: "",
			});
			setIsloading(false);
		}
	}, [resultsPreviewMailDispatchList]);

	// preview mail
	useEffect(() => {
		setIsloading(true);
		// there is no bodyText if coming from the button in the dispatchlist
		if (!bodyText && bodyText != "") {
			if (agreementTypeId && dispatchListId) {
				const formPreviewMailDispatchList: IGetPreviewDispatchListMail = {
					dispatchListId: dispatchListId || "",
					recipientType: recipientType,
					dispatchType: showTemplate,
				};
				lazyGetPreviewDispatchListMailQuery(formPreviewMailDispatchList);
			}
		} else {
			const formPreviewMailAgreementTemplate: IGetPreviewAgreementTemplateMail = {
				agreementText: bodyText && bodyText.length > 0 ? bodyText : "",
				recipientType: recipientType,
				dispatchType: showTemplate,
			};
			lazygetPreviewAgreementTemplateMailQuery(formPreviewMailAgreementTemplate);
		}
	}, [showTemplate, bodyText, role]);

	const closeModal = () => {
		setShowTemplate(10);
		showModal(false);
	};

	const previewLetter = () => {
		// there is no bodyText if coming from the button in the dispatchlist
		if (!bodyText && bodyText != "") {
			if (agreementTypeId) {
				const formPrevieLetterDispatchList = {
					fileName: `Förhandsgranskning av ${agreementData?.agreementTypeName || ""}.pdf`,
					dispatchListId: dispatchListId || "",
					recipientType: recipientType,
					dispatchType: showTemplate,
				};
				getPreviewDispatchListLetter(formPrevieLetterDispatchList);
			}
		} else {
			const formPrevieLetterAgreementTemplate = {
				fileName: `Förhandsgranskning av ${agreementData?.agreementTypeName || ""}.pdf`,
				agreementTypeId: agreementTypeId || "",
				agreementText: bodyText || "",
				recipientType: recipientType,
				dispatchType: showTemplate,
			};
			getPreviewAgreementTemplateLetter(formPrevieLetterAgreementTemplate);
		}
	};

	const showDialogContent = () => {
		return (
			<Box
				flexDirection={"column"}
				sx={{ height: "100%", minHeight: previewEmail.disabled ? 0 : 300 }}
			>
				{isLoading ? (
					<CircularProgress sx={{ margin: "auto", marginTop: 15 }} />
				) : (
					<Box
						sx={{
							backgroundColor: "white",
							height: "100%",
							fontFamily: "Arial",
						}}
						width={{ xs: "100%", lg: "600px" }}
					>
						{previewEmail.disabled ? (
							<Typography>Kan inte förhandsgranska då malltext saknas.</Typography>
						) : (
							<div dangerouslySetInnerHTML={{ __html: previewEmail.mail || "" }} />
						)}
					</Box>
				)}
			</Box>
		);
	};

	const Header = () => {
		return (
			<DialogTitle
				sx={{
					display: "flex",
					flexWrap: "wrap",
					alignItems: "center",
				}}
			>
				<Stack
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={{ xxs: "left", sm: "center" }}
					direction={{ xxs: "column", md: "row" }}
					width={"100%"}
				>
					<Stack direction={"row"} alignItems={"center"}>
						<Box alignItems={"center"} display={"flex"} mr={"16px"} py={2}>
							<SearchIcon />
						</Box>
						<Stack direction={"column"}>
							<Typography variant='body0'>Förhandsgranskar:</Typography>
							<Typography sx={{ fontWeight: 500 }}>{`${
								recipientType === 2 ? "Förtroendevald" : "Arbetsgivare"
							} - 
							${agreementData?.agreementTypeName || ""} `}</Typography>
						</Stack>
					</Stack>
					<Stack direction={{ xxs: "column", sm: "row" }} spacing={1} mt={{ xxs: 1 }}>
						<Button
							sx={{
								borderColor: showTemplate === 10 ? colors.primary : "transparent",
							}}
							size='small'
							variant='changeView'
							onClick={() => setShowTemplate(10)}
						>
							Första utskick
						</Button>
						<Button
							sx={{ borderColor: showTemplate === 20 ? colors.primary : "transparent" }}
							size='small'
							variant='changeView'
							onClick={() => setShowTemplate(20)}
						>
							Påminnelse 1
						</Button>
						<Button
							sx={{ borderColor: showTemplate === 30 ? colors.primary : "transparent" }}
							size='small'
							variant='changeView'
							onClick={() => setShowTemplate(30)}
						>
							Påminnelse 2
						</Button>
					</Stack>
				</Stack>
				<Stack width={"100%"}>
					<Divider
						sx={{ pt: 2, borderColor: colors.border.light, mx: { md: "-20px" } }}
						orientation='horizontal'
					/>
					{isLoadingPreviewLetterAgreementTemplate || isLoadingPreviewLetterDispatchList ? (
						<Box sx={{ justifySelf: "flex-end", ml: "auto", mb: 2, mt: 3 }}>
							<LoadingButton size='small' label={"Laddar ner pdf"} />
						</Box>
					) : (
						<Button
							size='small'
							variant='containedPrimary'
							sx={{ justifySelf: "flex-end", ml: "auto", mb: 2, mt: 3 }}
							onClick={() => previewLetter()}
							disabled={previewEmail.disabled}
						>
							Visa brevutskick (pdf)
						</Button>
					)}
				</Stack>
			</DialogTitle>
		);
	};

	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<DialogStyled
			onClose={() => showModal(true)}
			aria-labelledby='customized-dialog-title'
			open={isVisible}
			fullScreen={fullScreen}
		>
			<Header />
			<DialogContent
				sx={{
					backgroundColor: previewEmail.disabled ? "white" : colors.background.grey,
					display: "flex",
					justifyContent: "center",
					alignContent: "center",
					padding: { xs: "10px!important", md: "40px !important" },
					width: { lg: "1000px" },

					height: "100%",
				}}
			>
				{showDialogContent()}
			</DialogContent>

			<DialogActions>
				<Button aria-label='Avbryt' variant='grey' onClick={closeModal} sx={{ paddingX: 3 }}>
					Stäng
				</Button>
			</DialogActions>
		</DialogStyled>
	);
};
export default ModalDialogPreview;
