export interface IGetRolesData {
	roleName: string;
	roleLevel: number;
}

export interface IRole {
	roleName: string;
	uiRoleName: string;
}

export const sysAdmin: IRole = {
	roleName: "system-admin",
	uiRoleName: "System Admin",
};
export const administrator: IRole = {
	roleName: "administrator",
	uiRoleName: "Administratör",
};

export const roles: IRole[] = [
	sysAdmin,
	administrator,
	{
		roleName: "non-admin",
		uiRoleName: "Förtroendevald",
	},
];
