import React, { useEffect, useState } from "react";
import {
	Box,
	styled,
	Typography,
	Tab,
	Tabs,
	Divider,
	IconButton,
	Paper,
	Stack,
	Button,
	DialogActions,
	CircularProgress,
	DialogContent,
	useMediaQuery,
} from "@mui/material";
import {
	useGetMembersByWorkplaceQuery,
	useGetUpdateRecipientsForSalaryReportMutation,
	useGetByIdQuery,
	useGetUserFromDirectoryQuery,
} from "api/Api";
import { useLocation } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import AgentSelect from "./AgentSelect";
import MemberSearch from "./MemberSearch";
import ClearIcon from "@mui/icons-material/Clear";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SaveIcon from "@mui/icons-material/Save";
import {
	TabPanelProps,
	IEditAgentModal,
	IAgent,
	IUpdateRecipientsForSalaryReport,
} from "types/dispatch/dispatchListTable/SelectAgentModal";
import { ISalaryReport } from "types/dispatch/dispatchListTable/DispatchListTableTypes";
import LoadingButton from "components/common/loading/LoadingButton";
import theme from "styles/theme";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";

const PaperStyled = styled(Paper)(({ theme }) => ({
	border: "2px solid #DFEFFF",
	margin: "0 0 10px",
	maxHeight: "44px",
	borderRadius: "11px",
	padding: "13px 16px 12px 16px",
	boxShadow: "none",
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	width: "100%",
})) as typeof Paper;

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const EditFortroendevaldModal: React.FC<IEditAgentModal> = ({
	agentName,
	salaryReportId,
	workplaceId,
	closeAgentModal,
}) => {
	// fetch data
	const { state } = useLocation();
	const { dispatchListIdFromState } = state || "";
	const dispatchListIdFromUrl = LastSegmentOfUrl(location.href);
	const dispatchListId = dispatchListIdFromState ? dispatchListIdFromState : dispatchListIdFromUrl;

	const { data: user } = useGetUserFromDirectoryQuery(null);

	const { data: currentAgentsData, refetch: refreshCurrentAgentsData } =
		useGetByIdQuery(dispatchListId);
	const {
		data: membersByWorkplaceData,
		isSuccess: isSuccessMembersByWorkplace,
		isLoading: isLoadingMembersByWorkplace,
		refetch: refreshMembersByWorkplaceData,
	} = useGetMembersByWorkplaceQuery(workplaceId || "");

	// update list
	const [getUpdateRecipientsForSalaryReport, { isLoading: isLoadingUpdateRecipients }] =
		useGetUpdateRecipientsForSalaryReportMutation();

	// tab values
	const [value, setValue] = useState(0);

	const [selectedAgents, setSelectedAgents] = useState<IAgent[]>([]);
	const [membersByWorkplace, setMembersByWorkplace] = useState<IAgent[]>([]);

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		// populate array to manipulate
		if (membersByWorkplaceData && isSuccessMembersByWorkplace) {
			if (membersByWorkplaceData.length > 0) {
				setMembersByWorkplace(membersByWorkplaceData);
			}
		}

		// set current agents when modal opens
		const salaryReport = currentAgentsData.salaryReports.filter(
			(r: ISalaryReport) => r.id === salaryReportId,
		);
		const tempList: IAgent[] = [];
		salaryReport[0].representativeRecipients.forEach((recipient: IAgent) => {
			// id should be userId to be able to compare with recipientdata in modal
			const tempObj = { ...recipient };
			tempObj.userId = recipient.userId || "";
			tempList.push(tempObj);
		});
		setSelectedAgents(tempList);
	}, [membersByWorkplaceData]);

	// delete agent from selected list
	const deleteAgent = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, userId: string) => {
		e.preventDefault();
		const newList = selectedAgents
			.filter((agent) => agent.userId != userId)
			.map((agent: IAgent) => ({ ...agent }));
		setSelectedAgents(newList);
	};

	// on save
	const saveAgentSettings = async () => {
		const userIds = selectedAgents.map((a) => a.userId);

		const recipients: IUpdateRecipientsForSalaryReport = {
			salaryReportId: salaryReportId,
			userIds: userIds,
		};

		//	TODO uppdatera response when possible
		await getUpdateRecipientsForSalaryReport(recipients)
			.unwrap()
			.then((response) => {
				enqueueSnackbar("Valda förtroendevalda sparades", { variant: "success" });
				refreshCurrentAgentsData();
				refreshMembersByWorkplaceData();
				closeAgentModal();
			})
			.catch((error) => {
				enqueueSnackbar(error.data.detail, {
					variant: "error",
					persist: true,
				});
			});
	};

	const addSelfToSelectedAgents = () => {
		const tempList = [...selectedAgents];
		const self: IAgent = {
			userId: user.id,
			emails: user.email,
			name: `${user.firstName} ${user.lastName}`,
			nationalIdentityNumber: user.nationalIdentityNumber,
		};

		if (tempList.length > 0) {
			const idx = tempList.findIndex((r) => r.userId == user.id);
			if (idx > -1) {
				tempList.splice(idx, 1);
			} else {
				tempList.push(self);
			}
		} else {
			tempList.push(self);
		}

		setSelectedAgents(tempList);
	};
	const fullScreen = useMediaQuery(theme.breakpoints.up("md"));
	return (
		<>
			<DialogContent
				dividers
				sx={{
					width: fullScreen ? "697px" : "100%",
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "column",
				}}
			>
				<Stack flexDirection='column' justifyContent=' space-between' display='flex' mb={4}>
					<Typography sx={{ marginBottom: "20px" }}>
						Välj en eller flera förtroendevalda för{" "}
						<span style={{ fontWeight: "600" }}>{agentName}</span>
					</Typography>
					<Box
						sx={{
							borderBottom: 1,
							borderColor: "divider",
							width: "100%",
							typography: "body1",
						}}
						mb={4}
					>
						<Tabs value={value} onChange={handleChangeTab} aria-label='meny' variant='scrollable'>
							<Tab label='MEDLEMMAR PÅ ARBETSSTÄLLET' {...a11yProps(0)} />
							<Tab label='MEDLEMSREGISTER' {...a11yProps(1)} />
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						{isLoadingMembersByWorkplace ? (
							<CircularProgress />
						) : membersByWorkplace ? (
							<AgentSelect
								setSelectedAgents={setSelectedAgents}
								rows={membersByWorkplace}
								selectedAgents={selectedAgents}
								placeholder={
									membersByWorkplace && membersByWorkplace.length === 0
										? "Finns inga fler medlemmar på arbetsstället"
										: "Sök på namn eller välj från lista"
								}
							/>
						) : (
							<Typography sx={{ fontStyle: "italic" }}>Kunde inte hämta medlemmar.</Typography>
						)}
					</TabPanel>
					<TabPanel value={value} index={1}>
						<MemberSearch setSelectedAgents={setSelectedAgents} selectedAgents={selectedAgents} />
					</TabPanel>
				</Stack>
				<Stack>
					{selectedAgents && selectedAgents.length > 0 && <Divider flexItem />}
					<Stack mt={4} flexDirection='column-reverse'>
						{selectedAgents && selectedAgents.length > 0
							? selectedAgents.map((row: IAgent) => {
									return (
										<PaperStyled key={row.userId}>
											<IconButton
												sx={{ width: "0px", "&:hover": { backgroundColor: "transparent" } }}
												aria-label='Radera'
												onClick={(e) => deleteAgent(e, row.userId)}
											>
												<ClearIcon fontSize='small' />
											</IconButton>
											<Typography variant='body' sx={{ fontWeight: "600", mx: 2 }}>
												{row.name}{" "}
											</Typography>
										</PaperStyled>
									);
							  })
							: null}
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions>
				{user && (
					<Button
						aria-label='Skapa'
						variant='containedSecondary'
						onClick={addSelfToSelectedAgents}
						startIcon={<PersonAddIcon />}
						sx={{ marginRight: "auto" }}
						disabled={
							user && selectedAgents.findIndex((agent) => agent.userId == user.id) < 0
								? false
								: true
						}
					>
						Lägg till mig
					</Button>
				)}

				<Button variant='grey' onClick={closeAgentModal}>
					Avbryt
				</Button>

				{isLoadingUpdateRecipients ? (
					<LoadingButton label={"Lägger till förtroendevald"} />
				) : (
					<Button
						aria-label='Skapa'
						variant='containedPrimary'
						onClick={saveAgentSettings}
						startIcon={<SaveIcon />}
					>
						Spara
					</Button>
				)}
			</DialogActions>
		</>
	);
};

export default EditFortroendevaldModal;
