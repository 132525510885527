import { styled } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

export const StyledOverviewDataGrid = styled(DataGridPro)(({ theme }) => ({
	border: 0,
	[theme.breakpoints.down("sm")]: {
		margin: 0,
	},

	"&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
		outline: "none",
	},

	"& .MuiDataGrid-virtualScroller": {
		[theme.breakpoints.up("sm")]: {
			overflowX: "hidden !important",
		},
	},
	"& .MuiDataGrid-columnHeaders": {
		border: 0,
		paddingLeft: "10px",
	},

	"& .MuiDataGrid-row": {
		border: "2px solid #DFEFFF",
		margin: "0 0 8px",
		borderRadius: "8px",
		paddingLeft: "10px",
		cursor: "pointer",
		width: "auto",
		"&:nth-of-type(2n)": { backgroundColor: "transparent" },

		"& .MuiDataGrid-cell": {
			border: 0,
			fontSize: 14,
			fontWeight: 500,
		},
		"& .MuiDataGrid-cellContent": {
			"&:first-of-type": {
				fontWeight: 500,
				fontSize: 15,
			},
		},
	},
})) as typeof DataGridPro;
