import * as React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import { ReactComponent as TriangleWarningIcon } from "assets/icons/triangle-warning.svg";
import { ReactComponent as TriangleErrorIcon } from "assets/icons/triangle-error.svg";
import { ReactComponent as TriangleInfoIcon } from "assets/icons/triangle-info.svg";

import { styled } from "@mui/system";

interface AlertProps {
	type: "warning" | "info" | "error" | "success";
	alertText: string;
	actionLink: any;
}

const AlertStyled = styled(Alert)(({ theme }) => ({
	borderRadius: "0px 6px 6px 0px",
	minHeight: 75,
	backgroundColor: theme.palette.background.lightgrey,
	alignItems: "center",
	display: "flex",
	flexDirection: "row",
	padding: "10 10 10 33",
	// color: theme.palette.text.darkestGrey,
	"& .MuiAlert-message": {
		fontSize: 14,
		fontWeight: 300,
		paddingLeft: 15,
	},
	"& svg": {
		fontSize: "40px",
	},
}));

export default function AlertMessage({ alertText, type, actionLink }: AlertProps) {
	return (
		<Stack sx={{ width: "100%" }} spacing={2}>
			<AlertStyled
				variant='outlined'
				severity={type}
				iconMapping={{
					warning: <TriangleWarningIcon />,
					error: <TriangleErrorIcon />,
					info: <TriangleInfoIcon />,
				}}
			>
				{alertText}
				{actionLink}
			</AlertStyled>
		</Stack>
	);
}
