export const getUrl = (): string => {
	const hostName = window.location.hostname;
	if (process.env.NODE_ENV === "development") {
		return window.location.protocol + "//localhost:7150";
	}
	switch (hostName) {
		case 'localhost':
		  return 'https://localhost:7150';
		case 'sef-salaryweb-app-dev.azurewebsites.net':
		  return 'https://sef-salaryweb-dev.azurewebsites.net';
		case 'sef-salaryweb-app-systest.azurewebsites.net':
		  return 'https://sef-salaryweb-systest.azurewebsites.net';
		case 'acctest.lonewebben.se':
		  return 'https://acctest.lonewebben.se/api';
		case 'www.lonewebben.se':
			return 'https://www.lonewebben.se/api';
	
		default:
		  throw "No API-url configured for environment";
	}
};

type AuthHeader = { Authorization: string } | Record<string, never>;

export const authHeader = (): AuthHeader => {
	const userAuth: string = localStorage.getItem("userAuth") ?? "";
	const user: string = JSON.parse(userAuth) as string;
	if (user != null) {
		return { Authorization: "Bearer " + user };
	} else {
		return {};
	}
};

export const addJsonHeader = (header: object): object => {
	return {
		...header,
		"content-type": "application/vnd.api+json",
		Accept: "application/vnd.api+json",
	};
};
