import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Breadcrumbs, Typography, styled } from "@mui/material";
import {
	useLazyGetAgreementTypeTemplatesQuery,
	useLazyGetByIdQuery,
	useLazyGetSalaryReportQuery,
} from "api/Api";
import { IAgreement } from "types/templates/CommonTemplateTypes";
import { ISalaryProtocolWorkplace } from "types/representative/commonSalaryReportTypes";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const BreadcrumbHandler = () => {
	const [dispatchListName, setDispatchListName] = useState<string>();
	const [agreementTypeTemplatesData, setAgreementTypeTemplatesData] = useState<IAgreement[]>([]);
	const [salaryReportWorkplace, setSalaryReportWorkplace] = useState<ISalaryProtocolWorkplace>();
	const [returnUrl, setReturnUrl] = useState<string>("");
	const location = useLocation();
	const navigate = useNavigate();
	const pathnames = location.pathname.split("/").filter((x: string) => x);
	const lastSegmentOfUrl = pathnames.slice(-1)[0];
	const secondLastSegmentOfUrl = pathnames.slice(-2)[0];

	const recipientType =
		pathnames[pathnames.length - 2] == "ombud-ffv"
			? 2
			: pathnames[pathnames.length - 2] == "arbetsgivare"
			? 1
			: null;

	const [getById, results] = useLazyGetByIdQuery();

	const [getAgreementTypeTemplatesQuery, resultsAgreementTypeTemplates] =
		useLazyGetAgreementTypeTemplatesQuery();

	const [useGetSalaryReport] = useLazyGetSalaryReportQuery();

	useEffect(() => {
		if (
			location.pathname == `/utskickslistor/aktuella/${secondLastSegmentOfUrl}/${lastSegmentOfUrl}`
		) {
			setReturnUrl(`/utskickslistor/aktuella/${secondLastSegmentOfUrl}`);
		} else if (
			location.pathname ==
			`/utskickslistor/arkiverade/${secondLastSegmentOfUrl}/${lastSegmentOfUrl}`
		) {
			setReturnUrl(`/utskickslistor/arkiverade/${secondLastSegmentOfUrl}`);
		} else {
			setReturnUrl("");
		}
	}, [location.pathname]);

	useEffect(() => {
		if (pathnames.includes("utskickslistor")) {
			const dispatchListIdFromStorage = localStorage.getItem("dispatchListId");

			// dispatchListIdFromUrl is always index 2 in pathnames
			const dispatchListIdFromUrl = pathnames[2];
			const lastSegmentofUrl = pathnames[pathnames.length - 1];

			// lastSegmentofUrl is "aktuella" if coming from new tab. Use id from session storage instead
			(lastSegmentofUrl == "arkiverade" || lastSegmentofUrl == "aktuella") &&
			dispatchListIdFromStorage
				? getById(dispatchListIdFromStorage)
				: getById(dispatchListIdFromUrl);
		}
	}, []);

	useEffect(() => {
		if (results && results.data) {
			setDispatchListName(results.data.name);
		}
	}, [results]);

	// use lazy to call function based on condition
	useEffect(() => {
		if (recipientType != null) {
			getAgreementTypeTemplatesQuery(recipientType);
		}
		// in view löneprotokoll
		if (
			(pathnames[0] == "aktuella" || pathnames[0] == "historik") &&
			lastSegmentOfUrl?.length > 0
		) {
			useGetSalaryReport(lastSegmentOfUrl).then((result) => {
				if (result && result.data) {
					if (result.data.workplace) {
						const year = result.data.salaryRevision.date.substring(0, 4);
						const workplace = result.data.workplace;
						const workplaceWithYear = { ...workplace };
						workplaceWithYear.year = year;
						setSalaryReportWorkplace(workplaceWithYear);
					}
				}
			});
		}
	}, [lastSegmentOfUrl]);

	useEffect(() => {
		if (resultsAgreementTypeTemplates && resultsAgreementTypeTemplates.data) {
			setAgreementTypeTemplatesData(resultsAgreementTypeTemplates.data);
		}
	}, [resultsAgreementTypeTemplates]);

	const getPageName = (path: string) => {
		const regex = /^[\w-]+$/;
		if (regex.test(path) && path.includes("-")) {
			if (pathnames.includes("utskickslistor")) {
				if (dispatchListName) {
					if (dispatchListName) {
						return dispatchListName;
					}
				}
			} else {
				if (path.includes("ombud-ffv")) {
					return "Förtroendevald";
				}
				if (agreementTypeTemplatesData && agreementTypeTemplatesData.length > 0) {
					const avtal = agreementTypeTemplatesData.filter(
						(a: IAgreement) => a.agreementTypeId == pathnames[pathnames.length - 1],
					);
					if (avtal[0].agreementTypeName) {
						return avtal[0].agreementTypeName;
					}
				}
				if (salaryReportWorkplace) {
					return `${salaryReportWorkplace.name}(${salaryReportWorkplace.departmentId})`;
				}
			}
		} else {
			const url = path.charAt(0).toUpperCase() + path.slice(1);
			if (url == "Loneprotokoll") return "Löneprotokoll";
			return url;
		}
	};

	const urlSlicer = (key: number): string => {
		let url = "/";
		for (let i = 0; i < pathnames.length; i++) {
			const p = pathnames[i];
			if (key == i - 1) break;
			// Removes Löneprotokoll from url
			else if (p.includes("Löneprotokoll")) {
				url += p.substring(p.length - 4, p.length);
			} else {
				url += p + "/";
			}
		}
		return url;
	};

	// Adds Löneprotokoll to pathname if there is a year
	const yearRegex = /^[0-9]{4}$/;
	for (let i = 0; i < pathnames.length; i++) {
		if (yearRegex.test(pathnames[i])) {
			pathnames[i] = `Löneprotokoll ${pathnames[i]}`;
		}
	}

	const Crumb = styled(Typography)(() => ({
		fontSize: 13,
		fontWeight: 400,
		"&.hover:hover": {
			cursor: "pointer",
			textDecoration: "underline",
		},
	}));

	return (
		<Breadcrumbs sx={{ fontSize: 13, fontWeight: 400 }}>
			{returnUrl != "" ? (
				<Crumb
					color='#3699FF'
					onClick={() => navigate(-1)}
					sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 500 }}
					className='hover'
				>
					<ChevronLeftIcon fontSize='medium' sx={{ marginRight: "4px" }} />
					{location?.state?.fromDuplicates ?? false
						? "Tillbaka till dubbletter"
						: "Tillbaka till utskickslista"}
				</Crumb>
			) : (
				pathnames.map((path: string, index: number) =>
					index == 0 ? (
						<Crumb
							key={index}
							color='#3699FF'
							className='hover'
							sx={{ fontWeight: 500 }}
							onClick={() => navigate(`/${pathnames[index]}/`)}
						>
							{getPageName(path)}
						</Crumb>
					) : index != pathnames.length - 1 ? (
						<Crumb
							key={index}
							color='#3F4254'
							className='hover'
							onClick={() => navigate(urlSlicer(index))}
						>
							{getPageName(path)}
						</Crumb>
					) : (
						<Crumb key={index} color='#3F4254'>
							{getPageName(path)}
						</Crumb>
					),
				)
			)}
		</Breadcrumbs>
	);
};

export default BreadcrumbHandler;
