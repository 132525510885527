import React, { MouseEventHandler, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ReactComponent as LinesIcon } from "assets/icons/lines.svg";
import { IFilterProps } from "components/dispatch/DispatchListTable/DispatchListTable";
import { Checkbox, IconButton, Typography } from "@mui/material";

interface DispatchFastFilterProps {
	rowFilters: IFilterProps;
	setRowFilters: React.Dispatch<React.SetStateAction<IFilterProps>>;
}
interface IMenuItemsFilter {
	label: string;
	checked: boolean;
	onClick: MouseEventHandler<HTMLLIElement>;
}

export default function FilterButton({ rowFilters, setRowFilters }: DispatchFastFilterProps) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const allFiltersFalse = ![
		rowFilters.notStarted,
		rowFilters.started,
		rowFilters.investigation,
		rowFilters.sent,
	].some(Boolean);

	const menuItemsFilter: IMenuItemsFilter[] = [
		{
			label: "Ej påbörjade",
			onClick: () => {
				{
					setRowFilters({ ...rowFilters, notStarted: rowFilters.notStarted == 1 ? 0 : 1 });
					sessionStorage.setItem("rowFilters.notStarted", rowFilters.notStarted == 1 ? "0" : "1");
				}
			},
			checked: rowFilters.notStarted == 1 ? true : false,
		},
		{
			label: "Påbörjade",
			onClick: () => {
				{
					setRowFilters({ ...rowFilters, started: rowFilters.started == 1 ? 0 : 1 });
					sessionStorage.setItem("rowFilters.started", rowFilters.started == 1 ? "0" : "1");
				}
			},
			checked: rowFilters.started == 1 ? true : false,
		},
		{
			label: "Inskickade",
			onClick: () => {
				{
					setRowFilters({ ...rowFilters, sent: rowFilters.sent == 1 ? 0 : 1 });
					sessionStorage.setItem("rowFilters.sent", rowFilters.sent == 1 ? "0" : "1");
				}
			},
			checked: rowFilters.sent == 1 ? true : false,
		},
		{
			label: "Under utredning",
			onClick: () => {
				{
					setRowFilters({ ...rowFilters, investigation: rowFilters.investigation == 1 ? 0 : 1 });
					sessionStorage.setItem(
						"rowFilters.investigation",
						rowFilters.investigation == 1 ? "0" : "1",
					);
				}
			},
			checked: rowFilters.investigation == 1 ? true : false,
		},
	];

	return (
		<>
			<IconButton
				aria-label='filtrera'
				color={allFiltersFalse ? "primary" : "secondary"}
				sx={{ ml: 1 }}
				aria-haspopup='true'
				onClick={handleClick}
			>
				<LinesIcon />
			</IconButton>
			<Menu
				MenuListProps={{
					"aria-labelledby": "filtrera",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				PaperProps={{ sx: { minWidth: "160px !important" } }}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<Typography variant='h3' ml={2} my={2}>
					Filtrera på:
				</Typography>
				<Typography variant='h3' ml={3.5} mb={1}>
					Löneprotkoll
				</Typography>
				{menuItemsFilter.map((item, index: number) => {
					return (
						<MenuItem key={index} onClick={item.onClick}>
							<Checkbox sx={{ marginRight: 2, marginLeft: 1 }} checked={item.checked} />
							{item.label}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
}
