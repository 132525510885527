import React from "react";
import { Card, CircularProgress, styled, Typography } from "@mui/material";

export const CardInfoBoxStyled = styled(Card, {
	shouldForwardProp: (prop) => prop !== "maxWidthCustom",
})<{ maxWidthCustom?: boolean | string }>(({ theme, maxWidthCustom }) => ({
	display: "flex",
	flexDirection: "column",
	background: theme.palette.background.lightgrey,
	margin: "16px 10px",
	padding: "10px 16px",
	border: 0,
	maxWidth: maxWidthCustom ? maxWidthCustom + "px" : "auto",
	borderRadius: "6px !important",

	[theme.breakpoints.down("md")]: {
		marginTop: 0,
	},
}));

export const TypographyInfoBoxStyled = styled(Typography)(() => ({
	fontSize: "14px",
}));

export default function InfoBox({ infoBoxProps, maxWidthCustom }: any) {
	return infoBoxProps ? (
		<>
			{Object.entries(infoBoxProps).map(([key, value]) => {
				return (
					<CardInfoBoxStyled variant='outlined' key={key} maxWidthCustom={maxWidthCustom}>
						<TypographyInfoBoxStyled sx={{ fontWeight: "500" }}>
							{key as string}
						</TypographyInfoBoxStyled>
						<TypographyInfoBoxStyled>{value as string}</TypographyInfoBoxStyled>
					</CardInfoBoxStyled>
				);
			})}
		</>
	) : (
		<CircularProgress />
	);
}
