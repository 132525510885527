import * as React from "react";
import { useDispatch } from "react-redux";

import { IconButton, Menu, MenuItem, styled } from "@mui/material";

import ListItemIcon from "@mui/material/ListItemIcon";
import { ReactComponent as SettingsIcon } from "assets/icons/settings-grey.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as ArchiveIcon } from "assets/icons/archive.svg";

import { openConfirmation } from "components/common/confirmDialog/ConfirmationSlice";
import { enqueueSnackbar } from "notistack";
import {
	useGetAllActiveQuery,
	useGetAllCompletedQuery,
	useGetArchiveDispatchListMutation,
	useGetRemoveDispatchListMutation,
} from "api/Api";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";

interface ButtonProps {
	open?: boolean;
}

const IconButtonStyled = styled(IconButton, {
	shouldForwardProp: (prop) => prop !== "open",
})<ButtonProps>(({ theme, open }) => ({
	backgroundColor: open === true ? theme.palette.background.label : "transparent",
	"&:hover #Group_5 .settings": {
		fill: theme.palette.primary.main,
	},
	"& #Group_5 .settings": {
		fill: open && theme.palette.primary.main,
	},
}));

interface RowMenuProps {
	dispatchListId: string;
	setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function RowMenu({ dispatchListId, setLoading }: RowMenuProps) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [getArchiveDispatchListMutation] = useGetArchiveDispatchListMutation();
	const [getRemoveDispatch] = useGetRemoveDispatchListMutation();
	const { refetch: refetchActive } = useGetAllActiveQuery(null);
	const { refetch: refetchCompleted } = useGetAllCompletedQuery(null);
	const url = LastSegmentOfUrl(location.href);

	const dispatch = useDispatch();
	function handleDelete() {
		dispatch(
			openConfirmation({
				message: ["Är du säker på att du vill radera listan?", "Obs! Detta går inte att ångra."],
				callback: (confirmed: boolean) => {
					if (confirmed) {
						if (setLoading) setLoading(true);
						const form = {
							dispatchListId: dispatchListId,
						};
						getRemoveDispatch(form)
							.unwrap()
							.then((response) => {
								enqueueSnackbar("Lista raderad", {
									variant: "success",
								});
								url == "aktuella" ? refetchActive() : refetchCompleted();
							})
							.catch((error) => {
								if (error.data && error.data.detail) {
									enqueueSnackbar(error.data.detail, {
										variant: "error",
										persist: true,
									});
								}
							})
							.finally(() => {
								if (setLoading) setLoading(false);
							});
					}
				},
			}),
		);
	}

	function handleArchiveList() {
		dispatch(
			openConfirmation({
				message: ["Är du säker på att du vill arkivera listan?", "Obs! Detta går inte att ångra."],

				callback: (confirmed: boolean) => {
					if (confirmed) {
						const form = {
							dispatchListId: dispatchListId,
						};
						getArchiveDispatchListMutation(form)
							.unwrap()
							.then(async (response) => {
								enqueueSnackbar("Rad arkiverad", {
									variant: "success",
								});
								await refetchActive();
								refetchCompleted();
							})
							.catch((error) => {
								enqueueSnackbar(error.data.detail, {
									variant: "error",
									persist: true,
								});
							});
					}
				},
			}),
		);
	}

	return (
		<>
			<IconButtonStyled
				onClick={handleClick}
				aria-label='redigera'
				size='small'
				aria-haspopup='true'
				aria-controls={open ? "redigera" : undefined}
				aria-expanded={open ? "true" : undefined}
				open={open}
			>
				<SettingsIcon />
			</IconButtonStyled>
			<Menu
				anchorEl={anchorEl}
				id='account-menu'
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
				}}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				{url == "aktuella" && (
					<MenuItem onClick={handleArchiveList}>
						<ListItemIcon>
							<ArchiveIcon />
						</ListItemIcon>
						Arkivera
					</MenuItem>
				)}

				{url == "aktuella" ? (
					<MenuItem onClick={() => handleDelete()}>
						<ListItemIcon>
							<DeleteIcon />
						</ListItemIcon>
						Radera
					</MenuItem>
				) : (
					<MenuItem onClick={() => handleDelete()} disabled>
						<ListItemIcon>
							<DeleteIcon />
						</ListItemIcon>
						Radera
					</MenuItem>
				)}
			</Menu>
		</>
	);
}
