import React, { useEffect } from "react";

import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import theme from "styles/theme";

const PageTabsRepresentative = () => {
	const navigate = useNavigate();
	const [value, setValue] = React.useState("1");

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	const fullScreen = useMediaQuery(theme.breakpoints.up("sm"));

	const StyledTab = styled(Tab)({
		fontSize: "0.9rem",
		"&.Mui-selected": {
			color: "#3F4254;",
			fontWeight: "500",
		},
	});

	useEffect(() => {
		const pathnames = location.pathname.split("/").filter((x: string) => x);
		if (pathnames.includes("historik")) setValue("2");
	}, []);

	return (
		<Box sx={{ width: "100%", typography: "body1", bgcolor: "#EEEEEE" }}>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<TabList
						onChange={handleChange}
						sx={{ marginLeft: fullScreen ? "60px" : "14px" }}
						TabIndicatorProps={{
							style: { backgroundColor: theme.palette.background.menuRed },
						}}
					>
						<StyledTab label='Aktuella' value='1' onClick={() => navigate("/aktuella")} />
						<StyledTab label='Historik' value='2' onClick={() => navigate("/historik")} />
					</TabList>
				</Box>
			</TabContext>
		</Box>
	);
};

export default PageTabsRepresentative;
