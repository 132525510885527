import React, { useEffect, useState } from "react";
import { useGetPostSalaryReportGeneralCommentMutation, useGetSalaryReportQuery } from "api/Api";
import { enqueueSnackbar } from "notistack";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";

import { Typography, Stack, DialogActions, Button, DialogContent } from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";

import LoadingButton from "components/common/loading/LoadingButton";
import Comment from "components/common/comment/Comment";

interface GeneralCommentModalContentProps {
	showModal: React.Dispatch<React.SetStateAction<boolean>>;
	editable?: boolean;
}

const GeneralCommentModalContent: React.FC<GeneralCommentModalContentProps> = ({
	showModal,
	editable,
}) => {
	const salaryReportId = LastSegmentOfUrl(location.href);
	const [comment, setComment] = useState<string>("");

	const [
		getPostSalaryReportComment,
		{ isLoading: createLoading, isSuccess: createSuccess, error: saveError },
	] = useGetPostSalaryReportGeneralCommentMutation();

	const {
		data,
		isLoading: isLoadingComment,
		refetch: refetchComment,
		isError,
	} = useGetSalaryReportQuery(salaryReportId);

	useEffect(() => {
		if (data && data.generalComment?.length > 0) setComment(data.generalComment);
	}, []);

	const saveComment = async () => {
		const form = {
			comment: { comment: comment },
			salaryReportId: salaryReportId,
		};

		await getPostSalaryReportComment(form)
			.unwrap()
			.then((response) => {
				enqueueSnackbar("Kommentar sparad", { variant: "success" });
				showModal(false);
				refetchComment();
			})
			.catch((error) => {
				enqueueSnackbar(error.data.detail || "Kan ej spara kommentar", {
					variant: "error",
					persist: true,
				});
			});
	};

	return (
		<>
			<DialogContent dividers sx={{ width: { md: 600 } }}>
				<Stack spacing={2} mb={editable ? 2 : 0}>
					{editable ? (
						<Comment
							commentLabel={"Kommentar:"}
							isLoading={isLoadingComment}
							isError={isError}
							setComment={setComment}
							comment={comment}
						/>
					) : (
						<Typography variant='body'>{comment}</Typography>
					)}
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button aria-label='Avbryt' variant='grey' onClick={() => showModal(false)}>
					Avbryt
				</Button>

				{editable ? (
					createLoading ? (
						<LoadingButton label={"Sparar kommentar"} />
					) : (
						<Button
							aria-label='Spara'
							variant='containedPrimary'
							onClick={saveComment}
							startIcon={<SaveIcon />}
							disabled={comment?.length >= 1000}
						>
							Spara
						</Button>
					)
				) : null}
			</DialogActions>
		</>
	);
};
export default GeneralCommentModalContent;
