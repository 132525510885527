import React, { useState, useEffect, useCallback, useContext } from "react";

import SignIn from "components/idConnect/SignIn";
import { AuthContext } from "components/idConnect/AuthContext";

const Authenticate = (props) => {
	// const { userLoaded } = props;
	const [isAuthenticated, setIsAuthenticate] = useState(false);

	const [isLoading, setIsLoading] = useState(true);
	const userManager = useCallback(useContext(AuthContext), []);

	const onUserLoaded = useCallback(async (user) => {
		const token = user.access_token;
		localStorage.setItem("userAuth", JSON.stringify(token));
		setIsAuthenticate(true);
		// if (userLoaded !== undefined) userLoaded(user);
	}, []);

	useEffect(() => {
		const getUser = async () => {
			try {
				const user = await userManager.getUser();
				if (user !== null && user !== undefined) {
					await onUserLoaded(user);
				}
				if (window.location.href.includes("code")) {
					try {
						await userManager.signinRedirectCallback();
						const user = await userManager.getUser();
						await onUserLoaded(user);
						setIsAuthenticate(true);
					} catch (error) {
						console.log("Error signinRedirectCallback: ", error);
					}
				}
			} catch (e) {
				console.log(e);
			} finally {
				setIsLoading(false);
			}
		};
		getUser();
	}, []);

	const renderContent = isLoading ? (
		<div></div>
	) : isAuthenticated ? (
		props.children
	) : (
		<SignIn onUserLoaded={onUserLoaded} isLoading={isLoading} setIsLoading={setIsLoading} />
	);

	return <>{renderContent}</>;
};

export default Authenticate;
