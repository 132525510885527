import { ChangeEvent } from "react";

export const validateNumberInput = (
	e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
	if (e.ctrlKey && (e.code == "KeyV" || e.code == "KeyC" || e.code == "KeyX" || e.code == "KeyA")) {
		return;
	}
	if (
		e.key !== "Backspace" &&
		e.key !== "Delete" &&
		e.key !== "ArrowLeft" &&
		e.key !== "ArrowRight"
	) {
		if (isNaN(Number(e.key))) e.preventDefault(); 
	}
};

export const filterInputFromAlpha = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
	if (e.target != null) {
		const value = e.target.value.replace(/\D/g, "")
		e.target.value = value;
	}
};

export const formatSalary = (salary: number) => {
	const formated = new Intl.NumberFormat("sv-SE", {
		style: "currency",
		currency: "SEK",
	})
		.format(salary)
		.replace(",00", "")
		.replace("kr", "")
		.trim();
	return formated;
};