import React, { useEffect } from "react";
import { Box, styled, CircularProgress, Typography } from "@mui/material";
import InfoBox, { CardInfoBoxStyled, TypographyInfoBoxStyled } from "../common/infoBox/InfoBox";
import DispatchListTable from "components/dispatch/DispatchListTable/DispatchListTable";
import { useGetByIdQuery, useGetEmptyProtocolMutation } from "api/Api";
import { IDispatchListInfoDetails } from "types/dispatch/dispatchListTable/DispatchListTableTypes";
import { useLocation, useNavigate } from "react-router-dom";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";
import { LayoutBoxStyled } from "components/common/styledComponents/Layout";
import colors from "styles/colors-theme";
import { enqueueSnackbar } from "notistack";
import theme from "styles/theme";

const BoxStyled = styled(Box)(() => ({
	border: 0,
	padding: 0,
	paddingLeft: "48px",
	margin: 0,
	width: "100%",
	minHeight: "60px",
	background: colors.background.grey,
	display: "inline-flex",

	[theme.breakpoints.down("md")]: {
		paddingLeft: "10px",
		paddingTop: "16px",
	},

	[theme.breakpoints.down("sm")]: {
		flexDirection: "column",
		paddingLeft: 0,
	},
}));

export default function DispatchList() {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { dispatchListIdFromState } = state || "";
	const dispatchListIdFromUrl = LastSegmentOfUrl(location.href);
	const dispatchListId: string = dispatchListIdFromState
		? dispatchListIdFromState
		: dispatchListIdFromUrl;

	const { data, isLoading } = useGetByIdQuery(dispatchListId, { refetchOnMountOrArgChange: true });
	const [getEmptyProtocolMutation, { isError }] = useGetEmptyProtocolMutation();

	// if list is archived make sure the user is on right url
	useEffect(() => {
		const pathnames = location.pathname.split("/").filter((x: string) => x);
		if (data && data.isArchived && !pathnames.includes("arkiverade")) {
			navigate(`/${pathnames[0]}/arkiverade/${pathnames[2]}`);
		}
		if (data && !data.isArchived && pathnames.includes("arkiverade")) {
			navigate(`/${pathnames[0]}/aktuella/${pathnames[2]}`);
		}
	}, [data]);

	const downloadPdf = () => {
		try {
			getEmptyProtocolMutation(data.salaryRevisionId);
			if (isError) {
				enqueueSnackbar("Kunde inte ladda hem tomt löneprotokoll", {
					variant: "error",
					persist: true,
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	const dispatchListInfo: IDispatchListInfoDetails[] = [];
	if (data) {
		const departmentNumbers = data.departmentNumbers
			.reduce((accumulator: string | string[], value: string) => accumulator.concat(value), [])
			.sort((a: number, b: number) => a - b)
			.join(", ");

		dispatchListInfo.push({
			Namn: data.name ?? "",
			Avtalsområde: data.agreementTypeName ?? "",
			Avdelning: departmentNumbers ?? "",
		});
	}

	return (
		<>
			{isLoading ? (
				<Box height={"100vh"}>
					<CircularProgress sx={{ marginLeft: "50vw", marginTop: "40vh" }} />
				</Box>
			) : data ? (
				<>
					<BoxStyled>
						<InfoBox infoBoxProps={dispatchListInfo[0]} />
						<CardInfoBoxStyled variant='outlined'>
							<TypographyInfoBoxStyled sx={{ fontWeight: "500" }}>
								Ladda ner
							</TypographyInfoBoxStyled>
							<TypographyInfoBoxStyled
								onClick={() => downloadPdf()}
								sx={{ color: colors.primary, cursor: "pointer" }}
							>
								Tomt löneprotokoll.xlsx
							</TypographyInfoBoxStyled>
						</CardInfoBoxStyled>
					</BoxStyled>
					<LayoutBoxStyled>
						<DispatchListTable />
					</LayoutBoxStyled>
				</>
			) : (
				<LayoutBoxStyled>
					<Typography>Kunde ej ladda data</Typography>
				</LayoutBoxStyled>
			)}
		</>
	);
}
