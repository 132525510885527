import React, { useEffect, useState } from "react";
import {
	useGetActivateSalaryProtocolRowMutation,
	useGetCreateSalaryReportRowMutation,
	useGetIgnoreSalaryProtocolRowMutation,
	useGetPatchSalaryReportRowMutation,
	useGetSalaryReportQuery,
	useGetSalaryReportRowsQuery,
	useGetUserFromDirectoryQuery,
} from "api/Api";
import { enqueueSnackbar } from "notistack";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";
import { filterInputFromAlpha } from "lib/helpers/NumberInputHelper";
import { roles } from "api/user/Roles";

import theme from "styles/theme";
import {
	Box,
	Typography,
	CircularProgress,
	Stack,
	OutlinedInput,
	styled,
	DialogActions,
	Button,
	DialogContent,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	MenuItem,
	Select,
	Checkbox,
	IconButton,
	useMediaQuery,
} from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as TrashIconDisabled } from "assets/icons/trash-disabled.svg";

import LoadingButton from "components/common/loading/LoadingButton";
import Comment from "components/common/comment/Comment";
import {
	ISalaryRevisionLimit,
	IAddSalaryRow,
	IEditSalaryReportRowForm,
	ISalaryProtocolTableRow,
} from "types/representative/commonSalaryReportTypes";
import { IResponsible } from "types/dispatch/dispatchListTable/SelectAgentModal";

const OutlinedInputStyled = styled(OutlinedInput)(() => ({
	marginTop: 2,
}));

interface AddWorkerModalContentProps {
	showModal: React.Dispatch<React.SetStateAction<boolean>>;
	inEditMode: boolean;
	salaryRowId?: string;
	handleModalDelete?: (salaryRowId: string) => void;
}

interface IWorker {
	isManualyAdded: boolean;
	salaryReportId: string;
	name: string;
	isSEFMemberNumber: boolean;
	idNumber: string;
	previousSalary: string;
	newSalary: string;
	comment: string;
	warnings: string[];
	ignored: boolean;
	selectedCodeId: string;
	addedMethod: number;
}

const AddWorkerModalContent: React.FC<AddWorkerModalContentProps> = ({
	showModal,
	inEditMode,
	salaryRowId,
	handleModalDelete,
}) => {
	const salaryReportId = LastSegmentOfUrl(location.href);
	const [rows, setRows] = useState<ISalaryProtocolTableRow[]>([]);
	const [defaultIgnore, setDefaultIgnore] = useState<boolean>(false);
	const [row, setRow] = useState<IWorker>({
		isManualyAdded: false,
		salaryReportId: salaryReportId ?? "",
		name: "",
		isSEFMemberNumber: false,
		idNumber: "",
		previousSalary: "",
		newSalary: "",
		comment: "",
		warnings: [],
		ignored: false,
		selectedCodeId: "default",
		addedMethod: 0,
	});

	const [getIgnoreSalaryProtocolRow, { isLoading: createLoadingIgnore }] =
		useGetIgnoreSalaryProtocolRowMutation();

	const [getActivateSalaryProtocolRow, { isLoading: createLoadingActivate }] =
		useGetActivateSalaryProtocolRowMutation();

	const [getCreateSalaryReportRow, { isLoading: createLoading }] =
		useGetCreateSalaryReportRowMutation();

	const [getPatchSalaryReportRow, { isLoading: createLoadingEdit }] =
		useGetPatchSalaryReportRowMutation();

	const {
		data: salaryReportRowsData,
		refetch: refetchSalaryReportRowsData,
		isLoading: isLoadingsalaryReportRowsData,
		isError: isErrorsalaryReportRowsData,
	} = useGetSalaryReportRowsQuery(salaryReportId);

	const { data: userData } = useGetUserFromDirectoryQuery(null);
	const { data, isLoading: isLoadingCodes } = useGetSalaryReportQuery(salaryReportId);

	const fullEditInModal = useMediaQuery(theme.breakpoints.down("lg"));
	const isOmbud = data.responsibles.findIndex((r: IResponsible) => r.userId == userData.id) >= 0;
	const ombudCanEdit = isOmbud && data.status == 250;
	const disableNonAdmin = userData && userData?.activeRole == "non-admin" && data?.status >= 250;
	const isArchived = (data && data?.dispatchListArchivedDate != null) ?? false;
	const activeRole = roles.find((r) => r.roleName == userData?.activeRole)?.uiRoleName;

	const salaryCodes: ISalaryRevisionLimit[] =
		data?.salaryRevision?.salaryRevisionLimits
			?.slice()
			.sort((a: { salaryCode: { code: string } }, b: { salaryCode: { code: string } }) =>
				a.salaryCode.code.localeCompare(b.salaryCode.code),
			) ?? [];

	const salaryCodesOptions =
		data?.salaryRevision?.salaryRevisionLimits.map(
			(c: { salaryCode: { salaryCodeId: string; description: string; code: string } }) => ({
				salaryCodeId: c.salaryCode.salaryCodeId,
				label: `${c.salaryCode.description} (${c.salaryCode.code})`,
			}),
		) ?? [];

	// if inEditMode (coming from row setting button "Redigera" / clicked on row)
	useEffect(() => {
		if (salaryReportRowsData && salaryReportRowsData.salaryRows.length > 0) {
			setRows(salaryReportRowsData.salaryRows);
		}
		if (rows && rows.length > 0 && inEditMode && salaryRowId) {
			const worker = rows.filter((row: { salaryRowId: string }) => {
				return row.salaryRowId == salaryRowId;
			});

			setRow((prevRow) => ({
				...prevRow,
				isManualyAdded: worker[0]?.addedMethod === 0 ? false : true,
				name: worker[0]?.employee?.name || "",
				isSEFMemberNumber: !worker[0]?.employee?.birthDate,
				idNumber: worker[0]?.employee?.birthDate || worker[0]?.employee?.sefMemberNumber || "",
				selectedCodeId:
					worker[0]?.salaryCodeId && salaryCodesOptions.length > 0
						? salaryCodesOptions.find(
								(c: { salaryCodeId: string }) => c.salaryCodeId == worker[0].salaryCodeId,
						  )?.salaryCodeId || ""
						: "",
				comment: worker[0]?.comment || "",
				previousSalary: worker[0]?.previousSalary?.toString() || "",
				newSalary: worker[0]?.newSalary || "",
				ignored: worker[0]?.ignored || false,
				addedMethod: worker[0]?.addedMethod || 0,
			}));
			setDefaultIgnore(worker[0]?.ignored || false);

			if (fullEditInModal && inEditMode) {
				setRow((prevRow) => ({
					...prevRow,
					warnings:
						fullEditInModal && worker[0]?.calculations?.warnings
							? worker[0].calculations.warnings.map((w) => w.warningDescription)
							: [],
				}));
			}
		}
	}, [rows]);

	const saveEmployer = async () => {
		if (inEditMode) {
			const formEdit: IEditSalaryReportRowForm = {
				salaryReportId: salaryReportId || "",
				salaryRowId: salaryRowId || "",
				content: {
					name: row.name,
					isSEFMemberNumber: row.isSEFMemberNumber,
					idNumber: row.idNumber,
					salaryCodeId: row.selectedCodeId,
					previousSalary: Number(row.previousSalary),
					newSalary: Number(row.newSalary) > 0 ? Number(row.newSalary) : null,
					comment: row.comment,
				},
			};

			try {
				if (!disableNonAdmin || !isArchived || (isOmbud && ombudCanEdit)) {
					await getPatchSalaryReportRow(formEdit)
						.unwrap()
						.then(() => {
							if (row.ignored == defaultIgnore) {
								enqueueSnackbar("Arbetstagare " + row.name + " uppdaterad", { variant: "success" });
							}
						})
						.catch((error) => {
							enqueueSnackbar(error?.data?.Detail || "Kan ej uppdatera arbetstagare", {
								variant: "error",
								persist: true,
							});
						});
				}

				if (!row.ignored && row.ignored != defaultIgnore) {
					const formActivate = {
						salaryRowId: salaryRowId,
						salaryReportId: salaryReportId,
						comment: JSON.stringify(row.comment),
					};
					await getActivateSalaryProtocolRow(formActivate)
						.unwrap()
						.then(() => {
							enqueueSnackbar(`${row.name != null ? row.name : "arbetstagare "} inkluderad.`, {
								variant: "success",
							});
						})
						.catch((error) => {
							enqueueSnackbar(error?.data?.Detail || "Kan ej inkludera arbetstagare", {
								variant: "error",
								persist: true,
							});
						});
				}

				if (row.ignored && row.ignored != defaultIgnore) {
					const formIgnore = {
						salaryRowId: salaryRowId,
						salaryReportId: salaryReportId,
						ignore: {
							comment: row.comment,
							employmentEndedDate: new Date(),
						},
					};

					await getIgnoreSalaryProtocolRow(formIgnore)
						.unwrap()
						.then(() => {
							enqueueSnackbar(`${row.name != null ? row.name : "arbetstagare "} ignorerad.`, {
								variant: "success",
							});
						})
						.catch((error) => {
							enqueueSnackbar(error?.data?.Detail || "Kan ej ignorera arbetstagare", {
								variant: "error",
								persist: true,
							});
						});
				}
			} catch (error) {
				enqueueSnackbar("Ett fel inträffade", {
					variant: "error",
					persist: true,
				});
			} finally {
				refetchSalaryReportRowsData();
				showModal(false);
			}
		} else {
			const form: IAddSalaryRow = {
				salaryReportId: row.salaryReportId || "",
				name: row.name,
				isSEFMemberNumber: row.isSEFMemberNumber,
				idNumber: row.idNumber,
				salaryCodeId: row.selectedCodeId,
				previousSalary: Number(row.previousSalary),
				// should be null if not over 0, if newSalary is 0 warnings will not show correctly
				newSalary: Number(row.newSalary) > 0 ? Number(row.newSalary) : null,
				comment: row.comment,
			};
			await getCreateSalaryReportRow(form)
				.unwrap()
				.then(() => {
					enqueueSnackbar("Arbetstagare " + row.name + " tillagd", { variant: "success" });
					showModal(false);
					refetchSalaryReportRowsData();
				})
				.catch((error) => {
					enqueueSnackbar(error?.data?.Detail || "Kan ej spara arbetstagare", {
						variant: "error",
						persist: true,
					});
				});
		}
	};

	const readonly =
		(disableNonAdmin ||
			isArchived ||
			row.ignored ||
			createLoading ||
			createLoadingEdit ||
			createLoadingIgnore ||
			createLoadingActivate) &&
		!ombudCanEdit;

	const showDeleteInFullEditMode =
		(fullEditInModal && inEditMode && activeRole === "System Admin") ||
		(fullEditInModal && inEditMode && activeRole === "Administratör") ||
		(fullEditInModal && inEditMode && activeRole === "Förtroendevald" && row.addedMethod > 0);

	return (
		<>
			{isLoadingsalaryReportRowsData ? (
				<CircularProgress />
			) : (
				<DialogContent dividers sx={{ minWidth: { md: 606 } }}>
					{!readonly &&
						fullEditInModal &&
						inEditMode &&
						row.warnings &&
						row.warnings.length > 0 && (
							<Box
								sx={{ border: `2px solid ${theme.palette.error.contrastText}`, borderRadius: 2 }}
								p={2}
								mb={2}
							>
								<Stack spacing={1}>
									{row.warnings.map((m, i) => (
										<Typography key={i} variant='bodyMedium'>
											{m}
										</Typography>
									))}
								</Stack>
							</Box>
						)}
					<Stack spacing={2} mb={2}>
						<Box>
							<Typography variant='bodyMedium'>Namn:</Typography>
							<OutlinedInputStyled
								onChange={(e) => setRow((prevRow) => ({ ...prevRow, name: e.target.value }))}
								disabled={readonly || (inEditMode && !row.isManualyAdded)}
								value={row.name}
							/>
						</Box>
						<FormControl>
							<RadioGroup
								aria-labelledby='demo-radio-buttons-group-label'
								defaultValue='medlemsnummer'
								row
							>
								<FormControlLabel
									value='personnummer'
									onClick={() => setRow((prevRow) => ({ ...prevRow, isSEFMemberNumber: false }))}
									control={<Radio size='small' color='primary' />}
									label={<Typography variant='bodyMedium'>Personnummer:</Typography>}
									disabled={readonly || (inEditMode && !row.isManualyAdded)}
									checked={!row.isSEFMemberNumber}
									sx={{ pointerEvents: inEditMode && !row.isManualyAdded ? "none" : "" }}
								/>
								<FormControlLabel
									value='medlemsnummer'
									onClick={() => setRow((prevRow) => ({ ...prevRow, isSEFMemberNumber: true }))}
									control={<Radio size='small' color='primary' />}
									label={<Typography variant='bodyMedium'>Medlemsnummer:</Typography>}
									disabled={readonly || (inEditMode && !row.isManualyAdded)}
									checked={row.isSEFMemberNumber}
									sx={{ pointerEvents: inEditMode && !row.isManualyAdded ? "none" : "" }}
								/>
							</RadioGroup>
							<OutlinedInputStyled
								onChange={(e) => setRow((prevRow) => ({ ...prevRow, idNumber: e.target.value }))}
								disabled={readonly || (inEditMode && !row.isManualyAdded)}
								value={row.idNumber}
								placeholder={!row.isSEFMemberNumber ? "Använd helst personnummer" : ""}
							/>
						</FormControl>
						<Box>
							<Typography variant='bodyMedium'>Kod:</Typography>
							{isLoadingCodes ? (
								<CircularProgress />
							) : salaryCodesOptions && salaryCodesOptions.length > 0 ? (
								<>
									{salaryCodesOptions.length > 0 && (
										<>
											<FormControl fullWidth>
												<Select
													disabled={readonly}
													value={row.selectedCodeId ? row.selectedCodeId : ""}
													onChange={(e) =>
														setRow((prevRow) => ({
															...prevRow,
															selectedCodeId: e.target.value as string,
														}))
													}
													displayEmpty
												>
													<MenuItem
														selected
														disabled
														value='default'
														sx={{ opacity: 1 + "!important" }}
													>
														<Typography variant='body0' color={theme.palette.text.disabled}>
															Välj kod
														</Typography>
													</MenuItem>
													{salaryCodes.map((c) => {
														return (
															<MenuItem
																key={c.salaryCode.salaryCodeId}
																value={c.salaryCode.salaryCodeId}
															>
																{c.salaryCode.description} ({c.salaryCode.code})
															</MenuItem>
														);
													})}
												</Select>
											</FormControl>
										</>
									)}
								</>
							) : (
								<Typography variant='body' sx={{ fontStyle: "italic" }}>
									{" "}
									Kan inte hämtas.
								</Typography>
							)}
						</Box>
						<Box>
							<Typography variant='bodyMedium'>Nuvarande lön:</Typography>
							<OutlinedInputStyled
								onChange={(e) => {
									filterInputFromAlpha(e);
									setRow((prevRow) => ({ ...prevRow, previousSalary: e.target.value }));
								}}
								disabled={readonly}
								endAdornment={
									<Typography
										sx={{
											fontSize: 12,
											paddingRight: 1,
											color: readonly ? theme.palette.text.disabled : "",
										}}
									>
										kr
									</Typography>
								}
								sx={{
									backgroundColor: readonly ? theme.palette.background.label : "white",
								}}
								value={row.previousSalary}
							/>
						</Box>
						<Box>
							<Typography variant='bodyMedium'>Ny lön:</Typography>
							<OutlinedInputStyled
								onChange={(e) => {
									filterInputFromAlpha(e);
									setRow((prevRow) => ({ ...prevRow, newSalary: e.target.value }));
								}}
								disabled={readonly}
								endAdornment={
									<Typography
										sx={{
											fontSize: 12,
											paddingRight: 1,
											color: readonly ? theme.palette.text.disabled : "",
										}}
									>
										kr
									</Typography>
								}
								value={row.newSalary}
								sx={{
									backgroundColor: readonly ? theme.palette.background.label : "white",
								}}
							/>
						</Box>
						{inEditMode && (
							<Box>
								{!disableNonAdmin && !isArchived && !ombudCanEdit ? (
									<Comment
										commentLabel={
											row.ignored
												? "Kommentar måste lämnas när arbetstagare ignoreras:"
												: "Kommentar:"
										}
										isLoading={isLoadingsalaryReportRowsData}
										isError={isErrorsalaryReportRowsData}
										setComment={(newComment) =>
											setRow((prevRow) => ({
												...prevRow,
												comment:
													typeof newComment === "function"
														? (newComment as (prevComment: string) => string)(prevRow.comment)
														: newComment,
											}))
										}
										comment={row.comment}
									/>
								) : (
									<>
										<Typography variant='bodyMedium'>Kommentar:</Typography>
										<Box>
											<OutlinedInputStyled
												disabled={true}
												multiline
												sx={{ height: "100%" }}
												value={row.comment}
											/>
										</Box>
									</>
								)}
							</Box>
						)}

						{fullEditInModal && inEditMode && (
							<FormControlLabel
								control={
									<Checkbox
										sx={{ marginRight: 2, marginLeft: 1 }}
										onChange={(e) =>
											setRow((prevRow) => ({ ...prevRow, ignored: e.target.checked }))
										}
										checked={row.ignored}
										disabled={(disableNonAdmin || isArchived) && !ombudCanEdit}
									/>
								}
								sx={{
									border: "3px solid #DFEFFF",
									borderRadius: "11px",
									padding: "10px 6px",
								}}
								label={<Typography variant='body'>Ignorera arbetstagare {row.name}</Typography>}
							/>
						)}
					</Stack>
				</DialogContent>
			)}

			<DialogActions
				sx={{ justifyContent: showDeleteInFullEditMode ? "space-between" : "right", marginX: 1 }}
			>
				{showDeleteInFullEditMode && (
					<IconButton
						sx={{
							backgroundColor: theme.palette.error.light,
							"&:hover": { backgroundColor: theme.palette.text.grey },
						}}
						aria-label='Radera'
						onClick={() => {
							if (handleModalDelete && salaryRowId) {
								handleModalDelete(salaryRowId);
							}
						}}
						disabled={(disableNonAdmin || isArchived) && !ombudCanEdit}
					>
						{(disableNonAdmin || isArchived) && !ombudCanEdit ? (
							<TrashIconDisabled style={{ width: 15 }} />
						) : (
							<TrashIcon style={{ width: 15 }} />
						)}
					</IconButton>
				)}
				<Box display={"flex"}>
					<Button
						aria-label='Avbryt'
						variant='grey'
						onClick={() => showModal(false)}
						sx={{ marginRight: 1 }}
					>
						Avbryt
					</Button>

					{createLoading || createLoadingEdit ? (
						<LoadingButton label={"Sparar arbetstagare"} />
					) : (
						<Button
							aria-label='Spara'
							variant='containedPrimary'
							onClick={saveEmployer}
							startIcon={<SaveIcon />}
							disabled={
								(disableNonAdmin || isArchived) && !ombudCanEdit
									? true
									: row.ignored
									? row.comment.length == 0
									: (row.name == "" && row.isManualyAdded) ||
									  (row.idNumber == "" && row.isManualyAdded) ||
									  row.previousSalary == "" ||
									  row.selectedCodeId == "default" ||
									  row.selectedCodeId.length == 0 ||
									  row.comment?.length >= 1000 ||
									  readonly
							}
						>
							Spara
						</Button>
					)}
				</Box>
			</DialogActions>
		</>
	);
};
export default AddWorkerModalContent;
