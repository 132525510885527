import React from "react";
import { Box, Typography, CircularProgress, OutlinedInput } from "@mui/material";
import theme from "styles/theme";

interface CommentProps {
	commentLabel: string;
	comment: string;
	isLoading: boolean;
	isError: boolean;
	setComment: React.Dispatch<React.SetStateAction<string>>;
}

const Comment: React.FC<CommentProps> = ({
	setComment,
	commentLabel,
	comment,
	isError,
	isLoading,
}) => {
	return (
		<>
			{isLoading ? (
				<CircularProgress />
			) : isError ? (
				<Typography variant='body' sx={{ fontStyle: "italic" }}>
					Kommentar kan inte hämtas.
				</Typography>
			) : (
				<Box>
					<Typography variant='bodyMedium'>{commentLabel}</Typography>
					<OutlinedInput
						multiline
						rows={4}
						sx={{ height: "auto" }}
						onChange={(event) => setComment(event.target.value)}
						value={comment || ""}
					/>
					{comment && comment.length >= 1000 && (
						<Typography
							variant='body0'
							sx={{ fontStyle: "italic" }}
							color={theme.palette.error.contrastText}
						>
							Maxgräns 1000 tecken
						</Typography>
					)}
				</Box>
			)}
		</>
	);
};
export default Comment;
