import { Button, CircularProgress, Typography } from "@mui/material";
import colors from "styles/colors-theme";

interface LoadingButtonProps {
	label: string;
	size?: "medium" | "small" | "large" | undefined;
	fullWidth?: boolean;
}

const LoadingButton = ({ label, size, fullWidth = false }: LoadingButtonProps) => {
	const buttonSize = size ? size : "medium";
	return (
		<Button
			size={buttonSize}
			aria-label='Skapa'
			variant='grey'
			disabled={true}
			sx={{ width: fullWidth ? "100%" : "auto" }}
		>
			<Typography variant='body' sx={{ fontWeight: 500, color: colors.text.darkestGrey }}>
				{label}
			</Typography>
			<CircularProgress size={22} sx={{ marginLeft: 1 }} />
		</Button>
	);
};

export default LoadingButton;
