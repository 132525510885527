import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	useGetCreateOptionsQuery,
	useGetCreateDispatchMutation,
	useGetAllActiveQuery,
} from "api/Api";
import { enqueueSnackbar } from "notistack";

import {
	Box,
	Typography,
	CircularProgress,
	Stack,
	OutlinedInput,
	Autocomplete,
	styled,
	TextField,
	DialogActions,
	Button,
	DialogContent,
	Checkbox,
	Divider,
	FormControlLabel,
	Paper,
} from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import { IDepartment, IAgreementTypesWithRevision } from "types/dispatch/DispatchList";
import LoadingButton from "components/common/loading/LoadingButton";
import InfoBox from "components/common/infoBox/InfoBox";

const AutocompleteStyled = styled(Autocomplete)(({ theme }) => ({
	"& .MuiOutlinedInput-input": {
		height: 6,
		paddingRight: "10px !important",
	},

	"& .MuiOutlinedInput-root": {
		paddingRight: "10px !important",
		height: "auto",
	},

	"& .MuiAutocomplete-clearIndicator": {
		backgroundColor: "white",
		color: theme.palette.text.primary,
		display: "none",
	},

	"& .MuiAutocomplete-endAdornment": {
		backgroundColor: "white",
		color: theme.palette.text.primary,
		right: "2px !important",
		top: "auto",
		position: "absolute",
	},
	"& .MuiChip-root": {
		height: "auto",
		margin: 2,
		color: theme.palette.text.primary,
		borderRadius: 4,
		"& .MuiChip-deleteIcon": {
			fontSize: 16,
		},
	},
})) as typeof Autocomplete;

export interface CreateDispatchModalProps {
	showModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateDispatchModalContent: React.FC<CreateDispatchModalProps> = ({ showModal }) => {
	const navigate = useNavigate();
	const { data: options, isLoading } = useGetCreateOptionsQuery(null);
	const [getCreateDispatch, { isLoading: createLoading }] = useGetCreateDispatchMutation();

	const { refetch: refreshActive } = useGetAllActiveQuery(null);

	const [agreement, setAgreement] = useState<IAgreementTypesWithRevision | null>(null);
	const [dispatchName, setDispatchName] = useState("");
	const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
	const [selectAllDepartments, setSelectAllDepartments] = useState<boolean>(false);

	const toggleselectAllDepartmentsDepartments = () => {
		const allOptions: any[] = [];
		options.departments.map((option: IDepartment) => allOptions.push(option.name));

		setSelectAllDepartments((prev) => {
			if (!prev) setSelectedDepartments([...allOptions]);
			else setSelectedDepartments([]);
			return !prev;
		});
	};

	const handleAgreement = (value: string | unknown) => {
		setAgreement(null);
		options.agreementTypesWithRevisions.forEach((agreement: IAgreementTypesWithRevision) => {
			if (agreement.name == value) {
				setAgreement(agreement);
			}
		});
	};

	const saveDispatch = async () => {
		const departmentIdsArray: any[] = [];
		selectedDepartments.map((selected) => {
			options.departments.map((d: IDepartment) => {
				d.name == selected && departmentIdsArray.push(d.id);
			});
		});

		const form = {
			dispatchListName: dispatchName,
			departmentIds: departmentIdsArray,
			agreementTypeId: agreement!.id,
			salaryRevisionId: agreement!.salaryRevisionId,
		};

		await getCreateDispatch(form)
			.unwrap()
			.then((response) => {
				enqueueSnackbar('Utskickslista "' + dispatchName + '" skapad', { variant: "success" });
				showModal(false);
				refreshActive();
				navigate(`/utskickslistor/aktuella/${response.dispatchListId}`);
			})
			.catch((error) => {
				enqueueSnackbar(error.data.detail, { variant: "error", persist: true });
			});
	};

	return (
		<>
			<DialogContent dividers sx={{ padding: "30px 40px !important" }}>
				<Typography variant='body1'>
					Skicka ut information om löneförhandling till arbetsgivare och förtroendevalda.
				</Typography>
				<Stack spacing={2} mt={4}>
					<OutlinedInput
						placeholder='Namn på lista'
						onChange={(event) => setDispatchName(event.target.value)}
						disabled={createLoading}
					/>
					{isLoading ? (
						<CircularProgress />
					) : options ? (
						<>
							{options.departments.length > 0 && (
								<>
									<AutocompleteStyled
										options={options.departments.map((option: IDepartment) => option.name)}
										multiple
										disableCloseOnSelect
										value={selectedDepartments}
										noOptionsText={"Finns inga avdelningar"}
										onChange={(_e, value, reason) => {
											if (reason === "clear" || reason === "removeOption")
												setSelectAllDepartments(false);
											if (reason === "selectOption" && value.length === options.departments.length)
												setSelectAllDepartments(true);
											setSelectedDepartments(value);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												placeholder={"Välj avdelningar"}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<>
															{selectedDepartments.length > 0
																? null
																: params.InputProps.endAdornment}
														</>
													),
												}}
											/>
										)}
										PaperComponent={(paperProps) => {
											const { children, ...restPaperProps } = paperProps;
											return (
												<Paper {...restPaperProps}>
													<Box
														onMouseDown={(e) => e.preventDefault()} // prevent blur
														pl={1.5}
														py={1}
													>
														<FormControlLabel
															onClick={(e) => {
																e.preventDefault(); // prevent blur
																toggleselectAllDepartmentsDepartments();
															}}
															sx={{ paddingLeft: 1 }}
															label={
																<Typography sx={{ marginLeft: 1 }} variant='body0'>
																	Välj alla
																</Typography>
															}
															control={
																<Checkbox
																	id='select-all-checkbox'
																	checked={selectAllDepartments}
																	sx={{ mb: "3px" }}
																/>
															}
														/>
													</Box>
													<Divider />
													{children}
												</Paper>
											);
										}}
									/>
								</>
							)}
							{options.agreementTypesWithRevisions.length > 0 && (
								<AutocompleteStyled
									options={options.agreementTypesWithRevisions.map(
										(option: IAgreementTypesWithRevision) => option.name,
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											placeholder={"Välj avtal"}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>{agreement?.name ? null : params.InputProps.endAdornment}</>
												),
											}}
										></TextField>
									)}
									onChange={(event, value) => handleAgreement(value)}
									disabled={createLoading}
									noOptionsText={"Finns inga avtal"}
								></AutocompleteStyled>
							)}
							{agreement?.name && (
								<InfoBox
									infoBoxProps={{
										"Period Lönerevision": agreement.salaryRevisionDate?.slice(0, 10),
									}}
								></InfoBox>
							)}
						</>
					) : null}
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button aria-label='Avbryt' variant='grey' onClick={() => showModal(false)}>
					Avbryt
				</Button>

				{createLoading ? (
					<LoadingButton label={"Skapar utskickslista"} />
				) : (
					<Button
						aria-label='Skapa'
						variant='containedPrimary'
						onClick={saveDispatch}
						startIcon={<SaveIcon />}
						disabled={selectedDepartments.length == 0 || !agreement?.name || dispatchName == ""}
					>
						Spara
					</Button>
				)}
			</DialogActions>
		</>
	);
};
export default CreateDispatchModalContent;
