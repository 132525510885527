/* eslint-disable camelcase */
import { FC, Dispatch, SetStateAction } from "react";
import { Editor } from "@tinymce/tinymce-react";
import theme from "styles/theme";

interface TextEditorProps {
	initialText?: string | undefined;
	onChange: Dispatch<SetStateAction<string>>;
	smallEditor?: boolean;
	typeId?: string;
}

const TextEditor: FC<TextEditorProps> = ({
	initialText,
	onChange,
	smallEditor = false,
	typeId,
}) => {
	return (
		<>
			<Editor
				tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
				initialValue={initialText}
				key={typeId}
				init={{
					language: "sv_SE",
					language_url: "/langs/sv_SE.js",
					formats: {
						alignleft: {
							selector: "p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img",
							classes: "left",
						},
					},
					noneditable_class: "pagebreak", // To prevent edit
					menubar: false,
					branding: false,
					min_height: smallEditor ? undefined : 1120,
					statusbar: false,
					plugins: ["lists", "table", "autoresize"],

					width: "100%",

					// Only shows border and text in edit mode
					content_style: `
						.pagebreak {
							border-bottom: 3px dashed ${theme.palette.primary.main};
							text-align:center;
						}
						.pagebreak::before {
							content: 'Sidbrytning';
						}
					`,

					toolbar:
						"blocks | bold italic forecolor | alignleft aligncenter " +
						"alignright alignjustify | bullist numlist | " +
						`customTableButton ${!smallEditor ? "customPageBreakButton" : ""}`, // Hide page break button for ingress
					block_formats: "Rubrik 1=h1;Rubrik 2=h2;Rubrik 3=h3;Brödtext=p;",

					// eslint-disable-next-line camelcase

					setup: function (ed) {
						ed.on("init", () => {
							// Set caret to end
							ed.selection.select(ed.getBody(), true);
							ed.selection.collapse(false);
							ed.focus();
						});

						ed.on("change", () => {
							ed.dom.remove(ed.dom.select("img"));
							onChange(ed.getContent());
						});
						ed.on("input", () => {
							ed.dom.remove(ed.dom.select("img"));
							onChange(ed.getContent());
						});

						// e.key vilken tangent man klickar på,  tex "Meta"
						// e.ctrlKey (tex.) om ctrl hålls nere
						// e.code vilken kod tangenten man klickar på har tex "MetaLeft"

						// to be able to copy and paste text
						ed.on("keydown", (e) => {
							ed.dom.remove(ed.dom.select("img"));
							if (
								e.key == "Control" ||
								e.key == "Shift" ||
								e.key == "Alt" ||
								e.metaKey == true ||
								(e.ctrlKey == true && e.code != "KeyV" && e.ctrlKey == true && e.code != "KeyX")
							) {
								return;
							}
							onChange(ed.getContent());
						});

						ed.ui.registry.addButton("customTableButton", {
							icon: "table",
							tooltip: "Lägg till tabell",
							enabled: false,
							onAction: (_) => {
								ed.insertContent(
									`<table style="width: 100%;">
										<tbody>
											<tr>
												<td style="border: 1px solid black; width: 50%"></td>
												<td style="border: 1px solid black; width: 50%"></td>
											</tr>
											<tr>
												<td style="border: 1px solid black; width: 50%"></td>
												<td style="border: 1px solid black; width: 50%"></td>
											</tr>
										</tbody>
									</table>`,
								);
							},
							onSetup: (buttonApi) => {
								const editorEventCallback = (eventApi: any) => {
									buttonApi.setEnabled(true);
								};
								ed.on("NodeChange", editorEventCallback);

								return () => ed.off("NodeChange", editorEventCallback);
							},
						});
						ed.ui.registry.addButton("customPageBreakButton", {
							icon: "page-break",
							tooltip: "Lägg till sidbrytning",
							enabled: false,
							onAction: (_) => {
								ed.insertContent(
									`<pre 
										class="pagebreak" 
										style="
											width: 100%;
											page-break-after:always;
											clear:both;
											pointer-events:none;
											padding:0;
											margin:0;
											color: ${theme.palette.primary.main};
											height:0;
										"></pre>
									<p></p>`,
								);
							},
							onSetup: (buttonApi) => {
								const editorEventCallback = (eventApi: any) => {
									buttonApi.setEnabled(true);
								};
								ed.on("NodeChange", editorEventCallback);

								return () => ed.off("NodeChange", editorEventCallback);
							},
						});
					},
				}}
			/>
		</>
	);
};
export default TextEditor;
