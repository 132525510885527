import { closeSnackbar, SnackbarProvider } from "notistack";

import { administrator, sysAdmin } from "api/user/Roles";
import RouteAdmin from "routes/AdminRoute";
import GlobalConfirmDialog from "components/common/confirmDialog/GlobalConfirmDialog";
import RouteRecipient from "routes/RouteRecipient";
import CloseIcon from "@mui/icons-material/Close";
import { useGetUserFromDirectoryQuery } from "api/Api";
import { LicenseInfo } from "@mui/x-license-pro";

const App = (props: { user: any }) => {
	// får ut detta av props:
	// {user: User, role: undefined

	const { data, isSuccess, isLoading } = useGetUserFromDirectoryQuery(null);
	LicenseInfo.setLicenseKey(
		"13b7c40d938d34cbbca0967d2cbdc749Tz03Nzk2NSxFPTE3MzA2MjUxNDgwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=",
	);

	const renderRoute = () => {
		if (isLoading) {
			return (
				<div className='spinner-container'>
					<div className='spinner'></div>
				</div>
			);
		}
		if (isSuccess && data != null) {
			if (data.activeRole === administrator.roleName || data.activeRole === sysAdmin.roleName) {
				return <RouteAdmin />;
			} else {
				return <RouteRecipient />;
			}
		}
	};

	const renderRoutes = () => {
		return (
			<SnackbarProvider
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				autoHideDuration={5000}
				hideIconVariant
				style={{ justifyContent: "center", cursor: "pointer" }}
				action={() => (
					<CloseIcon
						style={{
							width: "15px",
							height: "15px",
						}}
					/>
				)}
				SnackbarProps={{
					onClick: () => {
						closeSnackbar();
					},
				}}
			>
				{renderRoute()}
				<GlobalConfirmDialog />
			</SnackbarProvider>
		);
	};

	return <>{renderRoutes()}</>;
};

export default App;
