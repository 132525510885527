import { Typography, styled, Card, Stack, CircularProgress, Divider, Box } from "@mui/material";
import { sanitizedData } from "lib/helpers/Sanitize";
import colors from "styles/colors-theme";
import theme from "styles/theme";
import { ISalaryReportRecipient } from "types/dispatch/dispatchListTable/DispatchListTableTypes";

const CardStyled = styled(Card)(() => ({
	display: "flex",
	flexDirection: "column",
	background: colors.background.lightgrey,
	margin: "16px 10px 0px 0px",
	padding: "10px 16px",
	border: 0,
	borderRadius: "6px !important",
	flex: 1,
	overflow: "initial",
}));

const TypographyStyled = styled(Typography)(() => ({
	fontSize: "14px",
}));
const SpanStyled = styled("span")(() => ({
	fontWeight: 600,
}));

export interface IContent {
	dispatchTypeText: string;
	agreementTypeName: string;
	name: string;
	departmentNumbers: number;
	workplaceSum: number;
	recipientSum: number;
}

export interface IDispatchListsContent {
	content: IContent;
	messageTemplate: string[];
	messageAgreement: string[];
	salaryProtocol: string[];
	dispatchTypeText: string;
	isLoadingData: boolean;
	isLoadingMessage: boolean;
	recipients: ISalaryReportRecipient[];
	recipientsNoAddress: ISalaryReportRecipient[];
}

export default function DispatchListsContent({
	content,
	messageTemplate,
	messageAgreement,
	dispatchTypeText,
	isLoadingData,
	isLoadingMessage,
	recipientsNoAddress,
	salaryProtocol,
}: IDispatchListsContent) {
	const { agreementTypeName, name, departmentNumbers, workplaceSum, recipientSum } = content;
	const messages = messageAgreement.concat(messageTemplate);
	const messageSorted = messages.sort();

	return (
		<Stack spacing={4}>
			{isLoadingData ? (
				<CircularProgress />
			) : (
				<>
					<Typography variant='body'>
						Du kommer nu skicka <SpanStyled>{dispatchTypeText || ""}</SpanStyled> till{" "}
						<SpanStyled>{workplaceSum}</SpanStyled>
						{workplaceSum > 1 || workplaceSum === 0 ? " arbetsställen " : " arbetsställe "}
						och <SpanStyled>{recipientSum}</SpanStyled> förtroendevalda.
					</Typography>
					<Stack
						display={"flex"}
						flexDirection={{ sm: "row" }}
						justifyItems={"flex-start"}
						mt={"20px !important"}
					>
						<CardStyled variant='outlined'>
							<TypographyStyled sx={{ fontWeight: "500" }}>Avtalsområde</TypographyStyled>
							<TypographyStyled>{agreementTypeName}</TypographyStyled>
						</CardStyled>
						<CardStyled variant='outlined'>
							<TypographyStyled sx={{ fontWeight: "500" }}>Namn</TypographyStyled>
							<TypographyStyled>{name}</TypographyStyled>
						</CardStyled>
						<CardStyled variant='outlined'>
							<TypographyStyled sx={{ fontWeight: "500" }}>Avdelning</TypographyStyled>
							<TypographyStyled>{departmentNumbers}</TypographyStyled>
						</CardStyled>
					</Stack>
				</>
			)}
			{isLoadingMessage ? (
				<CircularProgress />
			) : (
				<Stack spacing={2} mt={"40px !important"}>
					<Stack spacing={2} mb={"20px !important"}>
						{salaryProtocol && salaryProtocol.length > 0 && (
							<Typography variant='body'>
								<SpanStyled>Löneprotokoll</SpanStyled> bifogas till:{" "}
								<SpanStyled>
									{salaryProtocol[0] || ""}{" "}
									{salaryProtocol.length > 1 ? <Typography variant='body'>och </Typography> : ""}
									{salaryProtocol[1] || ""}
								</SpanStyled>
							</Typography>
						)}
					</Stack>

					{(recipientsNoAddress && recipientsNoAddress.length > 0) ||
					(messageSorted && messageSorted.length > 0) ? (
						<Divider flexItem />
					) : null}

					{recipientsNoAddress && recipientsNoAddress.length > 0 && (
						<Stack spacing={2} mt={2}>
							{recipientsNoAddress.map((r, i) => (
								<Typography key={i} variant='body'>
									<SpanStyled>{r.name}</SpanStyled> saknar distrubitionssätt.
								</Typography>
							))}
						</Stack>
					)}

					{messageSorted && messageSorted.length > 0 && (
						<Box
							sx={{ border: `2px solid ${theme.palette.error.contrastText}`, borderRadius: 2 }}
							mt={"20px !important"}
							p={2}
						>
							<Stack spacing={2} mb={1}>
								<Typography variant='body'>
									<SpanStyled>Åtgärder för att kunna skicka</SpanStyled>
								</Typography>
							</Stack>
							<Stack spacing={2}>
								{messageSorted.map((m, i) => (
									<Typography
										key={i}
										variant='body'
										dangerouslySetInnerHTML={sanitizedData(m)}
									></Typography>
								))}
							</Stack>
						</Box>
					)}
				</Stack>
			)}
		</Stack>
	);
}
