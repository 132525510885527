import React, { useState, MouseEvent, useEffect } from "react";
import theme from "styles/theme";
import { Button, Menu, MenuItem } from "@mui/material";

import { ReactComponent as UserIcon } from "assets/icons/user-regular.svg";
import { ReactComponent as UserColorIcon } from "assets/icons/user-group-21.svg";
import { ReactComponent as AttachmentIcon } from "assets/icons/paperclip-regular.svg";
import { ReactComponent as CommentRegularIcon } from "assets/icons/comment-regular.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import ModalDialog from "components/common/modal/ModalDialog";
import AddWorkerModalContent from "./AddWorkerModalContent";
import GeneralCommentModalContent from "./GeneralCommentModalContent";
import AttachmentModalContent from "./AttachmentModalContent";
import { useGetUserFromDirectoryQuery } from "api/Api";

interface IAddButtonProps {
	salaryReportStatus: number;
	isArchived: boolean;
	ombudCanEdit?: boolean;
}

export default function AddButton({
	salaryReportStatus,
	isArchived,
	ombudCanEdit,
}: IAddButtonProps) {
	const [modalAddWorker, setModalAddWorker] = useState<boolean>(false);
	const [modalGeneralComment, setModalGeneralComment] = useState<boolean>(false);
	const [modalAttachment, setModalAttachment] = useState<boolean>(false);

	const { data: userData } = useGetUserFromDirectoryQuery(null);
	const disableNonAdmin = userData.activeRole == "non-admin" && salaryReportStatus >= 250;

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const showModalAddWorker = () => {
		setModalAddWorker(true);
		setAnchorEl(null);
	};

	const showModalGeneralComment = () => {
		setModalGeneralComment(true);
		setAnchorEl(null);
	};
	const showModalAddAttachment = () => {
		setModalAttachment(true);
		setAnchorEl(null);
	};

	return (
		<>
			{modalAddWorker && (
				<ModalDialog
					isVisible={modalAddWorker}
					showModal={setModalAddWorker}
					label={"Lägg till arbetstagare"}
					icon={<UserColorIcon />}
					content={<AddWorkerModalContent showModal={setModalAddWorker} inEditMode={false} />}
				/>
			)}
			{modalGeneralComment && (
				<ModalDialog
					isVisible={modalGeneralComment}
					showModal={setModalGeneralComment}
					label={"Generell kommentar"}
					icon={
						<CommentRegularIcon
							color={theme.palette.primary.main}
							style={{ width: "20px", height: "20px" }}
						/>
					}
					content={
						<GeneralCommentModalContent showModal={setModalGeneralComment} editable={true} />
					}
				/>
			)}
			{modalAttachment && (
				<ModalDialog
					isVisible={modalAttachment}
					showModal={setModalAttachment}
					label={"Bilaga"}
					icon={
						<AttachmentIcon
							color={theme.palette.primary.main}
							style={{ width: "20px", height: "20px" }}
						/>
					}
					content={<AttachmentModalContent showModal={setModalAttachment} />}
				/>
			)}

			<Button
				aria-label='Lägg till'
				id='add-button'
				aria-controls={open ? "add-button" : undefined}
				aria-haspopup='true'
				aria-expanded={open ? "true" : undefined}
				variant='containedSecondary'
				disableElevation
				onClick={handleClick}
				startIcon={
					<PlusIcon
						style={{
							color:
								(disableNonAdmin || isArchived) && !ombudCanEdit
									? theme.palette.action.disabled
									: theme.palette.primary.main,
						}}
					/>
				}
				endIcon={<KeyboardArrowDownIcon />}
				fullWidth
				disabled={(disableNonAdmin || isArchived) && !ombudCanEdit}
			>
				Lägg till
			</Button>
			<Menu
				MenuListProps={{
					"aria-labelledby": "redigera",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={() => setAnchorEl(null)}
			>
				<MenuItem onClick={() => showModalAddWorker()}>
					<UserIcon style={{ marginRight: "12px" }} />
					Arbetstagare
				</MenuItem>
				<MenuItem onClick={() => showModalAddAttachment()}>
					<AttachmentIcon color={theme.palette.info.main} style={{ marginRight: "12px" }} />
					Bilaga
				</MenuItem>
				<MenuItem onClick={() => showModalGeneralComment()}>
					<CommentRegularIcon color={theme.palette.info.main} style={{ marginRight: "12px" }} />
					Generell kommentar
				</MenuItem>
			</Menu>
		</>
	);
}
