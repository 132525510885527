import React, { useEffect, useState } from "react";
import {
	useGetActivateSalaryProtocolRowMutation,
	useGetIgnoreSalaryProtocolRowMutation,
	useGetSalaryReportRowsQuery,
} from "api/Api";
import { enqueueSnackbar } from "notistack";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";

import { Stack, DialogActions, Button, DialogContent } from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "components/common/loading/LoadingButton";
import Comment from "components/common/comment/Comment";

interface AddWorkerModalContentProps {
	showModal: React.Dispatch<React.SetStateAction<boolean>>;
	workerId: string;
}

const ignoreModalContent: React.FC<AddWorkerModalContentProps> = ({ showModal, workerId }) => {
	const salaryReportId = LastSegmentOfUrl(location.href);
	const [comment, setComment] = useState<string>("");
	const [workerName, setWorkerName] = useState<string>("");
	const [ignored, setIgnored] = useState<boolean>(false);

	const [getIgnoreSalaryProtocolRow, { isLoading: createLoading }] =
		useGetIgnoreSalaryProtocolRowMutation();

	const [getActivateSalaryProtocolRow, { isLoading: createLoadingActivate }] =
		useGetActivateSalaryProtocolRowMutation();

	const {
		data: salaryReportRowsData,
		refetch: refetchSalaryReportRows,
		isLoading: isLoadingsalaryReportRowsData,
		isError: isErrorsalaryReportRowsData,
	} = useGetSalaryReportRowsQuery(salaryReportId);

	useEffect(() => {
		if (workerId && salaryReportRowsData && salaryReportRowsData?.salaryRows?.length > 0) {
			const worker = salaryReportRowsData?.salaryRows.filter((row: { salaryRowId: string }) => {
				return row.salaryRowId == workerId;
			});

			setIgnored(worker[0].ignored);
			setComment(worker[0].comment ?? "");
			setWorkerName(worker[0].employee?.name ?? "");
		}
	}, []);

	const save = async () => {
		const formIgnore = {
			salaryRowId: workerId,
			salaryReportId: salaryReportId,
			ignore: {
				comment: comment,
				employmentEndedDate: new Date(),
			},
		};

		const formActivate = {
			salaryRowId: workerId,
			salaryReportId: salaryReportId,
			comment: JSON.stringify(comment),
		};

		ignored
			? getActivateSalaryProtocolRow(formActivate)
					.unwrap()
					.then((response) => {
						enqueueSnackbar(`${workerName != null ? workerName : "arbetstagare "} inkluderad.`, {
							variant: "success",
						});
						showModal(false);
						refetchSalaryReportRows();
					})
					.catch((error) => {
						enqueueSnackbar(error.data.detail, {
							variant: "error",
							persist: true,
						});
					})
			: getIgnoreSalaryProtocolRow(formIgnore)
					.unwrap()
					.then((response) => {
						enqueueSnackbar(`${workerName != null ? workerName : "arbetstagare "} ignorerad.`, {
							variant: "success",
						});
						showModal(false);
						refetchSalaryReportRows();
					})
					.catch((error) => {
						enqueueSnackbar(error.data.detail, {
							variant: "error",
							persist: true,
						});
					});
	};

	return (
		<>
			<DialogContent dividers sx={{ minWidth: { md: 500 } }}>
				<Stack spacing={2} mb={2}>
					<Comment
						commentLabel={
							ignored ? "Kommentar:" : "Kommentar måste lämnas om arbetstagare ignoreras:"
						}
						isLoading={isLoadingsalaryReportRowsData}
						isError={isErrorsalaryReportRowsData}
						setComment={setComment}
						comment={comment}
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button aria-label='Avbryt' variant='grey' onClick={() => showModal(false)}>
					Avbryt
				</Button>

				{createLoading || createLoadingActivate ? (
					<LoadingButton
						label={createLoading ? "Ignorerar arbetstagare" : "Inkluderar arbetstagare"}
					/>
				) : (
					<Button
						aria-label='Spara'
						variant='containedPrimary'
						onClick={save}
						startIcon={<SaveIcon />}
						disabled={
							(!ignored && comment == "") ||
							(!ignored && comment == null) ||
							(!ignored && comment?.length >= 1000)
						}
					>
						Spara
					</Button>
				)}
			</DialogActions>
		</>
	);
};
export default ignoreModalContent;
