import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { enqueueSnackbar } from "notistack";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";
import {
	useGetSalaryReportRowsQuery,
	useGetSalaryReportQuery,
	useGetUserFromDirectoryQuery,
	useGetRemoveSalaryProtocolRowMutation,
} from "api/Api";
import theme from "styles/theme";
import colors from "styles/colors-theme";

import {
	Box,
	OutlinedInput,
	CircularProgress,
	Stack,
	Button,
	Typography,
	styled,
	Divider,
	useMediaQuery,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as CommentRegularIcon } from "assets/icons/comment-regular.svg";
import { ReactComponent as ImportIcon } from "assets/icons/arrow-up-to-line-sharp-solid.svg";
import { ReactComponent as UserColorIcon } from "assets/icons/user-group-21.svg";

import { openConfirmation } from "components/common/confirmDialog/ConfirmationSlice";
import ModalDialog from "components/common/modal/ModalDialog";

import { salaryProtocolListTableGridColDef } from "./components/tableCard/SalaryProtocolListTableGridColDef";
import AddButton from "./components/addButton/AddButton";
import GeneralCommentModalContent from "./components/addButton/GeneralCommentModalContent";
import ProtocolModalContent, { IProtocolStatus } from "./components/tableCard/ProtocolModalContent";
import { ImportProtocolInfo } from "./components/importProtoclInfo/ImportProtocolInfo";

import InfoBarSalaryAmount from "./components/footerCard/InfoBarSalaryAmount";
import AddWorkerModalContent from "./components/addButton/AddWorkerModalContent";

import {
	IError,
	ISalaryProtocolTableRow,
	IWarning,
} from "types/representative/commonSalaryReportTypes";
import { IResponsible } from "types/dispatch/dispatchListTable/SelectAgentModal";
import ProtocolAttachmentList from "./components/protocolAttachments/ProtocolAttachmentList";

const DataGridStyled = styled(DataGridPro)(() => ({
	"& .MuiDataGrid-row": {
		borderLeft: "none",
		"&:nth-of-type(2n)": { backgroundColor: colors.background.body },

		"& .MuiDataGrid-cell": {
			minHeight: "79px !important",
		},
	},

	"& .MuiDataGrid-columnHeaders": {
		marginLeft: 0,
	},
	"& .MuiDataGrid-cell:first-of-type": {
		padding: " 0px!important",
	},
})) as typeof DataGridPro;

const DividerStyled = styled(Divider)(() => ({
	margin: "0px 16px 16px 16px !important",
	maxHeight: 40,
})) as typeof Divider;

interface ISalaryProtocolTableCardContentProps {
	showErrors: boolean;
}

export const SalaryProtocolTableCardContent = ({
	showErrors,
}: ISalaryProtocolTableCardContentProps) => {
	const salaryReportId = LastSegmentOfUrl(location.href);
	const searchParams = new URLSearchParams(window.location.search);
	const defaultFilter = searchParams.get("filterValue") ?? "";

	const [salaryRowId, setSalaryRowId] = useState<string>("");
	const [modalAddWorker, setModalAddWorker] = useState<boolean>(false);
	const [modalProtocol, setModalProtocol] = useState<boolean>(false);
	const [showProtocolInfo, setShowProtocolInfo] = useState<boolean>(true);
	const [modalGeneralComment, setModalGeneralComment] = useState<boolean>(false);
	const [filteredRows, setFilteredRows] = useState<any[]>([]);
	const [filterValue, setFilterValue] = useState<string>(defaultFilter);
	const [salaryProtocolResponse, setSalaryProtocolResponse] = useState<IProtocolStatus>({
		errorRows: 0,
		newRows: 0,
		errorRowsReason: [],
		failedRows: 0,
		failedRowsReason: [],
		updatedRows: 0,
	});
	const {
		data: rowsData,
		isLoading: isLoadingRows,
		refetch: refetchSalaryReportRows,
	} = useGetSalaryReportRowsQuery(salaryReportId);

	const [getRemoveSalaryProtocolRow] = useGetRemoveSalaryProtocolRowMutation();

	const { data: salaryProtocolData, isLoading: isLoadingSalaryProtocolData } =
		useGetSalaryReportQuery(salaryReportId);
	const { data: userData } = useGetUserFromDirectoryQuery(null);

	const isOmbud =
		salaryProtocolData.responsibles.findIndex((r: IResponsible) => r.userId == userData.id) >= 0;
	const ombudCanEdit = isOmbud && salaryProtocolData.status == 250;
	const disableNonAdmin = userData.activeRole == "non-admin" && salaryProtocolData.status >= 250;
	const isArchived = salaryProtocolData?.dispatchListArchivedDate != null ?? false;

	const dispatch = useDispatch();

	const handleModalDelete = (salaryRowId: string) => {
		setModalAddWorker(false);
		dispatch(
			openConfirmation({
				message: [
					`Är du säker på att du vill radera arbetstagaren?`,
					"Obs! Detta går inte att ångra.",
				],
				callback: (confirmed: boolean) => {
					if (confirmed) {
						const form = {
							salaryRowId: salaryRowId,
							salaryReportId: salaryReportId,
						};
						getRemoveSalaryProtocolRow(form)
							.unwrap()
							.then((response) => {
								enqueueSnackbar(`Arbetstagaren raderad.`, {
									variant: "success",
								});
								refetchSalaryReportRows();
							})
							.catch((error) => {
								enqueueSnackbar(error?.data?.Detail || "Kan ej radera arbetstagare", {
									variant: "error",
									persist: true,
								});
							});
					}
				},
			}),
		);
	};

	const checkIfEmptyProtocol = (obj: IProtocolStatus) => {
		return obj.errorRows > 0 || obj.failedRows > 0 || obj.newRows > 0 || obj.updatedRows > 0;
	};

	useEffect(() => {
		checkIfEmptyProtocol(salaryProtocolResponse) && setShowProtocolInfo(true);
	}, [salaryProtocolResponse]);

	// TABLE ROWS
	const columns = salaryProtocolListTableGridColDef({ showErrors });
	const rows =
		rowsData &&
		rowsData?.salaryRows.length > 0 &&
		rowsData?.salaryRows?.map((p: ISalaryProtocolTableRow) => ({
			salaryCodeId: p.salaryCodeId ?? "",
			salaryRowId: p.salaryRowId ?? "",
			id: p.salaryRowId ?? "",

			name: p.employee.name ?? "",
			birthDate: p.employee.birthDate ?? "",
			sefMemberNumber: p.employee.sefMemberNumber ?? "",

			addedMethod: p.addedMethod,
			addedMethodDescription: p.addedMethodDescription,
			comment: p.comment ?? "",

			exampleExtraRaise: p.calculations.exampleExtraRaise,
			exampleNewSalary: p.calculations.exampleNewSalary,
			extraRaise: p.calculations.extraRaise,
			guaranteedRaise: p.calculations.guaranteedRaise,
			raiseInPercent: p.calculations.raiseInPercent,

			warnings: p.calculations?.warnings?.map((w: IWarning) => ({
				warningCode: w.warningCode,
				warningDescription: w.warningDescription,
			})),
			errors: p.errors?.map((e: IError) => ({
				errorCode: e.errorCode,
				errorDescription: e.errorDescription,
			})),
			ignored: p.ignored,
			newSalary: p.newSalary,
			previousSalary: p.previousSalary ?? "",
		}));

	useEffect(() => {
		if (filterValue != "" && rows) {
			const tempList: ISalaryProtocolTableRow[] = [];
			rows.forEach((row: ISalaryProtocolTableRow) => {
				const bDayArr: string[] = [];
				if (row.birthDate && row.birthDate.length > 0) {
					bDayArr.push(row.birthDate);
				}

				if (
					(row.name != null && row.name.toLowerCase().includes(filterValue.toLowerCase())) ||
					(row.comment != null && row.comment.toLowerCase().includes(filterValue.toLowerCase())) ||
					(row.birthDate != null &&
						row.birthDate.length > 0 &&
						bDayArr[0].toLowerCase().includes(filterValue.toLowerCase())) ||
					(row.salaryCodeId != null &&
						row.salaryCodeId.toLowerCase().includes(filterValue.toLowerCase()))
				) {
					tempList.push(row);
				}
			});
			setFilteredRows(tempList);
		} else {
			setFilteredRows([]);
		}
	}, [filterValue, rowsData]);

	const fullEditInModal = useMediaQuery(theme.breakpoints.down("lg"));

	return (
		<Box>
			{modalAddWorker && (
				<ModalDialog
					isVisible={modalAddWorker}
					showModal={setModalAddWorker}
					label={"Redigera arbetstagare"}
					icon={<UserColorIcon />}
					content={
						<AddWorkerModalContent
							showModal={setModalAddWorker}
							inEditMode={true}
							salaryRowId={salaryRowId}
							handleModalDelete={handleModalDelete}
						/>
					}
				/>
			)}
			{modalProtocol && (
				<ModalDialog
					isVisible={modalProtocol}
					showModal={setModalProtocol}
					label={"Importera löneprotokoll"}
					icon={
						<ImportIcon
							color={theme.palette.primary.main}
							style={{ width: "20px", height: "20px" }}
						/>
					}
					content={
						<ProtocolModalContent
							setSalaryProtocolResponse={setSalaryProtocolResponse}
							showModal={setModalProtocol}
						/>
					}
				/>
			)}
			{modalGeneralComment && (
				<ModalDialog
					isVisible={modalGeneralComment}
					showModal={setModalGeneralComment}
					label={"Generell kommentar"}
					icon={
						<CommentRegularIcon
							color={theme.palette.primary.main}
							style={{ width: "20px", height: "20px" }}
						/>
					}
					content={<GeneralCommentModalContent showModal={setModalGeneralComment} />}
				/>
			)}
			<Box>
				<ProtocolAttachmentList
					salaryReportId={salaryReportId ?? ""}
					disable={(disableNonAdmin || isArchived) && !ombudCanEdit}
				/>
			</Box>
			<Stack
				sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}
				flexDirection={{ xxs: "column", sm: "row" }}
			>
				<Stack
					display={"flex"}
					flexDirection={{ xxs: "column", sm: "row" }}
					flexWrap={"wrap"}
					mb={{ sm: 4 }}
				>
					<Box width={{ xxs: "100%", sm: 130 }}>
						<AddButton
							salaryReportStatus={salaryProtocolData?.status}
							isArchived={isArchived}
							ombudCanEdit={ombudCanEdit}
						/>
					</Box>
					<DividerStyled orientation='vertical' />
					<Button
						aria-label='Importera löneprotokoll'
						variant='containedSecondary'
						disableElevation
						onClick={() => setModalProtocol(true)}
						startIcon={<ImportIcon />}
						sx={{ width: { xxs: "100%", sm: 210 } }}
						disabled={(disableNonAdmin || isArchived) && !ombudCanEdit}
					>
						Importera löneprotokoll
					</Button>

					{isLoadingSalaryProtocolData ? (
						<CircularProgress size={20} sx={{ marginLeft: 2, marginTop: 1 }} />
					) : (
						salaryProtocolData.generalComment?.length > 0 && (
							<>
								<DividerStyled orientation='vertical' />
								<Button
									onClick={() => setModalGeneralComment(true)}
									aria-label='Kommentar'
									id='comment-button'
									aria-haspopup='true'
									variant='outlinedGrey'
									startIcon={
										<CommentRegularIcon
											color={theme.palette.primary.main}
											style={{ width: "15px", height: "15px" }}
										/>
									}
									sx={{ width: { xxs: "100%", sm: 140 } }}
								>
									Kommentar
								</Button>
							</>
						)
					)}
				</Stack>
				<Box
					sx={{
						width: { xxs: "100%", sm: "300px" },
						marginTop: { xxs: 2, sm: 0 },
						marginBottom: 4,
					}}
				>
					<OutlinedInput
						fullWidth
						placeholder='Filtrera'
						defaultValue={filterValue}
						onChange={(event) => setFilterValue(event.target.value)}
						startAdornment={<SearchIcon sx={{ marginLeft: "8px", color: "#B5B5C3" }} />}
					/>
				</Box>
			</Stack>
			{salaryProtocolResponse &&
				checkIfEmptyProtocol(salaryProtocolResponse) &&
				showProtocolInfo && (
					<ImportProtocolInfo
						salaryProtocolResponse={salaryProtocolResponse}
						setShowProtocolInfo={setShowProtocolInfo}
					/>
				)}
			{isLoadingRows ? (
				<CircularProgress />
			) : filterValue != "" && filteredRows.length == 0 ? (
				<p style={{ marginTop: 5 }}>Inga arbetstagare</p>
			) : rows && rows.length > 0 ? (
				<>
					<DataGridStyled
						autoHeight
						getRowHeight={() => "auto"}
						rows={filteredRows.length > 0 ? filteredRows : rows}
						columns={columns}
						disableColumnMenu
						columnHeaderHeight={40}
						rowHeight={79}
						hideFooterPagination
						hideFooter
						getRowClassName={(params: any) => {
							if (params.row.ignored) {
								return "tableRowDisabled";
							}
							return "";
						}}
						disableRowSelectionOnClick
						initialState={{
							sorting: {
								sortModel: [{ field: "name", sort: "asc" }],
							},
						}}
						onRowClick={(row) => {
							setSalaryRowId(row.row.salaryRowId);
							fullEditInModal && setModalAddWorker(true);
						}}
					/>
					<Box
						display={"flex"}
						justifyContent={"space-between"}
						mt={6}
						flexDirection={{ xxs: "column", lg: "row" }}
						mb={{ sm: 8 }}
					>
						<Typography variant='body' sx={{ fontWeight: 600, mb: 2 }}>
							{rows.length} {rows.length > 1 ? "rader" : "rad"}
						</Typography>
						<InfoBarSalaryAmount />
					</Box>
				</>
			) : rowsData && rowsData?.salaryRows.length == 0 ? (
				<p style={{ marginTop: 5 }}>Inga arbetstagare i listan</p>
			) : (
				<p style={{ marginTop: 5 }}>Kunde inte ladda arbetstagare</p>
			)}
		</Box>
	);
};
