import * as React from "react";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Accordion, styled } from "@mui/material";

import { ReactComponent as ShowMoreIcon } from "assets/icons/show-more.svg";

export const AccordionStyled = styled(Accordion)(({ theme }) => ({
	border: "2px solid #DFEFFF",
	margin: "0 0 8px",
	maxHeight: "auto",
	borderRadius: "8px !important",
	cursor: "pointer",
	width: "auto",
	boxShadow: "none",
	minHeight: 79,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	position: "unset",

	"& .MuiTypography-body1 ": {
		fontSize: 14,
		fontWeight: 500,
	},
})) as typeof Accordion;

export const AccordionSummaryStyled = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary expandIcon={<ShowMoreIcon />} {...props} />
))(({ theme }) => ({
	flexDirection: "row-reverse",
	minWidth: "100%",
	border: "none !important",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {},
	"& .MuiAccordionSummary-content": {
		padding: "0 16px",
		justifyContent: "space-between",
	},
	"& .MuiAccordionSummary-content.Mui-expanded": {},
}));

export const AccordionDetailsStyled = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(4),
	borderTop: "none",
}));
