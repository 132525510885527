import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import configureStore from "store/configureStore";
import { ThemeProvider } from "@mui/material";
import "./index.css";
import theme from "styles/theme";
import IdConnect from "IdConnect";

import AuthProvider from "components/idConnect/provider/AuthProvider";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root") as Element);

root.render(
  <Provider store={configureStore}>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          <IdConnect history={history} />
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  </Provider>
);
