export const getIdentityServerUrlByHost = (hostName) => {
  switch (hostName) {
    case 'localhost':
      return 'https://localhost:7169';
    case 'sef-salaryweb-app-dev.azurewebsites.net':
      return 'https://sef-identityserver-dev.azurewebsites.net';
    case 'sef-salaryweb-app-systest.azurewebsites.net':
      return 'https://sef-identityserver-systest.azurewebsites.net';
    case 'acctest.lonewebben.se':
      return 'https://acctest.lonewebben.se/identity';
      case 'www.lonewebben.se':
        return 'https://www.lonewebben.se/identity';

    default:
      throw "No IdentityServer configured for environment";
  }
};
