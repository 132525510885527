import React, { useEffect, useState } from "react";
import theme from "styles/theme";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

const PageTabs = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [value, setValue] = useState("1");

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	const StyledTab = styled(Tab)({
		fontSize: "0.9rem",
		"&.Mui-selected": {
			color: "#3F4254;",
			fontWeight: "500",
		},
	});

	useEffect(() => {
		const pathnames = location.pathname.split("/").filter((x: string) => x);
		pathnames.includes("utskickslistor") ? setValue("1") : setValue("2");
	}, [location]);

	return (
		<Box sx={{ width: "100%", typography: "body1", bgcolor: "#EEEEEE" }}>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<TabList
						onChange={handleChange}
						sx={{ marginLeft: "60px" }}
						TabIndicatorProps={{
							style: { backgroundColor: theme.palette.background.menuRed },
						}}
					>
						<StyledTab
							label='Utskickslistor'
							value='1'
							onClick={() => navigate("/utskickslistor/aktuella")}
						/>
						<StyledTab label='Mallar' value='2' onClick={() => navigate("/mallar/ombud-ffv")} />
					</TabList>
				</Box>
			</TabContext>
		</Box>
	);
};

export default PageTabs;
