import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "components/layout/Layout";
import SalaryProtocolHistoryOverview from "components/representative/salaryProtocolOverview/SalaryProtocolHistoryOverviewList";
import SalaryProtocolOverview from "components/representative/salaryProtocolOverview/SalaryProtocolOverviewList";
import SalaryProtocolList from "components/representative/salaryProtocol/SalaryProtocolList";

const RouteRecipient = () => {
	return (
		<Routes>
			<Route
				path='/aktuella/'
				element={
					<Layout>
						<SalaryProtocolOverview />
					</Layout>
				}
			/>

			<Route
				path='/aktuella/:year'
				element={
					<Layout>
						<SalaryProtocolOverview />
					</Layout>
				}
			/>

			<Route
				path='/aktuella/:year/:id'
				element={
					<Layout>
						<SalaryProtocolList />
					</Layout>
				}
			/>

			<Route
				path='/historik/'
				element={
					<Layout>
						<SalaryProtocolHistoryOverview />
					</Layout>
				}
			/>
			<Route
				path='/historik/:year'
				element={
					<Layout>
						<SalaryProtocolHistoryOverview />
					</Layout>
				}
			/>

			{/* // todo fixa route and page */}
			<Route
				path='/historik/:year/:id'
				element={
					<Layout>
						<SalaryProtocolList />
					</Layout>
				}
			/>

			<Route path='*' element={<Navigate to={`/aktuella`} />} />
		</Routes>
	);
};

export default RouteRecipient;
