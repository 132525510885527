import React, { useEffect, useState } from "react";
import {
	Box,
	styled,
	Typography,
	Tab,
	Tabs,
	Divider,
	IconButton,
	Paper,
	Stack,
	Button,
	DialogActions,
	CircularProgress,
	DialogContent,
	useMediaQuery,
} from "@mui/material";
import {
	useGetSefRepresentativesQuery,
	useGetByIdQuery,
	useGetUserFromDirectoryQuery,
	useGetUpdateResponsibleForSalaryReportMutation,
} from "api/Api";
import { useLocation } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import AgentSelect from "./AgentSelect";
import MemberSearch from "./MemberSearch";
import ClearIcon from "@mui/icons-material/Clear";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SaveIcon from "@mui/icons-material/Save";
import {
	TabPanelProps,
	IAgent,
	IUpdateResponsibleForSalaryReport,
	IResponsible,
	IEditResponsibleModal,
} from "types/dispatch/dispatchListTable/SelectAgentModal";
import LoadingButton from "components/common/loading/LoadingButton";
import theme from "styles/theme";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";

const PaperStyled = styled(Paper)(({ theme }) => ({
	border: "2px solid #DFEFFF",
	margin: "0 0 10px",
	maxHeight: "44px",
	borderRadius: "11px",
	padding: "13px 16px 12px 16px",
	boxShadow: "none",
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	width: "100%",
})) as typeof Paper;

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const EditOmbudsmanModal: React.FC<IEditResponsibleModal> = ({
	agentName,
	salaryReportId,
	closeAgentModal,
	selectedResponsibles,
}) => {
	// fetch data
	const { state } = useLocation();
	const { dispatchListIdFromState } = state || "";
	const dispatchListIdFromUrl = LastSegmentOfUrl(location.href);
	const dispatchListId = dispatchListIdFromState ? dispatchListIdFromState : dispatchListIdFromUrl;

	const { data: user } = useGetUserFromDirectoryQuery(null);

	const {
		data: sefRepresentativesData,
		isSuccess: isSuccessSefRep,
		isLoading: isLoadingSefRep,
		refetch: refreshSefRepData,
	} = useGetSefRepresentativesQuery(null);

	// update list
	const [getUpdateResponsibleForSalaryReport, { isLoading: isLoadingUpdateResponsible }] =
		useGetUpdateResponsibleForSalaryReportMutation();
	const { refetch: refetchList } = useGetByIdQuery(dispatchListId);

	// tab values
	const [value, setValue] = useState(0);

	const [selectedAgents, setSelectedAgents] = useState<IAgent[]>([]);
	const [responsibles, setResponsibles] = useState<IResponsible[]>(selectedResponsibles);
	const [sefRepresentatives, setSefRepresentatives] = useState<IAgent[]>([]);

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		const agentList: IAgent[] = [];

		// populate array to manipulate
		if (sefRepresentativesData && isSuccessSefRep) {
			if (sefRepresentativesData.length > 0) {
				setSefRepresentatives(sefRepresentativesData);

				for (let x = 0; x < selectedResponsibles.length; x++) {
					for (let y = 0; y < sefRepresentativesData.length; y++) {
						if (selectedResponsibles[x].userId == sefRepresentativesData[y].userId) {
							agentList.push(sefRepresentativesData[y]);
							break;
						}
					}
				}
				selectedResponsibles.map((r) => {
					const index = agentList.findIndex((a) => a.userId == r.userId);
					if (index < 0) {
						agentList.push({
							name: r.name,
							userId: r.userId,
						});
					}
				});
			}
		}
		setSelectedAgents(agentList);
	}, [sefRepresentativesData]);

	useEffect(() => {
		const tempList: IResponsible[] = [];
		if (selectedAgents.length > 0) {
			selectedAgents.map((agent) => {
				tempList.push({
					name: agent.name,
					userId: agent.userId,
				});
			});
			setResponsibles(tempList);
		} else {
			setResponsibles([]);
		}
	}, [selectedAgents]);

	// delete agent from selected list
	const deleteResponsible = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		userId: string,
	) => {
		e.preventDefault();
		const newList = selectedAgents
			.filter((agent) => agent.userId != userId)
			.map((agent: IAgent) => ({ ...agent }));
		setSelectedAgents(newList);
	};

	// on save
	const saveAgentSettings = async () => {
		const userIds = selectedAgents.map((a) => a.userId);

		const responsibles: IUpdateResponsibleForSalaryReport = {
			salaryReportId: salaryReportId,
			userIds: userIds,
		};

		//	TODO uppdatera response when possible
		await getUpdateResponsibleForSalaryReport(responsibles)
			.unwrap()
			.then((response) => {
				enqueueSnackbar("Valda ombudsmän sparades", { variant: "success" });
				refreshSefRepData();
				refetchList();
				closeAgentModal();
			})
			.catch((error) => {
				enqueueSnackbar(error.data.detail, {
					variant: "error",
					persist: true,
				});
			});
	};

	const addSelfToSelectedAgents = () => {
		const tempList = [...selectedAgents];
		const self: IAgent = {
			userId: user.id,
			emails: user.email,
			name: `${user.firstName} ${user.lastName}`,
			nationalIdentityNumber: user.nationalIdentityNumber,
		};

		if (tempList.length > 0) {
			const idx = tempList.findIndex((r) => r.userId == user.id);
			if (idx > -1) {
				tempList.splice(idx, 1);
			} else {
				tempList.push(self);
			}
		} else {
			tempList.push(self);
		}

		setSelectedAgents(tempList);
	};
	const fullScreen = useMediaQuery(theme.breakpoints.up("md"));
	return (
		<>
			<DialogContent
				dividers
				sx={{
					width: fullScreen ? "697px" : "100%",
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "column",
				}}
			>
				<Stack flexDirection='column' justifyContent=' space-between' display='flex' mb={4}>
					<Typography sx={{ marginBottom: "20px" }}>
						Välj en eller flera ombudsmän för <span style={{ fontWeight: "600" }}>{agentName}</span>
					</Typography>
					<Box
						sx={{
							borderBottom: 1,
							borderColor: "divider",
							width: "100%",
							typography: "body1",
						}}
						mb={4}
					>
						<Tabs value={value} onChange={handleChangeTab} aria-label='meny' variant='scrollable'>
							<Tab label='OMBUDSMÄN' {...a11yProps(0)} />
							<Tab label='MEDLEMSREGISTER' {...a11yProps(1)} />
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						{isLoadingSefRep ? (
							<CircularProgress />
						) : sefRepresentatives ? (
							<AgentSelect
								setSelectedAgents={setSelectedAgents}
								rows={sefRepresentatives}
								selectedAgents={selectedAgents}
								placeholder={
									sefRepresentatives && sefRepresentatives.length === 0
										? "Finns ingen förtroendevald på arbetstället"
										: "Sök på namn, avdelning eller välj från lista"
								}
							/>
						) : (
							<Typography sx={{ fontStyle: "italic" }}>
								Kunde inte hämta förtroendevalda.
							</Typography>
						)}
					</TabPanel>
					<TabPanel value={value} index={1}>
						<MemberSearch setSelectedAgents={setSelectedAgents} selectedAgents={selectedAgents} />
					</TabPanel>
				</Stack>
				<Stack>
					{responsibles && responsibles.length > 0 && <Divider flexItem />}
					<Stack mt={4} flexDirection='column-reverse'>
						{responsibles && responsibles.length > 0
							? responsibles.map((row: IResponsible) => {
									return (
										<PaperStyled key={row.userId}>
											<IconButton
												sx={{ width: "0px", "&:hover": { backgroundColor: "transparent" } }}
												aria-label='Radera'
												onClick={(e) => deleteResponsible(e, row.userId)}
											>
												<ClearIcon fontSize='small' />
											</IconButton>
											<Typography variant='body' sx={{ fontWeight: "600", mx: 2 }}>
												{row.name}{" "}
											</Typography>
										</PaperStyled>
									);
							  })
							: null}
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions>
				{user && (
					<Button
						aria-label='Skapa'
						variant='containedSecondary'
						onClick={addSelfToSelectedAgents}
						startIcon={<PersonAddIcon />}
						sx={{ marginRight: "auto" }}
						disabled={
							user && selectedAgents.findIndex((agent) => agent.userId == user.id) < 0
								? false
								: true
						}
					>
						Lägg till mig
					</Button>
				)}

				<Button variant='grey' onClick={closeAgentModal}>
					Avbryt
				</Button>

				{isLoadingUpdateResponsible ? (
					<LoadingButton label={"Lägger till ombudsman"} />
				) : (
					<Button
						aria-label='Skapa'
						variant='containedPrimary'
						onClick={saveAgentSettings}
						startIcon={<SaveIcon />}
					>
						Spara
					</Button>
				)}
			</DialogActions>
		</>
	);
};

export default EditOmbudsmanModal;
