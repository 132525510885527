const colors = {
	// brand: {
	// 	red: "#C30E15",
	// },
	primary: "#3699FF",
	secondary: "#E1F0FF",
	// accent: "#C30E15",
	error1: "#FFE2E5",
	error2: "#F64E60",
	warning1: "#FFF4DE",
	warning2: "#FFA800",
	success1: "#C9F7F5",
	success2: "#42C7BF",
	info2: "#3699FF",
	lightestGrey: "#f5f5f5",
	disabledText: "#B2D8FF",
	disabledBackground: "#E1F0FF",
	
	purple: {
		contrastText: "#EEE5FF",
		main: "#8950FC",
	},
	grey: "#B5B5C3",

	topbar: {
		// icon
		lightestgrey: "#f5f5f5",

		// start top bar bakgrund
		grey: "#eeeeee",

		// rutan i top bar
		lightergrey: "#f7f7f7",

		// infotext i top bar and unactive tab i top bar
		darkestgrey: "#8e919e",
	},

	status: {
		// open
		blue: "#00a2d9",
		// control
		yellow: "#fcbb00",
		// for approval
		black: "#292d39",
		// rejected, deleted
		red: "#c30e15",
		// approved
		green: "#a3c30f",
		// completed
		gold: "#a3823b",
		// draft
		grey: "#bfbfbf",
	},
	avatar: {
		lightBlue: "#afe0f9",
	},
	background: {
		darkestGrey: "#11141b",
		darkGrey: "#282c37",
		lightgrey: "#f7f7f7",
		disable: "#F7F7F7",
		menu: "#003B5A",
		menuRed: "#C30E15",
		label: "#F3F6F9",
		grey: "#EEEEEE",
		body: "#FAFAFA",
		bluegrey: "#F0F3F7",
		ignored:"#fff6f7",
	},
	// banners: {
	//     orange: amber[700],
	//     green: green[600],
	//     blue: darken('#00a2d9', 0.3),
	//     grey: grey[600],
	// },
	border: {
		light: "#D1D3E0",
		main: "#ebedf3",
		blue: "#DFEFFF",
		// lighterGrey: "#f3f3f3",
		// grey: "#D1D3E0",
		// grey: "#bfbfbf",
		// darkGrey: "#d8d8d8",
		dark: "#1f2321",
		boxShadowLight: "#523F690D",
		boxShadow: "#523F6926",
	},

	buttons: {
		darkOrange: "#F37321",
		orange: "#FFF4DE",
		primary: "#3699FF",
		primaryHover: "#187DE4",
		secondary: "#E1F0FF",
		secondaryHover: "#C1DFFC",
		ackordswebben: "#F37321",
		ackordswebbenHover: "#F48239",
		grey: "#E4E6EF",
	},
	icons: {
		lightestGrey: "#f5f5f5",
		// lightGrey: "#ede7eb",
		grey: "#C6C6D0",
		// darkerGrey: "#b2b2b3",
		// darkGrey: "#d8d8d8",
		// darkestGrey: "#747474",
		// yellow: "#f1c40f",
	},
	hover: {
		lightBlue: "#ddeef6",
	},
	text: {
		disabled: "#787B84",
		lightestGrey: "#f5f5f5",
		grey: "#B5B5C3",
		green: "#42C7BF",
		darkGrey: "#787B84",
		darkestGrey: "#3F4254",
		lighterGrey: "#8e919e",
		// warning: "#ffb822",
		primary: "#3699FF",
		secondary: "#E1F0FF",
	},

	// thirdParty: {
	//   word: "#2A579A",
	//   excel: "#1D6F42",
	// },
};

export default colors;
