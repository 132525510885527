import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	useGetAgreementTypeTemplateQuery,
	useGetAgreementTypeTemplatesQuery,
	useGetLatestSalaryRevisionInfoQuery,
	useGetUpdateAgreementTypeTemplateMutation,
} from "api/Api";
import { enqueueSnackbar } from "notistack";

import {
	Box,
	Button,
	Divider,
	Stack,
	CircularProgress,
	useMediaQuery,
	Typography,
} from "@mui/material";
import colors from "styles/colors-theme";

import { ListItemCard } from "components/common/list/ListItemCard";
import TextEditor from "components/common/texteditor/TextEditor";
import ModalDialogPreview from "./ModalDialogPreview";
import InfoBox from "components/common/infoBox/InfoBox";

import { ReactComponent as AgreementIcon } from "assets/icons/agreement.svg";
import SaveIcon from "@mui/icons-material/Save";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

import {
	IAgreementInfoBox,
	IAgreementTemplate,
	ICurrentAgreement,
	IGetAgreementTemplate,
	IUpdateAgreementTypeTemplateForm,
} from "types/templates/CommonTemplateTypes";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";
import theme from "styles/theme";
import { LayoutBoxStyled } from "components/common/styledComponents/Layout";
import { formatSalary } from "lib/helpers/NumberInputHelper";

export interface ISalaryRevisionLimits {
	code: string;
	minimalSalary: string;
}

const EditAgreement = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { agreementIdFromState } = state || "";
	const agreementIdFromUrl = LastSegmentOfUrl(location.href);
	const agreementId: string = agreementIdFromState ? agreementIdFromState : agreementIdFromUrl;

	const role = location.pathname.includes("/mallar/ombud-ffv") ? "ombud-ffv" : "arbetsgivare";

	const recipientType = role == "ombud-ffv" ? 2 : 1;
	const agreementInfoBoxContent: IAgreementInfoBox[] = [];
	const getAgreementTemplateForm: IGetAgreementTemplate = {
		form: {
			agreementTypeId: agreementId,
			recipientType: recipientType,
		},
	};

	// get agreement
	const {
		data: agreementData,
		isSuccess: isSuccessAgreement,
		refetch: refetchAgreement,
		isLoading: isloadingAgreementData,
	} = useGetAgreementTypeTemplateQuery(getAgreementTemplateForm);

	const { data: salaryRevision } = useGetLatestSalaryRevisionInfoQuery(agreementId);

	// get agreements
	const {
		data: agreementsData,
		refetch: refetchAgreements,
		isLoading: isloadingAgreementsData,
	} = useGetAgreementTypeTemplatesQuery(recipientType);

	// update agreement
	const [getUpdateAgreementTypeTemplateMutation] = useGetUpdateAgreementTypeTemplateMutation();

	const [showModal, setShowModal] = useState<boolean>(false);

	const [agreementArr, setAgreementArr] = useState<ICurrentAgreement[]>([]);
	const [currentAgreement, setCurrentAgreement] = useState<ICurrentAgreement>();

	const [body, setBody] = useState<string>("");
	const [bodyToSave, setBodyToSave] = useState<string>("");

	function addOrReplaceAgreement(array: ICurrentAgreement[], item: ICurrentAgreement) {
		const i = array.findIndex(
			(_element: { agreementTypeId: string }) => _element.agreementTypeId === item.agreementTypeId,
		);
		if (i > -1) array[i] = item;
		else array.push(item);
	}
	if (salaryRevision && salaryRevision.date) {
		const salaryRevisionLimits: ISalaryRevisionLimits[] = [];

		if (salaryRevision.salaryRevisionLimits && salaryRevision.salaryRevisionLimits.length > 0) {
			salaryRevision.salaryRevisionLimits.map(
				(s: { salaryCode: { code: string }; minimalSalary: number }) => {
					salaryRevisionLimits.push({
						code: `${" "}${s.salaryCode.code}`,
						minimalSalary: `:\u00A0${formatSalary(s.minimalSalary)}\u00A0kr`,
					});
				},
			);
		} else {
			salaryRevisionLimits.push({ code: "", minimalSalary: "-" });
		}

		salaryRevisionLimits.sort(function (a, b) {
			const textA = a.code.toUpperCase();
			const textB = b.code.toUpperCase();
			return textA < textB ? -1 : textA > textB ? 1 : 0;
		});

		agreementInfoBoxContent.push({
			"Period Lönerevision": salaryRevision.date.slice(0, 10),
			"Garanterat utfall":
				salaryRevision.guaranteedRaiseType !== 0
					? `${salaryRevision.guaranteedRaise.toString().replace(".", ",")} ${
							salaryRevision.guaranteedRaiseTypeUnit
					  }`
					: "-",
			"Avtalad höjning":
				salaryRevision.sharedRaiseType !== 0
					? `${salaryRevision.sharedRaise.toString().replace(".", ",")} ${
							salaryRevision.sharedRaiseTypeUnit
					  }`
					: "-",
			"Minimilön per lönekod": salaryRevisionLimits.map((item, i) => (
				<span style={{ paddingRight: "6px", display: "inline-block" }} key={i}>
					<span style={{ fontWeight: "500" }}>{item.code}</span>
					{item.minimalSalary}
					{i != salaryRevisionLimits.length - 1 ? "," : ""}
				</span>
			)),
		});
	}
	if (salaryRevision && !salaryRevision.date) {
		agreementInfoBoxContent.push({
			"Period Lönerevision": "Uppkommande lönerevision saknas",
			"Garanterat utfall": "-",
			"Avtalad höjning": "-",
			"Minimilön per lönekod": "-",
		});
	}

	useEffect(() => {
		if (agreementData && isSuccessAgreement) {
			// Om agreementArr har data så finns uppdaterad info (ej sparad till BE)
			if (agreementArr.length > 0) {
				for (let i = 0; i < agreementArr.length; i++) {
					if (agreementArr[i].agreementTypeId === agreementId) {
						setCurrentAgreement(agreementArr[i]);
						setBodyToSave(agreementArr[i].body);
						break;
					} else {
						setCurrentAgreement(agreementData);
						setBodyToSave(agreementData.body);
					}
				}
				// Om agreementArr är tom så finns inga justerade avtal att visa.
				// Visa bara upp avtalet i currentAgreement
			} else {
				setCurrentAgreement(agreementData);
				setBodyToSave(agreementData.body);
			}
		}
	}, [agreementData]);

	// uppdatera text
	useEffect(() => {
		setBody(body);

		if (currentAgreement) {
			const tempArr = agreementArr;
			const tempObj = {
				agreementTypeId: currentAgreement.agreementTypeId,
				agreementTypeName: currentAgreement.agreementTypeName,
				body: body,
				isChanged: true,
			};

			addOrReplaceAgreement(tempArr, tempObj);
			setAgreementArr(tempArr);
			setBodyToSave(tempObj.body);
		}
	}, [body]);

	const saveAgreement = async () => {
		const agreement: IUpdateAgreementTypeTemplateForm = {
			agreementTypeId: agreementId,
			recipientType: recipientType,
			body: bodyToSave,
		};

		await getUpdateAgreementTypeTemplateMutation(agreement)
			.unwrap()
			.then((response) => {
				enqueueSnackbar("Avtalet sparades", { variant: "success" });
				refetchAgreements();
				refetchAgreement();

				// remove saved from agreementArr since it should only include changed and not saved agreements
				const tempArr = agreementArr;
				const newArr = tempArr.filter((a) => {
					return a.agreementTypeId != agreementId;
				});
				setAgreementArr(newArr);
			})
			.catch((error) => {
				enqueueSnackbar(error.data.detail, {
					variant: "error",
					persist: true,
				});
			});
	};

	// show prompt if unsaved changes in any agreement
	window.onbeforeunload = () => {
		if (
			(currentAgreement?.body && bodyToSave !== currentAgreement?.body) ||
			agreementArr.length > 0
		) {
			return true;
		} else {
			return null;
		}
	};

	const showTemplatesButtons = () => {
		const renderAgreementName = (agreementId: string, agreementName: string) => {
			const i = agreementArr.findIndex(
				(_element: { agreementTypeId: string }) => _element.agreementTypeId === agreementId,
			);
			if (i > -1) {
				return `${agreementName} *`;
			} else {
				return agreementName;
			}
		};

		return (
			<>
				{agreementsData &&
					agreementsData.length > 0 &&
					agreementsData.map((row: IAgreementTemplate) => (
						<Button
							key={row.agreementTypeId}
							aria-label='Avtal'
							variant='changeView'
							onClick={() =>
								navigate(`/mallar/${role}/${row.agreementTypeId.toString()}`, {
									state: { agreementId: row.agreementTypeId },
								})
							}
							sx={{
								margin: 0.5,
								paddingX: 2,
								borderColor: row.agreementTypeId === agreementId ? colors.primary : "transparent",
								width: { xxs: "100%", xs: "auto" },
							}}
						>
							{renderAgreementName(row.agreementTypeId, row.agreementTypeName)}
						</Button>
					))}
			</>
		);
	};
	const fullScreen = useMediaQuery(theme.breakpoints.up("md"));

	const showEditBox = () => {
		return (
			<Stack
				direction={"column"}
				divider={<Divider orientation='vertical' flexItem />}
				alignItems={"center"}
			>
				<Stack
					sx={{
						width: "100%",
						height: "100%",
						maxWidth: "1000px",
						marginBottom: 10,
					}}
				>
					<Stack
						display={"flex"}
						mb={2}
						direction={{ xs: "column", md: "row" }}
						sx={{
							width: fullScreen ? "fit-content" : "100%",
							marginLeft: fullScreen ? -1 : "",
						}}
					>
						<InfoBox infoBoxProps={agreementInfoBoxContent[0]} maxWidthCustom={465} />
					</Stack>
					<Stack
						sx={{
							minWidth: "auto",
							maxWidth: "1000px",
							minHeight: "29.7cm",
							height: "auto",
							paddingX: { xxs: 1, lg: "80px" },
							borderLeft: "3px dashed",
							borderRight: "3px dashed",
							borderTop: "3px dashed",
							borderColor: `${colors.border.main}`,
						}}
					>
						{currentAgreement && (
							<TextEditor
								initialText={currentAgreement.body}
								onChange={setBody}
								typeId={currentAgreement.agreementTypeId}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>
		);
	};

	const showHeaderMenu = () => {
		const renderIsChanged = () => {
			const i = agreementArr.findIndex(
				(_element: { agreementTypeId: string }) => _element.agreementTypeId === agreementId,
			);
			if (i > -1) {
				if (agreementArr[i].isChanged) {
					return false;
				} else {
					return true;
				}
			} else {
				return true;
			}
		};
		const isChanged = renderIsChanged();

		return (
			<>
				<Stack spacing={1} direction={"row"} divider={<Divider orientation='vertical' flexItem />}>
					<Button
						aria-label='Förhandsgranska'
						variant='containedSecondary'
						startIcon={<SearchIcon />}
						onClick={() => setShowModal(true)}
					>
						Förhandsgranska
					</Button>
					<Button
						aria-label='Spara'
						variant='containedPrimary'
						startIcon={<SaveIcon />}
						onClick={saveAgreement}
						disabled={isChanged}
					>
						Spara
					</Button>
				</Stack>
			</>
		);
	};

	return (
		<>
			{showModal && (
				<ModalDialogPreview
					isVisible={showModal}
					showModal={setShowModal}
					bodyText={bodyToSave}
					agreementTypeId={currentAgreement?.agreementTypeId || ""}
				/>
			)}
			{agreementsData && agreementData ? (
				<>
					{agreementsData.length > 0 ? (
						<Box
							sx={{
								width: "auto",
								backgroundColor: `${colors.background.grey}`,
								border: "1px solid white",
								minHeight: 98,
								alignItems: "center",
								display: "flex",
								paddingX: 5,
								paddingY: 2,
								flexWrap: "wrap",
							}}
						>
							{showTemplatesButtons()}
						</Box>
					) : (
						<LayoutBoxStyled>
							<Typography>Kunde ej ladda avtalvsy</Typography>
						</LayoutBoxStyled>
					)}
					<LayoutBoxStyled>
						{agreementData && currentAgreement ? (
							<ListItemCard
								header={`${role === "ombud-ffv" ? "Förtroendevald" : "Arbetsgivare"} - ${
									currentAgreement.agreementTypeName || ""
								} `}
								icon={<AgreementIcon />}
								padding={30}
								content={showEditBox()}
								headerAction={showHeaderMenu()}
							/>
						) : (
							<Typography>Kunde ej ladda avtal</Typography>
						)}
					</LayoutBoxStyled>
				</>
			) : isloadingAgreementsData || isloadingAgreementData ? (
				<CircularProgress sx={{ marginLeft: "50vw", marginTop: "40vh" }} />
			) : (
				<LayoutBoxStyled>
					<Typography>Kunde ej ladda redigera avtal</Typography>
				</LayoutBoxStyled>
			)}
		</>
	);
};

export default EditAgreement;
