import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'

import Authenticate from 'Authenticate'
import { userService } from 'store/services/UserService'
import { userConstants } from 'store/constants/UserConstants'

import App from 'App'

const IdConnect = (props) => {

  const renderApp = <App />;

  return (
    <Authenticate
      renderNotAuthenticated={<div>Ej autentiserad</div>}
    >
      {renderApp}
    </Authenticate>
  );
};

export default connect()(IdConnect);
