import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllCompletedQuery } from "api/Api";
import { IArchivedList } from "types/dispatch/DispatchList";

import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import { StyledOverviewDataGrid } from "../common/styledComponents/StyledOverviewDataGrid";

import { Button, CircularProgress, OutlinedInput, Stack, Typography, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import RowMenu from "./components/RowMenu";

const columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Namn",
		sortable: true,
		align: "left",
		headerAlign: "left",
		flex: 3,
		minWidth: 250,
	},
	{
		field: "createdDate",
		headerName: "Skapad",
		sortable: true,
		align: "left",
		headerAlign: "left",
		flex: 2,
		minWidth: 100,
		renderCell: (date) => date.value && date.value.slice(0, 10),
	},
	{
		field: "salaryReportsCount",
		headerName: "Antal arbetsställen",
		type: "number",
		sortable: true,
		align: "left",
		headerAlign: "left",
		flex: 2,
		renderCell: (count) => count.value && `${count.value} st`,
	},
	{
		field: "completedSalaryReportsCount",
		headerName: "Antal inrapporterade",
		sortable: true,
		align: "left",
		headerAlign: "left",
		flex: 2,
		renderCell: (completed) => completed.value && `${completed.value} st`,
	},
	{
		field: "menu",
		headerName: "",
		sortable: false,
		align: "center",
		width: 60,
		renderCell: (row) => (
			<Box pr={2}>
				<RowMenu dispatchListId={row.row.id} />
			</Box>
		),
	},
];

export default function DispatchOverviewListTableArchive() {
	const navigate = useNavigate();
	const { data: rowsUnsorted, isLoading } = useGetAllCompletedQuery(null);
	const [filteredRows, setFilteredRows] = useState<IArchivedList[]>([]);
	const [filterValue, setFilterValue] = useState("");
	const [sortModel, setSortModel] = useState<GridSortModel>([
		{
			field: "name",
			sort: "asc",
		},
	]);
	const [rowsCount, setRowsCount] = useState<number>(5);
	const [limit, setLimit] = useState(15);
	const [rows, setRows] = useState<IArchivedList[]>([]);

	useEffect(() => {
		if (rowsUnsorted && rowsUnsorted.length > 0) {
			setRows(rowsUnsorted);

			const filterValueStorage = sessionStorage.getItem("filterDispatchOverviewHistory");
			filterValueStorage && setFilterValue(filterValueStorage);

			const sortModelStorage = sessionStorage.getItem("sortModelDispatchOverviewHistory");
			sortModelStorage && setSortModel(JSON.parse(sortModelStorage));
		}
	}, [rowsUnsorted]);

	useEffect(() => {
		sessionStorage.setItem("filterDispatchOverviewHistory", filterValue);

		if (filterValue != "") {
			const tempList: IArchivedList[] = [];
			rows.forEach((row: IArchivedList) => {
				if (
					row.name.toLowerCase().includes(filterValue.toLowerCase()) ||
					row.createdDate.toString().includes(filterValue.toLowerCase()) ||
					row.salaryReportsCount.toString().includes(filterValue.toLowerCase()) ||
					row.completedSalaryReportsCount.toString().includes(filterValue.toLowerCase())
				) {
					tempList.push(row);
				}
			});
			setFilteredRows(tempList);
		} else {
			setFilteredRows([]);
		}
	}, [filterValue]);

	const loadMoreRows = () => {
		setLimit(limit + 5);
	};

	useEffect(() => {
		if (rows && rows.length > 0) {
			const sumOfRowsShowing = rows.slice(0, limit).length;
			setRowsCount(sumOfRowsShowing);
		}
	}, [limit, rows]);

	useEffect(() => {
		if (rows && rows.length > 0) {
			sessionStorage.setItem("sortModelDispatchOverviewHistory", JSON.stringify(sortModel));
		}
	}, [sortModel]);

	return (
		<Box sx={{ height: "auto", width: "100%", border: "0", padding: "12px" }}>
			<Box sx={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
				<Box
					sx={{
						mb: { xs: 2, sm: 2 },
						width: { xxs: "100%", md: "300px" },
					}}
				>
					<OutlinedInput
						value={filterValue}
						fullWidth
						placeholder='Filtrera'
						onChange={(event) => setFilterValue(event.target.value)}
						startAdornment={<SearchIcon sx={{ marginLeft: "8px", color: "#B5B5C3" }} />}
					/>
				</Box>
			</Box>
			{isLoading ? (
				<CircularProgress />
			) : filterValue != "" && filteredRows.length == 0 ? (
				<p style={{ marginTop: 5 }}>Inga listor</p>
			) : rows && rows.length > 0 ? (
				<>
					<StyledOverviewDataGrid
						autoHeight
						rows={filteredRows.length > 0 ? filteredRows : rows.slice(0, limit)}
						sortModel={sortModel}
						onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
						columns={columns}
						disableColumnMenu
						hideFooterPagination
						onRowClick={(row) =>
							navigate("/utskickslistor/arkiverade/" + row.id, {
								state: { dispatchListIdFromState: row.id },
							})
						}
						hideFooter
						rowHeight={67}
						columnHeaderHeight={40}
					/>
					<Stack direction={"row"} mt={20} alignItems='center' spacing={1}>
						<Button
							variant='secondaryTable'
							onClick={loadMoreRows}
							disabled={rows.length == rowsCount}
						>
							Visa mer
						</Button>
						<Typography variant='body' fontWeight={500}>
							Visar {rowsCount} av totalt {rows.length}
						</Typography>
					</Stack>
				</>
			) : (
				<p style={{ marginTop: 5 }}>Inga listor</p>
			)}
		</Box>
	);
}
