import React from 'react';
import { AuthContext } from 'components/idConnect/AuthContext';
import AuthService from 'components/idConnect/service/AuthService';

export default function AuthProvider({ children }) {
  const serviceCb = new AuthService();

  return (
    <AuthContext.Provider value={serviceCb}>{children}</AuthContext.Provider>
  );
}
