import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, useMediaQuery } from "@mui/material";
import theme from "styles/theme";

export const DialogStyled = styled(Dialog, {
	shouldForwardProp: (prop) => prop !== "height",
})<{ height?: string }>(({ theme, height }) => ({
	minHeight: height,

	"& .MuiPaper-root": {
		borderRadius: "16px",
	},

	"& .MuiDialogTitle-root": {
		padding: "20px 30px 10px",

		[theme.breakpoints.down("xs")]: {
			padding: "20px 16px 10px",
		},

		"& p > svg": {
			marginRight: "8px",
		},
	},

	"& .MuiDialogContent-root": {
		padding: "20px 30px",

		[theme.breakpoints.down("xs")]: {
			padding: 16,
		},
	},
	"& .MuiDialogActions-root": {
		padding: "20px",
	},

	"& .MuiTab-root": {
		fontSize: "0.9rem",
		"&.Mui-selected": {
			color: "#3F4254;",
			fontWeight: "500",
		},
		flexDirection: "row-reverse",
		display: "flex",
		minHeight: "auto",
		padding: "12px 16px 0px 20px",

		[theme.breakpoints.down("xs")]: {
			padding: "0px 8px 0px 10px",
			fontSize: "0.8rem",
			top: 16,
		},
	},

	"& .MuiTabs-indicator": {
		backgroundColor: "#C30E15",
		bottom: 0,
	},
})) as typeof Dialog;

export interface DialogTitleProps {
	label: string;
	icon: JSX.Element;
	showModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface DialogProps {
	label: string;
	icon: JSX.Element;
	content: JSX.Element;
	isVisible: boolean;
	minHeight?: string;
	showModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Title: React.FC<DialogTitleProps> = ({ label, icon, showModal }) => {
	return (
		<DialogTitle
			sx={{
				mb: 1,
				p: 2,
				display: "flex",
				flexWrap: "wrap",
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
				}}
				mr={"16px"}
			>
				{icon}
			</Box>
			<Typography sx={{ fontWeight: 500 }}>{label}</Typography>
			<IconButton
				aria-label='close'
				onClick={() => showModal(false)}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
					"&:hover": {
						backgroundColor: "transparent",
					},
				}}
			>
				<CloseIcon />
			</IconButton>
		</DialogTitle>
	);
};

const ModalDialog: React.FC<DialogProps> = ({ isVisible, label, icon, content, showModal }) => {
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<div>
			<DialogStyled
				onClose={() => showModal(true)}
				aria-labelledby='customized-dialog-title'
				open={isVisible}
				maxWidth='lg'
				// fullWidth
				fullScreen={fullScreen}
			>
				<Title icon={icon} label={label} showModal={showModal} />
				{content}
			</DialogStyled>
		</div>
	);
};
export default ModalDialog;
