import React, { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Divider, Box, Stack } from "@mui/material";
import { IAgent, IAgentMemberSearch } from "types/dispatch/dispatchListTable/SelectAgentModal";
import { useGetSearchMembersMutation } from "api/Api";

export default function MemberSearch({ selectedAgents, setSelectedAgents }: IAgentMemberSearch) {
	const [getSearchMembers, { data }] = useGetSearchMembersMutation();
	const [inputValue, setInputValue] = useState("");

	let timer: ReturnType<typeof setTimeout>;
	useEffect(() => {
		if (inputValue != "" && inputValue.length >= 2) {
			timer = setTimeout(() => {
				getSearchMembers(inputValue);
			}, 500);
			return () => clearTimeout(timer);
		}
	}, [inputValue]);

	const handleCheckboxChange = async (row: IAgent) => {
		const tempList = [...selectedAgents];
		if (tempList.length > 0) {
			const idx = tempList.findIndex((r) => r.userId == row.userId);
			if (idx > -1) tempList.splice(idx, 1);
			else tempList.push(row);
		} else {
			tempList.push(row);
		}
		setSelectedAgents(tempList);
	};

	return (
		<Box>
			<FormControl fullWidth sx={{ border: "1px solid #EBEDF3", borderRadius: 2 }}>
				<OutlinedInput
					sx={{
						borderBottom: inputValue.length > 0 ? "1px solid #EBEDF3" : "",
						borderRadius: "0",
						"& fieldset": { border: "none !important" },
					}}
					placeholder={
						data && data.length === 0
							? "Hittade inga medlemmar"
							: "Sök på namn, epost, medlemsnummer eller personnummer"
					}
					onChange={(e) => {
						setInputValue(e.target.value);
					}}
				/>
				{data ? (
					<Box
						sx={{
							maxHeight: "300px",
							height: "auto",
							overflowY: "scroll",
						}}
					>
						{data.map((row: IAgent) => {
							// const isLast = selectedValue.length - 1 === index;
							if (selectedAgents.filter((r) => r.userId == row.userId).length == 0) {
								return (
									<MenuItem
										key={row.userId}
										value={row.userId}
										sx={{
											flexDirection: "column",
											alignItems: "flex-start",
											// borderBottom: index != rows.length - 1 ? "1px solid #EBEDF3" : "",
										}}
										onClick={() => handleCheckboxChange(row)}
									>
										<Stack
											direction={"row"}
											sx={{ width: "100%", alignItems: "center", padding: "5px" }}
										>
											<Checkbox sx={{ marginRight: 2, height: "10px" }} />
											<ListItemText
												primary={row.name}
												primaryTypographyProps={{ fontSize: 13 }}
												sx={{ fontWeight: "500" }}
											/>
											<Stack direction={"row"} sx={{ width: "120px", float: "right" }}>
												<Divider orientation='vertical' flexItem sx={{ marginRight: 3 }} />
												<ListItemText
													secondary={row.nationalIdentityNumber?.slice(0, -4) + "xxxx"}
													secondaryTypographyProps={{ fontSize: 13 }}
												/>
											</Stack>
										</Stack>
									</MenuItem>
								);
							}
						})}
					</Box>
				) : null}
			</FormControl>
		</Box>
	);
}
