import React, { useEffect, useState } from "react";
import {
	useGetSalaryReportQuery,
	useGetSendSalaryReportMutation,
	useGetSalaryReportRowsQuery,
	useGetAttachmentsQuery,
} from "api/Api";
import { enqueueSnackbar } from "notistack";
import theme from "styles/theme";
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	DialogActions,
	DialogContent,
	Divider,
	FormControlLabel,
	OutlinedInput,
	Stack,
	Typography,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LoadingButton from "components/common/loading/LoadingButton";
import InfoBox from "components/common/infoBox/InfoBox";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";
import { IWarning } from "types/representative/commonSalaryReportTypes";

interface ISalaryReportInfo {
	Arbestställe: string;
	Avtal: string;
	Ort: string;
}
interface ISalaryReportRowsInfo {
	workers: number;
	attachment: string;
	warnings: number;
}
interface SendSalaryProtocolModalContentProps {
	showModal: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
}

const SendSalaryProtocolModalContent: React.FC<SendSalaryProtocolModalContentProps> = ({
	showModal,
	setShowErrors,
}) => {
	const salaryReportId = LastSegmentOfUrl(location.href);
	const [comment, setComment] = useState<string>("");
	const [approvedByEmployer, setApprovedByEmployer] = useState<boolean>(false);
	const [salaryReportInfo, setSalaryReportInfo] = useState<ISalaryReportInfo[]>([]);
	const [salaryReportRowsInfo, setSalaryReportRowsInfo] = useState<ISalaryReportRowsInfo>();
	const [warningsProtocol, setWarningsProtocol] = useState<IWarning[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const { data: salaryReportRowsData, refetch: refetchSalaryReportRowsData } =
		useGetSalaryReportRowsQuery(salaryReportId);
	const { data: attachhmentData } = useGetAttachmentsQuery(salaryReportId);
	const { data: salaryReportData, refetch: refetchSalaryReport } =
		useGetSalaryReportQuery(salaryReportId);

	const [sendSalaryReport, { isLoading: isLoadingSend }] = useGetSendSalaryReportMutation();

	useEffect(() => {
		if (salaryReportData && salaryReportRowsData) {
			let rowErrors = 0;
			if (salaryReportRowsData.salaryRows.length > 0) {
				salaryReportRowsData.salaryRows.map((r: { calculations: { warnings: string | any[] } }) => {
					rowErrors = rowErrors += r?.calculations?.warnings?.length;
				}) ?? 0;

				setWarningsProtocol(salaryReportRowsData.warnings);
			}

			setSalaryReportRowsInfo({
				workers: salaryReportRowsData?.salaryRows?.length ?? 0,
				attachment: (attachhmentData && attachhmentData.name) ?? "",
				warnings: salaryReportRowsData?.warnings?.length + rowErrors ?? 0,
			});

			setComment(salaryReportData.generalComment ?? "");
			setSalaryReportInfo([
				{
					Arbestställe: salaryReportData.workplace.name ?? "",
					Avtal: salaryReportData.agreementTypeName ?? "",
					Ort: salaryReportData.workplace.municipality ?? "",
				},
			]);
			setIsLoading(false);
		}
	}, []);

	const send = async () => {
		const form = {
			salaryReportId: salaryReportId,
			body: {
				comment: comment,
				approvedByEmployer: approvedByEmployer,
			},
		};
		setShowErrors(true);

		await sendSalaryReport(form)
			.unwrap()
			.then((response) => {
				enqueueSnackbar('Utskickslista "' + salaryReportInfo[0].Arbestställe + '" skickad', {
					variant: "success",
				});
				showModal(false);
				refetchSalaryReport();
				refetchSalaryReportRowsData();
			})
			.catch((error) => {
				enqueueSnackbar(error.data.detail, { variant: "error", persist: true });
			});
	};

	return (
		<>
			<DialogContent dividers sx={{ width: { md: 700 } }}>
				{isLoading ? (
					<Box display='flex' justifyContent='center'>
						<CircularProgress sx={{}} />
					</Box>
				) : (
					<Stack spacing={2} mt={1} mb={2}>
						<Box display={"flex"} flexDirection={{ xxs: "column", sm: "row" }}>
							<Box flexDirection={"row"} mr={4}>
								<Typography variant='bodyMedium'>Arbetstagare: </Typography>
								<Typography variant='body'>{salaryReportRowsInfo?.workers} st</Typography>
							</Box>
							<Box flexDirection={"row"} mr={4}>
								<Typography variant='bodyMedium'>Bilaga: </Typography>
								<Typography variant='body'>{salaryReportRowsInfo?.attachment}</Typography>
							</Box>
							<Box flexDirection={"row"} mr={4}>
								<Typography variant='bodyMedium'>Avvikelser: </Typography>
								<Typography
									variant='body'
									color={salaryReportRowsInfo?.warnings ? theme.palette.error.contrastText : ""}
								>
									{salaryReportRowsInfo?.warnings} st
								</Typography>
							</Box>
						</Box>
						<Box>
							<Typography variant='bodyMedium'>Kommentar:</Typography>
							<OutlinedInput
								multiline
								rows={4}
								sx={{ height: "auto" }}
								onChange={(event) => setComment(event.target.value)}
								value={comment}
							/>
							{warningsProtocol.length > 0 && !comment && (
								<Typography variant='body' color={theme.palette.error.contrastText}>
									Kommentar nödvändig när inte garanterad höjning uppnår avtalad gräns.
								</Typography>
							)}
						</Box>
						<Divider sx={{ padding: "8px 0px" }} />
						<Box display={"flex"} flexDirection={{ xxs: "column", sm: "row" }}>
							<InfoBox infoBoxProps={salaryReportInfo[0]} />
						</Box>
						<Divider />
						<FormControlLabel
							control={
								<Checkbox
									sx={{ marginRight: 2, marginLeft: 1 }}
									onChange={(e) => setApprovedByEmployer(e.target.checked)}
								/>
							}
							sx={{
								border: "3px solid #DFEFFF",
								borderRadius: "11px",
								padding: "10px 6px",
								marginTop: "30px !important",
							}}
							label={
								<Typography variant='body'>Protokollet är kontrollerat av arbetsgivare</Typography>
							}
						/>
					</Stack>
				)}
			</DialogContent>
			<DialogActions>
				<Button aria-label='Avbryt' variant='grey' onClick={() => showModal(false)}>
					Avbryt
				</Button>
				<>
					{isLoadingSend ? (
						<LoadingButton label={"Skickar"} />
					) : (
						<Button
							aria-label='Skicka'
							variant='containedPrimary'
							onClick={send}
							startIcon={<MailOutlineIcon />}
							disabled={!approvedByEmployer || (warningsProtocol.length > 0 && !comment)}
						>
							Skicka
						</Button>
					)}
				</>
			</DialogActions>
		</>
	);
};

export default SendSalaryProtocolModalContent;
