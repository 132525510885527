import React from "react";
import CssBaseline from "@mui/material/CssBaseline";

import { Box, styled } from "@mui/material";
import Appbar from "./Appbar";
import PageTabs from "./PageTabs";
import PageTabsRepresentative from "./PageTabsRepresentative";

interface Props {
	children: React.ReactNode | React.ReactNode[];
}

const Main = styled(Box)(({ theme }) => ({
	margin: 0,
})) as typeof Box;

const Layout = ({ children }: Props) => {
	const pathnames = location.pathname.split("/").filter((x: string) => x);
	const lastSegmentOfUrl = pathnames.slice(-1)[0];

	return (
		<div className='root'>
			<CssBaseline />
			<Appbar />
			{location.pathname == "/utskickslistor/aktuella/" ||
			location.pathname == "/utskickslistor/aktuella" ||
			location.pathname == "/utskickslistor/arkiverade/" ||
			location.pathname == "/utskickslistor/arkiverade" ||
			location.pathname == "/mallar/ombud-ffv/" ||
			location.pathname == "/mallar/ombud-ffv" ||
			location.pathname == "/mallar/arbetsgivare/" ||
			location.pathname == "/mallar/arbetsgivare" ? (
				<PageTabs />
			) : location.pathname == "/aktuella/" ||
			  location.pathname == "/aktuella" ||
			  location.pathname == `/aktuella/${lastSegmentOfUrl}` ||
			  location.pathname == `/aktuella/${lastSegmentOfUrl}/` ||
			  location.pathname == "/historik/" ||
			  location.pathname == "/historik" ? (
				<PageTabsRepresentative />
			) : null}
			<Main>{children}</Main>
		</div>
	);
};

export default Layout;
