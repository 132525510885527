import React, { useState } from "react";
import { styled } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import ModalDialog from "components/common/modal/ModalDialog";

import { ReactComponent as SettingsIcon } from "assets/icons/settings-grey.svg";
import { ReactComponent as CreateIcon } from "assets/icons/rectangle-history-circle-plus-regular.svg";
import { ReactComponent as InvestigationIcon } from "assets/icons/bell-small.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as UsersIcon } from "assets/icons/user-group-regular.svg";
import { ReactComponent as SendListIcon } from "assets/icons/send-dispatch-list.svg";
import DoneIcon from "@mui/icons-material/Done";

import { IRowSettingsButton } from "types/dispatch/dispatchListTable/DispatchListTableTypes";
import { CircularProgress, ListItemIcon, Tooltip } from "@mui/material";
import {
	useGetByIdQuery,
	useGetCreateSalaryReportRowsMutation,
	useGetPatchSalaryReportInvestigationMutation,
	useGetPatchSalaryReportCompletedMutation,
	useGetRemoveSalaryReportMutation,
	useGetUserFromDirectoryQuery,
} from "api/Api";
import { useDispatch } from "react-redux";
import { openConfirmation } from "components/common/confirmDialog/ConfirmationSlice";
import { enqueueSnackbar } from "notistack";
import { useLocation } from "react-router";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";
import SendDispatchListsModalContent from "./sendDispatch/SendDispatchListsModalContent";
import { useNavigate } from "react-router-dom";
import theme from "styles/theme";
import EditFortroendevaldModal from "components/dispatch/DispatchListTable/editAgentModal/EditFortroendevaldModal";
import EditOmbudsmanModal from "../editAgentModal/EditOmbudsmanModal";

interface ButtonProps {
	open?: boolean;
}

const IconButtonStyled = styled(IconButton, {
	shouldForwardProp: (prop) => prop !== "open",
})<ButtonProps>(({ theme, open }) => ({
	backgroundColor: open === true ? theme.palette.background.label : "transparent",
	"&:hover #Group_5 .settings": {
		fill: theme.palette.primary.main,
	},
	"& #Group_5 .settings": {
		fill: open && theme.palette.primary.main,
	},
}));

export default function RowSettingsButton({
	agentName,
	editDistribution,
	changeEditDistribution,
	salaryReportId,
	workplaceId,
	isDisabled,
	status,
	selectedResponsibles,
}: IRowSettingsButton) {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { dispatchListIdFromState } = state || "";
	const dispatchListIdFromUrl = LastSegmentOfUrl(location.href);
	const dispatchListId: string = dispatchListIdFromState
		? dispatchListIdFromState
		: dispatchListIdFromUrl;

	const { data: userData } = useGetUserFromDirectoryQuery(null);
	const { refetch } = useGetByIdQuery(dispatchListId);

	const [getRemoveSalaryReport] = useGetRemoveSalaryReportMutation();
	const [getCreateSalaryReportRowsMutation, { isLoading: creatingSalaryReport }] =
		useGetCreateSalaryReportRowsMutation();
	const [getPatchSalaryReportInvestigation] = useGetPatchSalaryReportInvestigationMutation();
	const [getPatchSalaryReportCompleted] = useGetPatchSalaryReportCompletedMutation();

	const [showSendDispatchModal, setShowSendDispatchModal] = useState(false);
	const [openFortroendevaldModal, setOpenFortroendevaldModal] = useState(false);
	const [openOmbudsmanModal, setOpenOmbudsmanModal] = useState(false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const dispatch = useDispatch();

	function handleDelete() {
		dispatch(
			openConfirmation({
				message: [
					"Är du säker på att du vill ta bort arbetsstället från denna utskicklista?",
					"Obs! Detta går inte att ångra.",
				],
				callback: (confirmed: boolean) => {
					if (confirmed) {
						const form = { salaryReportId: salaryReportId };
						getRemoveSalaryReport(form)
							.unwrap()
							.then((response) => {
								enqueueSnackbar("Arbetsstället borttaget", {
									variant: "success",
								});
								refetch();
							})
							.catch((error) => {
								enqueueSnackbar(error.data.detail, {
									variant: "error",
									persist: true,
								});
							});
					}
				},
			}),
		);
		handleClose();
	}

	function handleDistribution() {
		if (
			editDistribution != undefined &&
			changeEditDistribution != undefined &&
			salaryReportId != undefined
		) {
			if (editDistribution.length == 0) {
				changeEditDistribution(salaryReportId);
			} else {
				changeEditDistribution("");
			}
		}
		handleClose();
	}

	function handleCreateSalaryProtocol() {
		if (salaryReportId && salaryReportId.length > 0) {
			const form = {
				salaryReportId: salaryReportId,
			};
			try {
				getCreateSalaryReportRowsMutation(form)
					.unwrap()
					.then(() => {
						refetch();
						navigate(`/utskickslistor/aktuella/${dispatchListId}/${salaryReportId}`);
					})
					.catch((error) => {
						handleClose();
						enqueueSnackbar(error.data.detail || "Kunde inte navigera till löneprotokoll", {
							variant: "error",
							persist: true,
						});
					});
			} catch (error) {
				// setIsLoading(false);
				enqueueSnackbar("Löneprotokoll kan inte laddas", {
					variant: "error",
					persist: true,
				});
				handleClose();
			}
		}
	}
	function handleInvestigation() {
		if (salaryReportId && salaryReportId.length > 0) {
			if (status === 250) {
				try {
					getPatchSalaryReportCompleted(salaryReportId)
						.unwrap()
						.then(() => {
							enqueueSnackbar("Utredning klar", {
								variant: "success",
							});
							refetch();
						})
						.catch((error) => {
							enqueueSnackbar(error.data.detail || "Utredningstatus kan inte ändras", {
								variant: "error",
								persist: true,
							});
						});
				} catch (error) {
					enqueueSnackbar("Utredningstatus kan inte ändras", {
						variant: "error",
						persist: true,
					});
					handleClose();
				}
			} else {
				try {
					getPatchSalaryReportInvestigation(salaryReportId)
						.unwrap()
						.then(() => {
							enqueueSnackbar("Raden är satt till under utredning", {
								variant: "success",
							});
							refetch();
						})
						.catch((error) => {
							enqueueSnackbar(error.data.detail || "Utredningstatus kan inte ändras", {
								variant: "error",
								persist: true,
							});
						});
				} catch (error) {
					enqueueSnackbar("Utredningstatus kan inte ändras", {
						variant: "error",
						persist: true,
					});
					handleClose();
				}
			}
		}
		handleClose();
	}

	function handleSend() {
		setShowSendDispatchModal(true);
		handleClose();
	}

	function handleFortroendevald() {
		setOpenFortroendevaldModal(true);
		handleClose();
	}

	function handleOmbudsman() {
		setOpenOmbudsmanModal(true);
		handleClose();
	}

	return (
		<>
			<ModalDialog
				isVisible={openFortroendevaldModal}
				label={"Redigera förtroendevald"}
				icon={<UsersIcon />}
				content={
					<EditFortroendevaldModal
						agentName={agentName || ""}
						salaryReportId={salaryReportId}
						workplaceId={workplaceId}
						closeAgentModal={() => setOpenFortroendevaldModal(false)}
					/>
				}
				showModal={setOpenFortroendevaldModal}
			/>
			<ModalDialog
				isVisible={openOmbudsmanModal}
				label={"Redigera ombudsman"}
				icon={<UsersIcon />}
				content={
					<EditOmbudsmanModal
						agentName={agentName || ""}
						salaryReportId={salaryReportId}
						workplaceId={workplaceId}
						closeAgentModal={() => setOpenOmbudsmanModal(false)}
						selectedResponsibles={selectedResponsibles}
					/>
				}
				showModal={setOpenOmbudsmanModal}
			/>
			<ModalDialog
				isVisible={showSendDispatchModal}
				label={"Skicka till " + agentName}
				icon={<SendListIcon />}
				content={
					<SendDispatchListsModalContent
						showModal={setShowSendDispatchModal}
						salaryReportId={salaryReportId}
					/>
				}
				showModal={setShowSendDispatchModal}
				minHeight='auto'
			/>
			<IconButtonStyled
				aria-label='redigera'
				size='small'
				aria-haspopup='true'
				aria-controls={open ? "redigera" : undefined}
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				open={open}
				disabled={isDisabled}
			>
				<SettingsIcon />
			</IconButtonStyled>
			<Menu
				MenuListProps={{
					"aria-labelledby": "redigera",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<MenuItem onClick={handleFortroendevald}>
					<ListItemIcon>
						<EditIcon />
					</ListItemIcon>
					Redigera förtroendevald
				</MenuItem>
				<MenuItem onClick={handleOmbudsman}>
					<ListItemIcon>
						<EditIcon />
					</ListItemIcon>
					Redigera ombudsman
				</MenuItem>
				<MenuItem onClick={handleDistribution}>
					<ListItemIcon>
						<EditIcon />
					</ListItemIcon>
					Redigera distr.sätt
				</MenuItem>
				<Tooltip title={status !== 100 ? "Löneprotokoll redan skapat" : ""}>
					<div>
						<MenuItem
							onClick={() => {
								handleCreateSalaryProtocol();
							}}
							disabled={status !== 100 || creatingSalaryReport}
						>
							{creatingSalaryReport ? (
								<CircularProgress size={18} sx={{ marginRight: "12px" }} />
							) : (
								<ListItemIcon>
									<CreateIcon />
								</ListItemIcon>
							)}
							{creatingSalaryReport ? "Skapar protokoll... " : "Skapa protokoll"}
						</MenuItem>
					</div>
				</Tooltip>

				<Tooltip
					title={
						status != 300 && status != 250
							? "Endast inskickade löneprotokoll kan sättas för utredning"
							: ""
					}
				>
					<div>
						<MenuItem
							onClick={handleInvestigation}
							disabled={userData?.activeRole == "non-admin" || status == 100 || status == 200}
						>
							<ListItemIcon>
								{status == 250 ? (
									<DoneIcon
										fontSize='small'
										sx={{ color: "rgba(0, 0, 0, 0.54)", strokeWidth: -1, stroke: "#ffffff" }}
									/>
								) : (
									<InvestigationIcon color={theme.palette.text.grey} />
								)}
							</ListItemIcon>
							{status == 250 ? "Utredning klar" : "För utredning"}
						</MenuItem>
					</div>
				</Tooltip>

				<MenuItem onClick={handleSend}>
					<ListItemIcon>
						<MailOutlineIcon
							fontSize='small'
							sx={{ color: "rgba(0, 0, 0, 0.54)", strokeWidth: -1, stroke: "#ffffff" }}
						/>
					</ListItemIcon>
					Skicka
				</MenuItem>
				<MenuItem onClick={handleDelete}>
					<ListItemIcon>
						<TrashIcon />
					</ListItemIcon>
					Radera
				</MenuItem>
			</Menu>
		</>
	);
}
