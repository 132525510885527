import React, { useState } from "react";
import { useGetAttachmentsQuery, useGetUploadAttachmentMutation } from "api/Api";
import { enqueueSnackbar } from "notistack";

import {
	Box,
	Typography,
	CircularProgress,
	Stack,
	DialogActions,
	Button,
	DialogContent,
} from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "components/common/loading/LoadingButton";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";
import theme from "styles/theme";

interface AttachmentModalContentProps {
	showModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AttachmentModalContent: React.FC<AttachmentModalContentProps> = ({ showModal }) => {
	const salaryReportId = LastSegmentOfUrl(location.href);
	const [file, setFile] = useState<File | null>(null);

	const [getUploadAttachment, { isLoading: createLoading }] = useGetUploadAttachmentMutation();
	const {
		data,
		isLoading: isLoadingFile,
		isError: isErrorLoadingFile,
		refetch: refetchFile,
	} = useGetAttachmentsQuery(salaryReportId);

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			setFile(e.target.files[0]);
		}
	};

	const saveFile = async () => {
		if (file) {
			const formData = new FormData();
			formData.append("type", file.type);
			formData.append("formFile", file);
			formData.append("fileName", file.name);

			try {
				const form = {
					salaryReportId: salaryReportId,
					file: formData,
				};

				await getUploadAttachment(form)
					.unwrap()
					.then(() => {
						enqueueSnackbar('Bilaga "' + file.name + '" uppladdad', { variant: "success" });
						refetchFile();
						showModal(false);
					})
					.catch((error) => {
						enqueueSnackbar(error.data.detail, { variant: "error", persist: true });
					});
			} catch (error) {
				enqueueSnackbar("Bilaga kan ej laddas upp", { variant: "error", persist: true });
			}
		}
	};

	return (
		<>
			<DialogContent dividers sx={{ minWidth: { md: 500 } }}>
				{isLoadingFile ? (
					<CircularProgress />
				) : (
					<>
						{data.length >= 10 && (
							<Typography
								variant='body'
								component='p'
								color={theme.palette.error.contrastText}
								sx={{ marginBottom: 2 }}
							>
								Ett protokoll kan endast innehålla 10 bilagor
							</Typography>
						)}

						<Box
							display={"flex"}
							alignItems={"center"}
							sx={{
								flexDirection: { xxs: "column", sm: "row" },
							}}
						>
							<Button
								variant='secondaryTable'
								component='label'
								sx={{ width: { xxs: "100%", sm: 110 }, marginRight: 2 }}
								disabled={data.length >= 10}
							>
								Välj bilaga
								<input
									type='file'
									hidden
									onChange={handleFileChange}
									disabled={data.length >= 10}
								/>
							</Button>
							<Stack
								sx={{
									marginTop: { xxs: 2, sm: 0 },
								}}
							>
								{file ? (
									<Typography variant='bodyMedium'>{file.name}</Typography>
								) : (
									<Typography variant='body0'>Ingen bilaga vald</Typography>
								)}
							</Stack>
						</Box>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button aria-label='Avbryt' variant='grey' onClick={() => showModal(false)}>
					Avbryt
				</Button>

				{createLoading ? (
					<LoadingButton label={"Laddar upp bilaga"} />
				) : (
					<Button
						aria-label='Spara'
						variant='containedPrimary'
						onClick={saveFile}
						startIcon={<SaveIcon />}
						disabled={!file || data.length >= 10}
					>
						Ladda upp bilaga
					</Button>
				)}
			</DialogActions>
		</>
	);
};
export default AttachmentModalContent;
