import React, { useState } from "react";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";
import StatusChip from "components/common/chip/StatusChip";

import { useGetEmptyProtocolMutation, useGetSalaryReportQuery } from "api/Api";
import { enqueueSnackbar } from "notistack";

import theme from "styles/theme";
import colors from "styles/colors-theme";

import { Box, CircularProgress, Stack, Typography, styled, useMediaQuery } from "@mui/material";
import { ReactComponent as AgreementIcon } from "assets/icons/agreement.svg";
import { ReactComponent as UserIcon } from "assets/icons/user-group-21.svg";

import { LayoutBoxStyled } from "components/common/styledComponents/Layout";
import ModalDialog from "components/common/modal/ModalDialog";
import { ListItemCard } from "components/common/list/ListItemCard";
import InfoBox, {
	CardInfoBoxStyled,
	TypographyInfoBoxStyled,
} from "components/common/infoBox/InfoBox";

import SalaryProtocolListTableHeader from "./components/headerCard/SalaryProtocolListTableHeader";
import { SalaryProtocolTableCardContent } from "./SalaryProtocolTableCardContent";
import AgreementModalContent from "./components/AgreementModalContent";

const BoxStyled = styled(Box)(() => ({
	border: 0,
	width: "100%",
	minHeight: "60px",
	background: colors.background.grey,
	display: "inline-flex",
	padding: "0px 48px",
	flexWrap: "wrap",

	[theme.breakpoints.down("md")]: {
		padding: "16px 0px 0px 10px",
		display: "flex",
		flexWrap: "wrap",
	},

	[theme.breakpoints.down("sm")]: {
		padding: "16px 1px 0px 1px",
		flexDirection: "column",
	},
}));

interface ISalaryProtocolList {
	defaultFilterValue?: string;
}

// KORT ARBETSTAGARE
const SalaryProtocolList = ({ defaultFilterValue }: ISalaryProtocolList) => {
	const [modalAgreement, setModalAgreement] = useState<boolean>(false);
	const [showErrors, setShowErrors] = useState<boolean>(false);
	const salaryReportId = LastSegmentOfUrl(location.href);

	const { data, isLoading } = useGetSalaryReportQuery(salaryReportId);
	const [getEmptyProtocolMutation, { isError }] = useGetEmptyProtocolMutation();

	const downloadPdf = () => {
		try {
			getEmptyProtocolMutation(data.salaryRevision.salaryRevisionId);
			if (isError) {
				enqueueSnackbar("Kunde inte ladda hem tomt löneprotokoll", {
					variant: "error",
					persist: true,
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	const fullScreen = useMediaQuery(theme.breakpoints.up("sm"));

	return (
		<>
			{modalAgreement && (
				<ModalDialog
					isVisible={modalAgreement}
					showModal={setModalAgreement}
					label={data?.agreementTypeName || ""}
					icon={<AgreementIcon />}
					content={
						<AgreementModalContent showModal={setModalAgreement} salaryReportId={salaryReportId} />
					}
				/>
			)}
			{isLoading ? (
				<Box height={"100vh"}>
					<CircularProgress sx={{ marginLeft: "50vw", marginTop: "40vh" }} />
				</Box>
			) : data ? (
				<>
					<BoxStyled>
						<InfoBox
							infoBoxProps={{
								Arbetsställe: data?.workplace?.name || "",
							}}
						/>
						<CardInfoBoxStyled variant='outlined'>
							<TypographyInfoBoxStyled sx={{ fontWeight: "500" }}>Avtal</TypographyInfoBoxStyled>
							<TypographyInfoBoxStyled
								onClick={() => setModalAgreement(true)}
								sx={{ color: colors.primary, cursor: "pointer" }}
							>
								{data?.agreementTypeName || ""}
							</TypographyInfoBoxStyled>
						</CardInfoBoxStyled>
						<InfoBox
							infoBoxProps={{
								Organisationsnr: data?.workplace?.companyOrganizationNumber || "",
								Ort: data?.workplace?.municipality || "",
							}}
						/>
						<CardInfoBoxStyled variant='outlined'>
							<TypographyInfoBoxStyled sx={{ fontWeight: "500" }}>
								Ladda ner
							</TypographyInfoBoxStyled>
							<TypographyInfoBoxStyled
								onClick={() => downloadPdf()}
								sx={{ color: colors.primary, cursor: "pointer" }}
							>
								Tomt löneprotokoll.xlsx
							</TypographyInfoBoxStyled>
						</CardInfoBoxStyled>
					</BoxStyled>
					<LayoutBoxStyled>
						{/* Kort arbetstagare med header, table och footer */}
						<ListItemCard
							icon={<UserIcon />}
							header={
								<Box alignItems={"baseline"}>
									Arbetstagare
									<StatusChip
										statusMessage={
											data.statusMessage && data.statusMessage.length > 0 ? data.statusMessage : ""
										}
										status={data?.status ? data.status : 0}
										margin={"0 0 0 20px"}
									/>
								</Box>
							}
							content={<SalaryProtocolTableCardContent showErrors={showErrors} />}
							headerAction={<SalaryProtocolListTableHeader setShowErrors={setShowErrors} />}
							sx={{
								"& .MuiCardHeader-action": { minWidth: fullScreen ? "auto" : "100% !important" },
							}}
						/>
					</LayoutBoxStyled>
				</>
			) : (
				<LayoutBoxStyled>
					<Typography>Kunde ej ladda data</Typography>
				</LayoutBoxStyled>
			)}
		</>
	);
};

export default SalaryProtocolList;
