import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUrl, authHeader } from "lib/helpers/ApiHelper";
import {
	IUpdateRecipientsForSalaryReport,
	IUpdateResponsibleForSalaryReport,
} from "types/dispatch/dispatchListTable/SelectAgentModal";
import { IUpdateDistributionSettingsForRecipients } from "types/dispatch/dispatchListTable/DispatchListTableTypes";
import { IGetCreateDispatchForm } from "types/dispatch/DispatchList";
import {
	IUpdateDispatchTemplateForm,
	IUpdateAgreementTypeTemplateForm,
	IGetDispatchTemplateForm,
	IGetAgreementTemplate,
	IGetPreviewAgreementTemplateMail,
	IGetPreviewDispatchListMail,
} from "types/templates/CommonTemplateTypes";
import { enqueueSnackbar } from "notistack";
import { IEditSalaryReportRowForm } from "types/representative/commonSalaryReportTypes";

const url = getUrl();

// Define a service using a base URL and expected endpoints
export const api = createApi({
	reducerPath: "api",
	baseQuery: fetchBaseQuery({ baseUrl: url }),
	endpoints: (builder) => ({
		// ROLES
		getRolesFromDirectory: builder.query({
			query: () => ({
				url: `Session/GetRoles`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		getChangeRoleFromDirectory: builder.mutation({
			query: (form: any) => ({
				url: `Session/ChangeRole`,
				method: "POST",
				body: form.role,
				headers: { ...authHeader(), "Content-Type": "application/json" },
			}),
		}),

		// USER
		getUserFromDirectory: builder.query({
			query: () => ({
				url: `Session/User`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		// DispatchList
		getAllActive: builder.query({
			query: () => ({
				url: `DispatchLists/Active`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		getAllCompleted: builder.query({
			query: () => ({
				url: `DispatchLists/Completed`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		getById: builder.query({
			query: (id: string) => ({
				url: `DispatchLists/` + id,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		getByIdM: builder.mutation({
			query: (id: string) => ({
				url: `DispatchLists/` + id,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		getCreateOptions: builder.query({
			query: () => ({
				url: `DispatchLists/CreateOptions`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		getCreateDispatch: builder.mutation({
			query: (form: IGetCreateDispatchForm) => ({
				url: `DispatchLists/Create`,
				method: "POST",
				body: form,
				headers: authHeader(),
			}),
		}),

		getRemoveSalaryReport: builder.mutation({
			query: (form: any) => ({
				url: `SalaryReports/${form.salaryReportId}`,
				method: "DELETE",
				body: form,
				headers: authHeader(),
			}),
		}),

		getRemoveDispatchList: builder.mutation({
			query: (form: { dispatchListId: string }) => ({
				url: `DispatchLists`,
				method: "DELETE",
				body: form,
				headers: authHeader(),
			}),
		}),

		getArchiveDispatchList: builder.mutation({
			query: (form: { dispatchListId: string }) => ({
				url: `DispatchLists/Archive`,
				method: "PATCH",
				body: form,
				headers: authHeader(),
			}),
		}),

		// RECIPIENT
		// Distribution in dispatch list
		getUpdateDistributionSettingsForRecipients: builder.mutation({
			query: (form: IUpdateDistributionSettingsForRecipients) => ({
				url: `SalaryReports/${form.salaryReportId}/Recipients/DistributionSettings`,
				method: "PATCH",
				body: form.recipients,
				headers: authHeader(),
			}),
		}),

		// MEMBER
		// in agent modal
		getSefRepresentatives: builder.query({
			query: () => ({
				url: `Members/SefRepresentatives`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		getMembersByWorkplace: builder.query({
			query: (workplaceId: string | "") => ({
				url: `Members/ByWorkplace/` + workplaceId,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		getSearchMembers: builder.mutation({
			query: (searchString: string) => ({
				url: `Members/Search/` + searchString,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		getUpdateRecipientsForSalaryReport: builder.mutation({
			query: (form: IUpdateRecipientsForSalaryReport) => ({
				url: `SalaryReports/${form.salaryReportId}/Recipients`,
				method: "PATCH",
				body: form.userIds,
				headers: authHeader(),
			}),
		}),

		// RESPONSIBLES
		getUpdateResponsibleForSalaryReport: builder.mutation({
			query: (form: IUpdateResponsibleForSalaryReport) => ({
				url: `/SalaryReports/${form.salaryReportId}/Responsibles`,
				method: "PATCH",
				body: form.userIds,
				headers: authHeader(),
			}),
		}),

		// DISPATCHLIST
		// send
		getSendSalaryReportDispatches: builder.mutation({
			query: (form: any) => ({
				url: `SalaryReports/${form.salaryReportId}/Recipients/Dispatches`,
				method: "POST",
				body: form,
				headers: authHeader(),
			}),
		}),

		getSendDispatches: builder.mutation({
			query: (form: any) => ({
				url: `DispatchLists/SendDispatches`,
				method: "POST",
				body: form,
				headers: authHeader(),
			}),
		}),

		// TEMPLATES
		// generella mallar
		getDispatchTemplateQ: builder.query({
			query: ({ form }: IGetDispatchTemplateForm) => ({
				url: `Templates/DispatchTemplate?RecipientType=${form.recipientType}&DispatchType=${form.dispatchType}`,
				method: "GET",
				headers: authHeader(),
			}),
		}),
		getDispatchTemplate: builder.mutation({
			query: ({ form }: IGetDispatchTemplateForm) => ({
				url: `Templates/DispatchTemplate?RecipientType=${form.recipientType}&DispatchType=${form.dispatchType}`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		getUpdateDispatchTemplate: builder.mutation({
			query: (form: IUpdateDispatchTemplateForm) => ({
				url: `Templates/DispatchTemplate`,
				method: "PATCH",
				body: form,
				headers: authHeader(),
			}),
		}),

		// avtalsområde
		getAgreementTypeTemplates: builder.query({
			query: (recipientType: 1 | 2) => ({
				url: `Templates/AgreementTemplates?RecipientType=${recipientType}`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		getAgreementTypeTemplate: builder.query({
			query: ({ form }: IGetAgreementTemplate) => ({
				url: `Templates/AgreementTemplate?AgreementTypeId=${form.agreementTypeId}&RecipientType=${form.recipientType}`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		getAgreementTypeTemplateM: builder.mutation({
			query: ({ form }: IGetAgreementTemplate) => ({
				url: `Templates/AgreementTemplate?AgreementTypeId=${form.agreementTypeId}&RecipientType=${form.recipientType}`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		getUpdateAgreementTypeTemplate: builder.mutation({
			query: (form: IUpdateAgreementTypeTemplateForm) => ({
				url: `Templates/AgreementTemplate`,
				method: "PATCH",
				body: form,
				headers: authHeader(),
			}),
		}),

		// preview
		// mail
		getPreviewAgreementTemplateMail: builder.query({
			query: (form: IGetPreviewAgreementTemplateMail) => ({
				url: `Templates/Preview/AgreementTemplate/Mail`,
				method: "POST",
				body: form,
				headers: authHeader(),
				responseHandler: async (response) => {
					if (response.ok) {
						return response.text();
					}
				},
			}),
		}),

		getPreviewDispatchListMail: builder.query({
			query: (form: IGetPreviewDispatchListMail) => ({
				url: `Templates/Preview/DispatchList/Mail?dispatchListId=${form.dispatchListId}&recipientType=${form.recipientType}&dispatchType=${form.dispatchType}`,
				method: "GET",
				headers: authHeader(),
				responseHandler: async (response) => {
					if (response.ok) {
						return response.text();
					}
				},
			}),
		}),

		// preview
		// letter
		getPreviewAgreementTemplateLetter: builder.mutation({
			queryFn: async (form, api, extraOptions, baseQuery) => {
				const result = await baseQuery({
					url: `Templates/Preview/AgreementTemplate/Letter`,
					method: "POST",
					body: form,
					headers: authHeader(),
					responseHandler: async (response: { ok: boolean; blob: () => any }) => {
						if (response.ok) {
							return response.blob();
						} else {
							enqueueSnackbar(`Kunde inte ladda hem ${form.fileName}`, {
								variant: "error",
								persist: true,
							});
						}
					},
				});
				const hiddenElement = document.createElement("a");
				const url = window.URL || window.webkitURL;
				const blobPDF = url.createObjectURL(result.data as Blob);
				hiddenElement.href = blobPDF;
				hiddenElement.target = "_blank";
				hiddenElement.download = `${form.fileName}`;
				hiddenElement.type = "application/pdf";
				hiddenElement.click();
				return { data: result };
			},
		}),

		getPreviewDispatchListLetter: builder.mutation({
			queryFn: async (form, api, extraOptions, baseQuery) => {
				const result = await baseQuery({
					url: `Templates/Preview/DispatchList/Letter?dispatchListId=${form.dispatchListId}&recipientType=${form.recipientType}&dispatchType=${form.dispatchType}`,
					method: "GET",
					headers: authHeader(),
					responseHandler: async (response: { ok: boolean; blob: () => any }) => {
						if (response.ok) {
							return response.blob();
						} else {
							enqueueSnackbar(`Kunde inte ladda hem ${form.fileName}`, {
								variant: "error",
								persist: true,
							});
						}
					},
				});
				const hiddenElement = document.createElement("a");
				const url = window.URL || window.webkitURL;
				const blobPDF = url.createObjectURL(result.data as Blob);
				hiddenElement.href = blobPDF;
				hiddenElement.target = "_blank";
				hiddenElement.download = `${form.fileName}`;
				hiddenElement.type = "application/pdf";
				hiddenElement.click();
				return { data: result };
			},
		}),

		// AGRREMENTYPE
		getLatestSalaryRevisionInfo: builder.query({
			query: (agreementTypeId: string) => ({
				url: `AgreementTypes/${agreementTypeId}/LatestSalaryRevision`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		// PROTOCOL
		getEmptyProtocol: builder.mutation({
			queryFn: async (id, api, extraOptions, baseQuery) => {
				const result = await baseQuery({
					url: `Protocols/EmptyProtocol/xlsx?SalaryRevisionId=${id}`,
					method: "GET",
					headers: authHeader(),
					responseHandler: async (response: { ok: boolean; blob: () => any }) => {
						if (response.ok) {
							return response.blob();
						} else {
							enqueueSnackbar(`Kunde inte ladda hem tomt löneprotokoll`, {
								variant: "error",
								persist: true,
							});
						}
					},
				});
				const hiddenElement = document.createElement("a");
				const url = window.URL || window.webkitURL;
				const blobPDF = url.createObjectURL(result.data as Blob);
				hiddenElement.href = blobPDF;
				hiddenElement.target = "_blank";
				hiddenElement.download = "Tomt löneprotokoll";
				hiddenElement.type = "application/pdf";
				hiddenElement.click();
				return { data: result };
			},
		}),

		// SALARYREPORTS overview
		getAllSalaryReportActive: builder.query({
			query: () => ({
				url: `/SalaryReports/Active`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		getAllSalaryReportCompleted: builder.query({
			query: () => ({
				url: `/SalaryReports/Completed`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		// Salaryreport
		getSalaryReport: builder.query({
			query: (salaryReportId) => ({
				url: `SalaryReports/${salaryReportId}`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		// Salaryreport comments
		getSalaryReportComments: builder.query({
			query: (salaryReportId) => ({
				url: `SalaryReports/${salaryReportId}/Comments`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		// Salaryreport investigation
		getPatchSalaryReportInvestigation: builder.mutation({
			query: (salaryReportId) => ({
				url: `SalaryReports/${salaryReportId}/Investigation`,
				method: "PATCH",
				headers: authHeader(),
			}),
		}),

		// investigation completed
		getPatchSalaryReportCompleted: builder.mutation({
			query: (salaryReportId) => ({
				url: `SalaryReports/${salaryReportId}/Completed`,
				method: "PATCH",
				headers: authHeader(),
			}),
		}),

		// "Skapa protokoll"
		getCreateSalaryReportRows: builder.mutation({
			query: (form) => ({
				url: `/SalaryReports/${form.salaryReportId}/SalaryRows`,
				method: "POST",
				body: form,
				headers: authHeader(),
			}),
		}),

		getSalaryReportRows: builder.query({
			query: (salaryReportId) => ({
				url: `/SalaryReports/${salaryReportId}/SalaryRows`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		// generalComment
		getPostSalaryReportGeneralComment: builder.mutation({
			query: (form) => ({
				url: `/SalaryReports/${form.salaryReportId}/GeneralComment`,
				method: "POST",
				body: form.comment,
				headers: authHeader(),
			}),
		}),

		// protocol
		getUploadProtocol: builder.mutation({
			query: (form) => {
				return {
					url: `/SalaryReports/${form.salaryReportId}/Protocol`,
					method: "POST",
					body: form.file,
					formData: true,
					headers: authHeader(),
				};
			},
		}),

		// attachments
		getAttachments: builder.query({
			query: (salaryReportId) => ({
				url: `/SalaryReports/${salaryReportId}/Attachments`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		getRemoveAttachment: builder.mutation({
			query: (form) => ({
				url: `SalaryReports/${form.salaryReportId}/Attachments/${form.attachmentId}`,
				method: "DELETE",
				body: form,
				headers: authHeader(),
			}),
		}),

		getUploadAttachment: builder.mutation({
			query: (form) => {
				return {
					url: `/SalaryReports/${form.salaryReportId}/Attachments`,
					method: "POST",
					body: form.file,
					formData: true,
					headers: authHeader(),
				};
			},
		}),

		getAttachmentDownload: builder.mutation({
			queryFn: async (form, api, extraOptions, baseQuery) => {
				const result = await baseQuery({
					url: `SalaryReports/${form.salaryReportId}/Attachments/${form.attachmentId}/Download`,
					method: "GET",
					headers: authHeader(),
					responseHandler: async (response: { ok: boolean; blob: () => any }) => {
						if (response.ok) {
							return response.blob();
						} else {
							enqueueSnackbar(`Kunde inte ladda hem ${form.name}`, {
								variant: "error",
								persist: true,
							});
						}
					},
				});

				const hiddenElement = document.createElement("a");
				const url = window.URL || window.webkitURL;
				const blobPDF = url.createObjectURL(result.data as Blob);
				hiddenElement.href = blobPDF;
				hiddenElement.target = "_blank";
				hiddenElement.download = `${form.name}`;
				hiddenElement.click();
				return { data: result };
			},
		}),

		// sendIn
		getSendSalaryReport: builder.mutation({
			query: (form) => ({
				url: `SalaryReports/${form.salaryReportId}/SendIn`,
				method: "POST",
				body: form.body,
				headers: authHeader(),
			}),
		}),

		// SALARYROWS (single row)
		// add salaryrow
		getCreateSalaryReportRow: builder.mutation({
			query: (form) => ({
				url: `/SalaryReports/${form.salaryReportId}/SalaryRows/AddRow`,
				method: "POST",
				body: form,
				headers: authHeader(),
			}),
		}),

		// edit salaryrow
		getPatchSalaryReportRow: builder.mutation({
			query: (form: IEditSalaryReportRowForm) => ({
				url: `/SalaryReports/${form.salaryReportId}/SalaryRows/${form.salaryRowId}`,
				method: "PATCH",
				body: form.content,
				headers: authHeader(),
			}),
		}),

		getSalaryReportNewSalary: builder.mutation({
			query: (form) => {
				return {
					url: `SalaryReports/${form.salaryReportId}/SalaryRows/${form.salaryRowId}/NewSalary`,
					method: "PATCH",
					headers: { ...authHeader(), "Content-Type": "application/json" },
					body: form.newSalary,
				};
			},
		}),

		getSalaryReportPreviousSalary: builder.mutation({
			query: (form) => {
				return {
					url: `SalaryReports/${form.salaryReportId}/SalaryRows/${form.salaryRowId}/PreviousSalary`,
					method: "PATCH",
					headers: { ...authHeader(), "Content-Type": "application/json" },
					body: form.previousSalary,
				};
			},
		}),

		getSalaryReportSalaryCode: builder.mutation({
			query: (form) => {
				return {
					url: `SalaryReports/${form.salaryReportId}/SalaryRows/${form.salaryRowId}/SalaryCode`,
					method: "PATCH",
					headers: { ...authHeader(), "Content-Type": "application/json" },
					body: form.salaryCodeId,
				};
			},
		}),

		getPatchSalaryRowComment: builder.mutation({
			query: (form: any) => ({
				url: `/SalaryReports/${form.salaryReportId}/SalaryRows/${form.salaryRowId}/Comment`,
				method: "PATCH",
				headers: { ...authHeader(), "Content-Type": "application/json" },

				body: form.comment,
			}),
		}),

		getIgnoreSalaryProtocolRow: builder.mutation({
			query: (form: any) => ({
				url: `/SalaryReports/${form.salaryReportId}/SalaryRows/${form.salaryRowId}/Ignore`,
				method: "PATCH",
				headers: { ...authHeader(), "Content-Type": "application/json" },

				body: form.ignore,
			}),
		}),

		getActivateSalaryProtocolRow: builder.mutation({
			query: (form: any) => ({
				url: `/SalaryReports/${form.salaryReportId}/SalaryRows/${form.salaryRowId}/Activate`,
				method: "PATCH",
				headers: { ...authHeader(), "Content-Type": "application/json" },
				body: form.comment,
			}),
		}),

		getRemoveSalaryProtocolRow: builder.mutation({
			query: (form: any) => ({
				url: `/SalaryReports/${form.salaryReportId}/SalaryRows/${form.salaryRowId}`,
				method: "DELETE",
				body: form,
				headers: authHeader(),
			}),
		}),

		// Duplicates
		getDuplicateNumberOfSalaryRowEmployees: builder.query({
			query: (ids) => ({
				url: `/DispatchLists/DuplicateNumberOfSalaryRowEmployees?${ids}`,
				method: "GET",
				headers: authHeader(),
			}),
		}),

		getDuplicateSalaryRowEmployees: builder.query({
			query: (ids) => ({
				url: `/DispatchLists/DuplicateSalaryRowEmployees?${ids}`,
				method: "GET",
				headers: authHeader(),
			}),
		}),
	}),
});

export const {
	// Session (roles & user)
	useGetRolesFromDirectoryQuery,
	useGetChangeRoleFromDirectoryMutation,
	useGetUserFromDirectoryQuery,
	// useGetUserIdentityQuery,

	// Dispatchlist
	useGetAllActiveQuery,
	useGetAllCompletedQuery,
	useGetByIdQuery,
	useLazyGetByIdQuery,
	useGetByIdMMutation,
	useGetCreateOptionsQuery,
	useGetCreateDispatchMutation,
	useGetRemoveSalaryReportMutation,
	useGetRemoveDispatchListMutation,
	useGetArchiveDispatchListMutation,

	// Dispatchlist send
	useGetSendSalaryReportDispatchesMutation,
	useGetSendDispatchesMutation,

	// Member
	useGetSefRepresentativesQuery,
	useGetMembersByWorkplaceQuery,
	useGetSearchMembersMutation,

	// Recipient
	useGetUpdateDistributionSettingsForRecipientsMutation,
	useGetUpdateRecipientsForSalaryReportMutation,

	// Responsible
	useGetUpdateResponsibleForSalaryReportMutation,

	// Templates
	useGetAgreementTypeTemplateQuery,
	useLazyGetAgreementTypeTemplateQuery,
	useGetAgreementTypeTemplateMMutation,

	useGetAgreementTypeTemplatesQuery,
	useLazyGetAgreementTypeTemplatesQuery,
	// useGetAgreementTypeTemplatesMMutation,

	useGetDispatchTemplateQQuery,
	useLazyGetDispatchTemplateQQuery,
	useGetDispatchTemplateMutation,
	useGetUpdateAgreementTypeTemplateMutation,
	useGetUpdateDispatchTemplateMutation,

	// preview
	useGetPreviewAgreementTemplateMailQuery,
	useLazyGetPreviewAgreementTemplateMailQuery,
	useLazyGetPreviewDispatchListMailQuery,

	useGetPreviewAgreementTemplateLetterMutation,
	useGetPreviewDispatchListLetterMutation,

	// AGRREMENTYPE
	useGetLatestSalaryRevisionInfoQuery,

	// Protocol
	useGetEmptyProtocolMutation,

	// SALARYREPORTS overview
	useGetAllSalaryReportActiveQuery,
	useLazyGetAllSalaryReportActiveQuery,
	useGetAllSalaryReportCompletedQuery,

	useGetPatchSalaryReportInvestigationMutation,
	useGetPatchSalaryReportCompletedMutation,
	useGetSalaryReportCommentsQuery,
	useGetUploadProtocolMutation,

	// Salaryreport
	useGetSalaryReportQuery,
	useLazyGetSalaryReportQuery,
	useGetSendSalaryReportMutation,

	// Salaryrows
	useGetCreateSalaryReportRowsMutation,
	useGetSalaryReportRowsQuery,

	// Salaryrows Add salaryrow
	useGetCreateSalaryReportRowMutation,

	// Salaryrows General Comment
	useGetPostSalaryReportGeneralCommentMutation,

	// Salaryrows Attachments
	useGetUploadAttachmentMutation,
	useGetAttachmentsQuery,
	useGetAttachmentDownloadMutation,
	useGetRemoveAttachmentMutation,

	// SalaryRow (single row)
	useGetSalaryReportNewSalaryMutation,
	useGetSalaryReportPreviousSalaryMutation,
	useGetSalaryReportSalaryCodeMutation,
	useGetPatchSalaryReportRowMutation,
	useGetPatchSalaryRowCommentMutation,

	useGetRemoveSalaryProtocolRowMutation,
	useGetIgnoreSalaryProtocolRowMutation,
	useGetActivateSalaryProtocolRowMutation,

	// Duplicates
	useLazyGetDuplicateNumberOfSalaryRowEmployeesQuery,
	useLazyGetDuplicateSalaryRowEmployeesQuery,
} = api;
