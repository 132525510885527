import React from "react";
import { useGetSalaryReportRowsQuery } from "api/Api";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";
import colors from "styles/colors-theme";
import theme from "styles/theme";
import { formatSalary } from "lib/helpers/NumberInputHelper";
import { Box, Card, CircularProgress, Tooltip, Typography, styled } from "@mui/material";

const BoxStyled = styled(Box)(() => ({
	display: "inline-flex",
	maxWidth: "fit-content",
	background: colors.background.body,
	borderColor: colors.border.main,
	borderStyle: "solid",
	borderWidth: 1,
	borderRadius: 11,
	wordBreak: "keep-all",

	[theme.breakpoints.down("md")]: {
		minWidth: "100%",
		width: "100%",
		justifyContent: "space-between",
	},
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column",
	},
}));

export const CardInfoBoxStyled = styled(Card)(() => ({
	display: "flex",
	flexDirection: "column",
	margin: 22,
	textAlign: "center",
	border: 0,
	background: "transparent",
	minWidth: 80,
	maxWidth: 200,
	[theme.breakpoints.down("md")]: {
		textAlign: "start",
		maxWidth: "100%",
	},
}));

interface IInfoBarSalaryAmount {
	"TOTALT LÖNEUTRYMME": string;
	"TOTAL LÖNEPOTT": string;
	"EXTRA UTÖVER POTT": string;
}

const InfoBarSalaryAmount = () => {
	const salaryReportId = LastSegmentOfUrl(location.href);
	const { data, isLoading } = useGetSalaryReportRowsQuery(salaryReportId);
	const infoBarSalaryAmountObj: IInfoBarSalaryAmount[] = [];

	if (data) {
		infoBarSalaryAmountObj.push({
			"TOTALT LÖNEUTRYMME": data.totalSalaryAmount && formatSalary(data.totalSalaryAmount),
			"TOTAL LÖNEPOTT": data.totalSalarySettlement && formatSalary(data.totalSalarySettlement),
			"EXTRA UTÖVER POTT": data.amountOverSettlement && formatSalary(data.amountOverSettlement),
		});
	}

	return (
		<Box
			display={"flex"}
			alignItems={{ md: "center", xxs: "start" }}
			flexDirection={{ md: "row", xxs: "column" }}
			minWidth={{ md: "fit-content", xxs: "100%" }}
		>
			{isLoading ? (
				<CircularProgress />
			) : data ? (
				<Box
					flexDirection={{ lg: "row", xxs: "column" }}
					sx={{
						display: "flex",
						gap: 5,
						width: "100%",
					}}
				>
					<BoxStyled>
						{Object.entries(infoBarSalaryAmountObj[0]).map(([key, value]) => {
							return (
								<CardInfoBoxStyled variant='outlined' key={key}>
									<Typography
										variant='body0'
										sx={{ fontWeight: 600, fontSize: "12px !important", mb: 1 }}
									>
										{key as string}
									</Typography>
									<Typography variant='body0'>
										{(value != null ? (value as unknown as string) : "") +
											(parseInt(value) >= 0 ? " kr" : "-")}
									</Typography>
								</CardInfoBoxStyled>
							);
						})}
					</BoxStyled>
					<BoxStyled>
						<CardInfoBoxStyled variant='outlined'>
							<Typography
								variant='body0'
								sx={{ fontWeight: 600, fontSize: "12px !important", mb: 1 }}
							>
								KVAR ATT FÖRDELA
							</Typography>
							{data.salarySettlementLeftOver == null ? (
								"-"
							) : (
								<Typography
									variant='body0'
									fontWeight={500}
									color={
										data.warnings.some((w: { warningCode: number }) => w.warningCode == 0)
											? theme.palette.error.contrastText
											: theme.palette.success.main
									}
								>
									<Tooltip
										title={
											data.warnings.find((w: any) => w.warningCode == 0)?.warningDescription ?? ""
										}
									>
										<span>{formatSalary(data.salarySettlementLeftOver) ?? "-"} kr</span>
									</Tooltip>
								</Typography>
							)}
						</CardInfoBoxStyled>
						<CardInfoBoxStyled variant='outlined'>
							<Typography
								variant='body0'
								sx={{ fontWeight: 600, fontSize: "12px !important", mb: 1 }}
							>
								GENOMSNITTLIG HÖJNING
							</Typography>
							<Box display={"flex"} justifyContent={"space-around"}>
								{!data.averageSalaryIncreaseInPercent || !data.averageSalaryIncrease ? (
									"-"
								) : (
									<>
										<Typography
											variant='body0'
											fontWeight={500}
											color={
												data.warnings.some((w: { warningCode: number }) => w.warningCode == 1)
													? theme.palette.error.contrastText
													: theme.palette.success.main
											}
										>
											<Tooltip
												title={
													data.warnings.find((w: any) => w.warningCode == 1)?.warningDescription ??
													""
												}
											>
												<span>{data.averageSalaryIncreaseInPercent.toLocaleString()} %</span>
											</Tooltip>
										</Typography>

										<Typography
											variant='body0'
											fontWeight={500}
											color={
												data.warnings.some((w: { warningCode: number }) => w.warningCode == 2)
													? theme.palette.error.contrastText
													: theme.palette.success.main
											}
										>
											<Tooltip
												title={
													data.warnings.find((w: any) => w.warningCode == 2)?.warningDescription ??
													""
												}
											>
												<span>{data.averageSalaryIncrease?.toLocaleString()} kr</span>
											</Tooltip>
										</Typography>
									</>
								)}
							</Box>
						</CardInfoBoxStyled>
					</BoxStyled>
				</Box>
			) : null}
		</Box>
	);
};

export default InfoBarSalaryAmount;
