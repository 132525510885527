import { useEffect, useRef, useState } from "react";
import theme from "styles/theme";
import colors from "styles/colors-theme";
import {
	useGetSalaryReportNewSalaryMutation,
	useGetSalaryReportPreviousSalaryMutation,
	useGetSalaryReportQuery,
	useGetSalaryReportRowsQuery,
	useGetSalaryReportSalaryCodeMutation,
	useGetUserFromDirectoryQuery,
} from "api/Api";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";

import {
	Box,
	styled,
	IconButton,
	OutlinedInput,
	Typography,
	CircularProgress,
	MenuItem,
	Select,
	useMediaQuery,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import { ReactComponent as CommentRegularIcon } from "assets/icons/comment-regular.svg";
import {
	filterInputFromAlpha,
	validateNumberInput,
	formatSalary,
} from "lib/helpers/NumberInputHelper";

import ModalDialog from "components/common/modal/ModalDialog";
import SalaryRowCommentModalContent from "./SalaryRowCommentModalContent";
import RowMenuSalaryProtocol from "./RowMenuSalaryProtocol";
import {
	IError,
	ISalaryRevisionLimit,
	IWarning,
} from "types/representative/commonSalaryReportTypes";
import { enqueueSnackbar } from "notistack";
import { IResponsible } from "types/dispatch/dispatchListTable/SelectAgentModal";

const StyledBox = styled(Box)(() => ({
	display: "flex",
	flexDirection: "column",
})) as typeof Box;

interface IOptions {
	id: string;
	name: string;
}

interface IProtocolProps {
	showErrors?: boolean;
}

export const salaryProtocolListTableGridColDef = ({ showErrors }: IProtocolProps) => {
	const salaryReportId = LastSegmentOfUrl(location.href);
	const [codeOptions, setCodeOptions] = useState<IOptions[]>([]);

	const [getSalaryReportNewSalary] = useGetSalaryReportNewSalaryMutation();
	const [getSalaryReportPreviousSalary] = useGetSalaryReportPreviousSalaryMutation();
	const [getSalaryReportSalaryCode] = useGetSalaryReportSalaryCodeMutation();
	const { refetch: refetchSalaryReportRows } = useGetSalaryReportRowsQuery(salaryReportId);

	const { data: salaryReportData } = useGetSalaryReportQuery(salaryReportId);
	const { data: userData } = useGetUserFromDirectoryQuery(null);
	const isOmbud =
		salaryReportData.responsibles.findIndex((r: IResponsible) => r.userId == userData.id) >= 0;
	const ombudCanEdit = isOmbud && salaryReportData.status == 250;
	const disableNonAdmin = userData.activeRole == "non-admin" && salaryReportData.status >= 250;
	const isArchived = salaryReportData.dispatchListArchivedDate != null ?? false;

	useEffect(() => {
		if (salaryReportData && salaryReportData.salaryRevision.salaryRevisionLimits.length > 0) {
			const salaryCodes: ISalaryRevisionLimit[] =
				salaryReportData?.salaryRevision?.salaryRevisionLimits
					?.slice()
					.sort((a: { salaryCode: { code: string } }, b: { salaryCode: { code: string } }) =>
						a.salaryCode.code.localeCompare(b.salaryCode.code),
					) ?? [];

			const optionsArr: IOptions[] = [];

			salaryCodes.map((item) => {
				optionsArr.push({
					name: item.salaryCode.code,
					id: item.salaryCode.salaryCodeId,
				});
			});
			setCodeOptions(optionsArr);
		}
	}, [salaryReportData]);

	function enterToTab(
		e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLLIElement>,
	) {
		if (e.key === "Enter" || (e.key === "Tab" && !e.shiftKey)) {
			if (e.key == "Tab") e.preventDefault();
			const inputs = Array.from(
				document.querySelectorAll(
					"input[type='text']:not(.Mui-disabled), .MuiSelect-select:not(.Mui-disabled)",
				),
			);
			const currentIndex = inputs.indexOf(e.target as Element);
			// if only one row go to filter input on blur
			if (inputs.length - 1 == currentIndex) {
				(inputs[0] as HTMLElement).focus();
			} else {
				(inputs[currentIndex + 1] as HTMLElement).focus();
			}
		}
		// tab backwards
		if (e.shiftKey && e.key === "Tab") {
			if (e.key == "Tab") e.preventDefault();
			const inputs = Array.from(
				document.querySelectorAll(
					"input[type='text']:not(.Mui-disabled), .MuiSelect-select:not(.Mui-disabled)",
				),
			);
			const currentIndex = inputs.indexOf(e.target as Element);

			if (currentIndex == 1) {
				(inputs[inputs.length - 1] as HTMLElement).focus();
			} else {
				(inputs[currentIndex - 1] as HTMLElement).focus();
			}
		}
	}

	const columns: GridColDef[] = [
		{
			field: "tableRowError",
			headerName: "",
			sortable: false,
			minWidth: 4,
			maxWidth: 4,
			renderCell: (params) => {
				const hasWarnings = params.row.warnings?.length > 0 && true;
				return (
					<Box
						sx={{
							background: `${hasWarnings ? colors.error2 : "transparent"}`,
							width: "100%",
							height: "100%",
						}}
					/>
				);
			},
		},
		{
			field: "name",
			headerName: "Namn",
			sortable: true,
			align: "left",
			headerAlign: "left",
			flex: 3,
			minWidth: 150,
			renderCell: (params) => {
				return (
					<StyledBox>
						<>
							<span>{params.value}</span>
							<span
								style={{
									fontSize: 13,
									fontWeight: 500,
									color: theme.palette.info.light,
									paddingTop: "4px",
								}}
							>
								{params.row?.birthDate?.slice(0, 8)}
							</span>
						</>
					</StyledBox>
				);
			},
		},
		{
			field: "salaryCodeId",
			headerName: "Kod",
			sortable: true,
			align: "left",
			headerAlign: "left",
			flex: 1,
			minWidth: 130,
			renderCell: (params) => {
				const salaryCodeId = params.value;
				const ignored = params.row.ignored;
				const activeSelectRef = useRef<HTMLSelectElement>(null);

				const [selectedCode, setSelectedCode] = useState("");
				const [isLoadingCode, setIsLoadingCode] = useState(false);
				const [rowSalaryChanged, setRowSalaryChanged] = useState<string[]>([]);

				useEffect(() => {
					const handleKeyDown = (e: KeyboardEvent) => {
						if (e.key === " " || e.key === "ArrowUp" || e.key === "ArrowDown") {
							e.preventDefault();
							e.stopPropagation();
						}
					};

					const activeSelect = activeSelectRef.current;

					if (activeSelect) {
						activeSelect.addEventListener("keydown", handleKeyDown);
					}
				}, [activeSelectRef]);

				return (
					<>
						{codeOptions.length > 0 && (
							<Select
								ref={activeSelectRef}
								type={ignored ? "" : "text"}
								onBlur={async (e) => {
									if ((params.value ?? "") != e.target.value) {
										const form = {
											salaryReportId: salaryReportId,
											salaryRowId: params.row.id,
											salaryCodeId: { salaryCodeId: selectedCode },
										};
										setIsLoadingCode(true);
										try {
											getSalaryReportSalaryCode(form)
												.unwrap()
												.then(() => {
													const tempArr = [...rowSalaryChanged];
													tempArr.push(params.row.id);
													setRowSalaryChanged(tempArr);

													refetchSalaryReportRows();
													setIsLoadingCode(false);
												})
												.catch((error) => {
													setIsLoadingCode(false);
													enqueueSnackbar(error.data.detail || "Kan inte spara kod", {
														variant: "error",
														persist: true,
													});
												});
										} catch (error) {
											setIsLoadingCode(false);
											enqueueSnackbar("Kod kan inte sparas", {
												variant: "error",
												persist: true,
											});
										}
									}
								}}
								disabled={
									(disableNonAdmin || isArchived || ignored || isLoadingCode) && !ombudCanEdit
								}
								value={selectedCode ? selectedCode : salaryCodeId}
								onChange={(e) => {
									setSelectedCode(e.target.value as string);
								}}
								input={
									<OutlinedInput
										type={ignored ? "" : "text"}
										sx={{
											backgroundColor:
												(disableNonAdmin || isArchived || ignored || isLoadingCode) && !ombudCanEdit
													? theme.palette.background.label
													: "white",

											"& fieldset": {
												borderColor:
													(disableNonAdmin || isArchived || ignored || isLoadingCode) &&
													!ombudCanEdit
														? `${colors.border.light} !important`
														: rowSalaryChanged.includes(params.row.id)
														? `${theme.palette.text.darkGrey}!important`
														: "",
											},
											"& :focus": {
												border: `${theme.palette.secondary.dark} 2px solid !important`,
												borderRadius: "7px",
												py: "7px",
											},
										}}
										endAdornment={
											isLoadingCode && (
												<CircularProgress
													size={24}
													sx={{
														position: "absolute",
														right: 45,
													}}
												/>
											)
										}
									/>
								}
							>
								{codeOptions.map((c, index) => {
									return (
										<MenuItem
											key={index}
											value={c.id}
											onKeyDown={(e) => {
												if (e.key == "Enter") {
													e.stopPropagation();
												} // necessary to prevent mui default
												enterToTab(e);
											}}
										>
											{c.name}
										</MenuItem>
									);
								})}
							</Select>
						)}
					</>
				);
			},
		},
		{
			field: "previousSalary",
			headerName: "Nuvarande Lön",
			type: "number",
			sortable: true,
			align: "right",
			headerAlign: "right",
			flex: 2,
			minWidth: 170,
			renderCell: (params) => {
				const ignored = params.row.ignored;
				const [isLoadingPrevSal, setIsLoadingPrevSal] = useState(false);
				const [errorPreviousSalary, setErrorPreviousSalary] = useState<boolean>(false);
				const [rowSalaryChanged, setRowSalaryChanged] = useState<string[]>([]);

				return (
					<Box
						sx={{
							maxWidth: 100,
						}}
					>
						<OutlinedInput
							// to make enterToTab skip ignored row and add focus next input
							type={ignored ? "" : "text"}
							onKeyDown={(e) => {
								e.stopPropagation(); // necessary to prevent mui default
								enterToTab(e);
								validateNumberInput(e);
							}}
							onChange={(e) => {
								filterInputFromAlpha(e);
							}}
							onFocus={(e) => (e.target.value = params.value)}
							onBlur={async (e) => {
								const previousSalaryNoSpaces = e.target.value.toString().split(" ").join("");
								const previousSalary = previousSalaryNoSpaces
									? parseInt(previousSalaryNoSpaces)
									: 0;

								if ((params.value ?? "") != e.target.value) {
									const form = {
										salaryReportId: salaryReportId,
										salaryRowId: params.row.id,
										previousSalary: previousSalary,
									};

									setIsLoadingPrevSal(true);

									try {
										getSalaryReportPreviousSalary(form)
											.unwrap()
											.then(() => {
												const tempArr = [...rowSalaryChanged];
												tempArr.push(params.row.id);
												setRowSalaryChanged(tempArr);

												refetchSalaryReportRows();

												setErrorPreviousSalary(false);
												setIsLoadingPrevSal(false);
											})
											.catch((error) => {
												setErrorPreviousSalary(true);
												setIsLoadingPrevSal(false);

												enqueueSnackbar(error.data.detail || "Kan inte spara nuvarande lön", {
													variant: "error",
													persist: true,
												});
											});
									} catch (error) {
										setIsLoadingPrevSal(false);
										enqueueSnackbar("Nuvarande lön kan inte sparas", {
											variant: "error",
											persist: true,
										});
									}
								}
							}}
							key={!errorPreviousSalary ? formatSalary(params.value) : ""}
							defaultValue={params.value ? formatSalary(params.value) : ""}
							endAdornment={
								!ignored && !isLoadingPrevSal ? (
									<Typography
										sx={{
											fontSize: 13,
											paddingRight: 1,
											color:
												(disableNonAdmin || isArchived || ignored || isLoadingPrevSal) &&
												!ombudCanEdit
													? theme.palette.text.disabled
													: "",
										}}
									>
										kr
									</Typography>
								) : isLoadingPrevSal ? (
									<CircularProgress
										size={24}
										sx={{
											position: "absolute",
											right: 40,
										}}
									/>
								) : null
							}
							disabled={
								(disableNonAdmin || isArchived || ignored || isLoadingPrevSal) && !ombudCanEdit
							}
							sx={{
								fontSize: 13,
								backgroundColor:
									(disableNonAdmin || isArchived || ignored || isLoadingPrevSal) && !ombudCanEdit
										? theme.palette.background.label
										: "white",
								"& fieldset": {
									borderColor:
										(disableNonAdmin || isArchived || ignored || isLoadingPrevSal) && !ombudCanEdit
											? `${colors.border.light} !important`
											: rowSalaryChanged.includes(params.row.id)
											? `${theme.palette.text.darkGrey}!important`
											: "",
								},
								":focus-within ": {
									outline: `${theme.palette.secondary.dark} thin solid`,
								},
							}}
						/>
					</Box>
				);
			},
		},

		{
			field: "guaranteedRaise",
			headerName: "Garanterad utfall",
			sortable: true,
			align: "right",
			headerAlign: "right",
			flex: 3,
			minWidth: 190,
			renderCell: (params) => {
				return <span>{params.value != null ? formatSalary(params.value) + " kr" : "-"}</span>;
			},
		},
		{
			field: "extraRaise",
			headerName: "Lokal pott",
			sortable: true,
			align: "right",
			headerAlign: "right",
			flex: 2,
			minWidth: 150,
			renderCell: (params) => {
				return (
					<>
						{params.row.newSalary != null && params.row.newSalary != 0 ? (
							<span>{formatSalary(params.value) + " kr"}</span>
						) : (
							<Typography
								variant='body0'
								color={params.row.exampleExtraRaise && theme.palette.text.disabled}
							>
								{params.row.exampleExtraRaise
									? formatSalary(params.row.exampleExtraRaise) + " kr"
									: "-"}
							</Typography>
						)}
					</>
				);
			},
		},
		{
			field: "newSalary",
			headerName: "Ny lön",
			sortable: true,
			align: "left",
			headerAlign: "left",
			flex: 2,
			minWidth: 150,
			renderCell: (params) => {
				const ignored = params.row.ignored;
				const [isLoading, setIsLoading] = useState(false);
				const [errorNewSalary, setErrorNewSalary] = useState<boolean>(false);
				const [rowSalaryChanged, setRowSalaryChanged] = useState<string[]>([]);

				return (
					<Box
						sx={{
							maxWidth: 100,
						}}
					>
						<OutlinedInput
							// to make enterToTab skip ignored row and add focus next input
							type={ignored ? "" : "text"}
							onKeyDown={(e) => {
								e.stopPropagation(); // necessary to prevent mui default
								enterToTab(e);
								validateNumberInput(e);
							}}
							onChange={(e) => {
								filterInputFromAlpha(e);
							}}
							onFocus={(e) => (e.target.value = params.row.newSalary)}
							onBlur={async (e) => {
								const newSalaryNoSpaces = e.target.value.toString().split(" ").join("");
								const newSalary = newSalaryNoSpaces ? parseInt(newSalaryNoSpaces) : 0;

								if ((params.row.newSalary ?? "") != e.target.value) {
									const form = {
										salaryReportId: salaryReportId,
										salaryRowId: params.row.id,
										newSalary: newSalary,
									};

									setIsLoading(true);

									try {
										getSalaryReportNewSalary(form)
											.unwrap()
											.then(() => {
												const tempArr = [...rowSalaryChanged];
												tempArr.push(params.row.id);
												setRowSalaryChanged(tempArr);

												refetchSalaryReportRows();

												setErrorNewSalary(false);
												setIsLoading(false);
											})
											.catch((error) => {
												setErrorNewSalary(true);
												setIsLoading(false);

												enqueueSnackbar(error.data.detail || "Kan inte spara ny lön", {
													variant: "error",
													persist: true,
												});
											});
									} catch (error) {
										setIsLoading(false);
										enqueueSnackbar("Ny lön kan inte sparas", {
											variant: "error",
											persist: true,
										});
									}
								}
							}}
							key={!errorNewSalary ? formatSalary(params.row.newSalary) : ""}
							defaultValue={params.row.newSalary ? formatSalary(params.row.newSalary) : ""}
							placeholder={params.row.exampleNewSalary && formatSalary(params.row.exampleNewSalary)}
							endAdornment={
								!ignored && !isLoading ? (
									<Typography
										sx={{
											fontSize: 13,
											paddingRight: 1,
											color:
												disableNonAdmin || isArchived || ignored || isLoading
													? theme.palette.text.disabled
													: "",
										}}
									>
										kr
									</Typography>
								) : isLoading ? (
									<CircularProgress
										size={24}
										sx={{
											position: "absolute",
											right: 40,
										}}
									/>
								) : null
							}
							disabled={(disableNonAdmin || isArchived || ignored || isLoading) && !ombudCanEdit}
							sx={{
								fontSize: 13,
								backgroundColor:
									(disableNonAdmin || isArchived || ignored || isLoading) && !ombudCanEdit
										? theme.palette.background.label
										: "white",
								"& fieldset": {
									borderColor:
										(disableNonAdmin || isArchived || ignored || isLoading) && !ombudCanEdit
											? `${colors.border.light} !important`
											: rowSalaryChanged.includes(params.row.id)
											? `${theme.palette.text.darkGrey}!important`
											: "",
								},
								":focus-within ": {
									outline: `${theme.palette.secondary.dark} thin solid`,
								},
							}}
						/>
					</Box>
				);
			},
		},
		{
			field: "comment",
			headerName: "",
			sortable: true,
			align: "center",
			headerAlign: "center",
			width: 10,

			renderCell: (params) => {
				const hasWarnings = params.row.warnings?.length > 0 && true;
				const comment = params.value;
				const salaryRowId = params.row.salaryRowId;
				const ignored = params.row.ignored;
				const [modalRowComment, setModalRowComment] = useState(false);

				return (
					<>
						{modalRowComment && (
							<ModalDialog
								isVisible={modalRowComment}
								showModal={setModalRowComment}
								label={`Kommentar för ${
									params.row.name != null ? params.row.name : "arbetstagare"
								}`}
								icon={
									<CommentRegularIcon
										color={theme.palette.primary.main}
										style={{ width: "20px", height: "20px" }}
									/>
								}
								content={
									<SalaryRowCommentModalContent
										showModal={setModalRowComment}
										salaryRowId={salaryRowId}
									/>
								}
							/>
						)}
						<IconButton
							aria-label='meddelande'
							size='small'
							sx={{
								backgroundColor:
									comment.length > 0
										? theme.palette.secondary.main
										: theme.palette.background.label,
							}}
							onClick={(e) => {
								e.stopPropagation();
								setModalRowComment(true);
							}}
						>
							<CommentRegularIcon
								color={
									(ignored && comment == "") || (hasWarnings && comment == "")
										? theme.palette.error.contrastText
										: comment == "" || comment == null
										? theme.palette.infoGrey.dark
										: theme.palette.primary.main
								}
								style={{
									width: "15px",
									height: "15px",
								}}
							/>
						</IconButton>
					</>
				);
			},
		},
		{
			field: "raiseInPercent",
			headerName: "",
			sortable: true,
			align: "center",
			headerAlign: "center",
			width: 65,
			renderCell: (params) => {
				const hasWarnings = params.row.warnings?.length > 0 && true;
				return (
					<>
						{params.row.newSalary != null && (
							<Typography
								variant='body'
								fontWeight={"500"}
								color={
									hasWarnings ? theme.palette.error.contrastText : theme.palette.green.contrastText
								}
								mt={1}
							>
								{params.value != null ? params.value.toString().replace(".", ",") + "%" : ""}
							</Typography>
						)}
					</>
				);
			},
		},
		{
			field: "infoText",
			headerName: "",
			sortable: false,
			align: "left",
			headerAlign: "left",
			flex: 3,
			minWidth: 215,
			renderCell: (params) => {
				const warningDescArray: string[] = [];

				if (params.row?.warnings?.length > 0) {
					params.row.warnings.map((w: IWarning) => {
						warningDescArray.push(w.warningDescription);
					});
				}

				if (showErrors || salaryReportData.status >= 250) {
					if (params.row?.errors?.length > 0) {
						params.row.errors.map((e: IError) => {
							warningDescArray.push(e.errorDescription);
						});
					}
				}
				return (
					<StyledBox py={1}>
						{warningDescArray.map((warning, key) => (
							<Typography fontSize={13} key={key}>
								{warning}.
							</Typography>
						))}
						{!params.row.comment &&
							params.row.warnings?.length > 0 &&
							salaryReportData.status === 200 && (
								<Typography fontSize={13}>Kommentar nödvändig.</Typography>
							)}
					</StyledBox>
				);
			},
		},

		{
			field: "menu",
			headerName: "",
			sortable: false,
			align: "center",
			headerAlign: "center",
			flex: 1,
			renderCell: (params) => {
				const fullEditInModal = useMediaQuery(theme.breakpoints.down("lg"));
				if (!fullEditInModal) {
					return <RowMenuSalaryProtocol row={params.row} />;
				}
			},
		},
	];
	return columns;
};
